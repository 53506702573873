import { getRemoteCaretsOnLeaf, getRemoteCursorsOnLeaf } from '@tunasong/sync-lib/react'
import Caret from './caret.js'
import { isCaret } from './types.js'
import type { BaseText } from 'slate'

export const CaretLeaf = ({ leaf }: { leaf: BaseText }) => {
  if (!isCaret(leaf)) {
    return null
  }
  const cursors = getRemoteCursorsOnLeaf(leaf)
  const carets = getRemoteCaretsOnLeaf(leaf)

  return <Caret {...leaf} cursors={cursors} carets={carets} />
}
