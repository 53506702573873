/** Will choose the "correct" editor based on the type of entity */

import { type EditorContextProps, EntityEditor } from '@tunasong/editor'
import { isCollabEntity, logger } from '@tunasong/models'
import { isEntity, isPersisted } from '@tunasong/schemas'
import { type FC } from 'react'
import { CollabWebsocketEditorYDoc } from './collab-editor-websocket-ydoc.js'

/** Editor that will render a collaborative editor if Entity is collaborative, otherwise a standard editor */
export const DynamicEntityEditor: FC<EditorContextProps> = props => {
  const { element, plugins, ...restProps } = props

  const collabElement = isEntity(element) && isPersisted(element) && isCollabEntity(element) ? element : undefined

  if (!plugins) {
    logger.warn('No plugins provided to DynamicEntityEditor')
    return null
  }

  return collabElement ? (
    <CollabWebsocketEditorYDoc key={element.id} {...restProps} element={collabElement} plugins={plugins} />
  ) : (
    <EntityEditor {...restProps} key={element.id} element={element} plugins={plugins} />
  )
}

export default EntityEditor
