import { AvTimer } from '@mui/icons-material'
import { GlobalStyles, IconButton, useTheme, type IconButtonProps } from '@mui/material'
import { type Rhythm } from '@tunasong/models'
import { type FC } from 'react'
import { useMetronome } from './metronome.hook.js'

export interface MetronomeProps extends IconButtonProps {
  rhythm: Rhythm
  flashBackground?: boolean
}

export const MetronomeButton: FC<MetronomeProps> = props => {
  const { rhythm, flashBackground, ...restProps } = props
  const theme = useTheme()

  const { toggle, playing, tik } = useMetronome(rhythm)
  /** We need to change the color of an element in order to trigger rendering */
  const buttonColor = playing
    ? tik
      ? theme.palette.primary.main
      : theme.palette.secondary.main
    : theme.palette.primary.main
  const backgroundColor = playing
    ? tik
      ? theme.palette.primary.dark
      : theme.palette.secondary.dark
    : theme.palette.background.default

  return (
    <>
      {flashBackground ? (
        <GlobalStyles
          styles={{
            body: {
              backgroundColor: `${backgroundColor} !important`,
              transition: `background 0.05s ease-out`,
            },
          }}
        />
      ) : null}
      <IconButton {...restProps} onClick={toggle} sx={{ color: buttonColor, p: 0 }}>
        <AvTimer />
      </IconButton>
    </>
  )
}

export default MetronomeButton
