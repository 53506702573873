import { capitalize, Tab, Tabs } from '@mui/material'
import { pluginCategories, type PluginCategory, type Template, type TunaPlugin } from '@tunasong/plugin-lib'
import { entityTypeNames, type EntityType } from '@tunasong/schemas'
import { useCallback, useState, type FC } from 'react'
import { getCreateEntityPlugins } from './create-entity.hook.js'
import TemplateGrid from './template-grid.js'

interface CreateEntityGridProps {
  plugins: TunaPlugin[]
  selected?: Template
  /** @default true */
  showCategories?: boolean
  allowedChildEntities?: readonly EntityType[]
  onSelect: (template: Template | null) => void
}

/** Grid of Entity templates */
export const CreateEntityGrid: FC<CreateEntityGridProps> = props => {
  const { onSelect, showCategories = true, allowedChildEntities = entityTypeNames, plugins } = props

  const [activeCategory, setActiveCategory] = useState<PluginCategory>('main')
  const handleTab = useCallback(
    (ev: unknown, category: PluginCategory) => {
      setActiveCategory(category)
      onSelect(null)
    },
    [onSelect]
  )

  /** All createPlugins have at least one template */
  const getPluginsForCategory = (category: PluginCategory) =>
    getCreateEntityPlugins({
      plugins,
      category,
      allowedTypes: allowedChildEntities,
    })

  return (
    <>
      {showCategories ? (
        <Tabs
          sx={{ mb: 2, flex: 1 }}
          value={activeCategory}
          onChange={handleTab}
          scrollButtons
          variant="scrollable"
          allowScrollButtonsMobile
        >
          {pluginCategories.map(category => {
            const plugins = getPluginsForCategory(category)
            if (plugins.length === 0) {
              return null
            }
            return <Tab key={category} label={capitalize(category)} value={category} />
          })}
        </Tabs>
      ) : null}
      <TemplateGrid plugins={getPluginsForCategory(activeCategory)} onSelect={onSelect} />
    </>
  )
}

export default CreateEntityGrid
