import Note from '@tonaljs/note'
import range from 'just-range'
import React, { type FC } from 'react'

import { Box } from '@mui/material'
import { type NoteOctave } from '@tunasong/music-lib'
import Key from './key.js'
import type { NoteName, Scale } from '@tunasong/schemas'

export interface KeyboardNoteInfo {
  note: NoteOctave
  pitchName: string
  octave: number
  midiNumber: number
  isActive: boolean
  isAccidental: boolean
}

export type NoteLabelType = 'none' | 'note' | 'note-scale'

export type NoteLabelProps = Pick<KeyboardNoteInfo, 'octave' | 'midiNumber' | 'isActive' | 'isAccidental'> & {
  note: NoteName | NoteOctave
  scale?: Scale | null
  label?: NoteLabelType
}

interface KeyboardProps {
  noteRange: { first: number; last: number }
  /** Midi numbers */
  activeNotes: number[]
  className?: string
  renderNoteLabel?: FC<NoteLabelProps>
  keyWidthToHeight: number

  disabled?: boolean

  useTouchEvents?: boolean
  // If width is not provided, must have fixed width and height in parent container
  width?: number
  onPlayNoteInput(midiNote: number): void
  onStopNoteInput(midiNote: number): void
}

interface KeyboardState {}

class Keyboard extends React.Component<KeyboardProps, KeyboardState> {
  static defaultProps = {
    disabled: false,
    useTouchEvents: false,
    keyWidthToHeight: 0.33,
    renderNoteLabel: () => {},
  }

  // Range of midi numbers on keyboard
  getMidiNumbers() {
    return range(this.props.noteRange.first, this.props.noteRange.last + 1)
  }

  getNaturalKeyCount() {
    return this.getMidiNumbers().filter(num => {
      const { alt } = Note.get(Note.fromMidi(num))
      return alt === 0
    }).length
  }

  // Returns a ratio between 0 and 1
  getNaturalKeyWidth() {
    return 1 / this.getNaturalKeyCount()
  }

  getWidth() {
    return this.props.width ? this.props.width : '100%'
  }

  getHeight() {
    if (!this.props.width) {
      return '100%'
    }
    const keyWidth = this.props.width * this.getNaturalKeyWidth()
    return `${keyWidth / (this.props.keyWidthToHeight ?? 1)}px`
  }

  render() {
    const naturalKeyWidth = this.getNaturalKeyWidth()
    return (
      <Box
        sx={{
          /* Used for absolute positioning of .ReactPiano__Key--accidental elements */
          position: 'relative',
          /* Used to lay out .ReactPiano__Key--natural elements */
          display: 'flex',
        }}
        // className={classNames('ReactPiano__Keyboard', this.props.className)}
        style={{ width: this.getWidth(), height: this.getHeight() }}
      >
        {this.getMidiNumbers().map(midiNumber => {
          const { pc: note, alt = 0, oct: octave = 3 } = Note.get(Note.fromMidi(midiNumber))
          const isActive = !this.props.disabled && this.props.activeNotes.includes(midiNumber)
          const isAccidental = alt !== 0
          return (
            <Key
              naturalKeyWidth={naturalKeyWidth}
              midiNumber={midiNumber}
              noteRange={this.props.noteRange}
              active={isActive}
              accidental={isAccidental}
              disabled={this.props.disabled}
              onPlayNoteInput={this.props.onPlayNoteInput}
              onStopNoteInput={this.props.onStopNoteInput}
              useTouchEvents={this.props.useTouchEvents}
              key={midiNumber}
            >
              {this.props.disabled
                ? null
                : this.props.renderNoteLabel
                  ? this.props.renderNoteLabel({
                      isActive,
                      isAccidental,
                      midiNumber,
                      note: note as NoteName,
                      octave,
                    })
                  : null}
            </Key>
          )
        })}
      </Box>
    )
  }
}

export default Keyboard
