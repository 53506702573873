import { logger } from '@tunasong/models'
import { IndexeddbPersistence, Y } from '@tunasong/sync-lib'
import { useCallback, useEffect, useRef, useState } from 'react'
import invariant from 'tiny-invariant'
import { indexeddbCacheName } from './db.js'

interface UseDBSyncProps {
  entityId: string
  doc: Y.Doc
  disabled?: boolean
}
/** Sync from IndexedDB */
export const useDBSync = (props: UseDBSyncProps) => {
  const { doc, entityId, disabled = false } = props

  invariant(entityId, `useDBSync without entityId specified`)
  const markAsReady = useRef<(() => void) | null>(null)
  const readyPromise = useRef<Promise<void> | null>(null)
  useEffect(() => {
    readyPromise.current = new Promise(resolve => {
      markAsReady.current = resolve
    })
  }, [])

  /** `true` when the doc has been synced with local persistence, or syncing is disabled */
  const [isSynced, setIsSynced] = useState(disabled ? true : false)
  const idbRef = useRef<IndexeddbPersistence | null>(null)

  /** Start provider first to ensure that the SyncHandler will record changes from Indexeddb */
  useEffect(() => {
    if (disabled) {
      return
    }

    const idb = new IndexeddbPersistence({ cacheName: indexeddbCacheName(entityId), doc })
    const onSynced = () => {
      logger.debug(`useDBSync: Synced with local IndexedDB`)
      setIsSynced(true)
    }

    idb.on('synced', onSynced)
    idbRef.current = idb

    invariant(markAsReady.current, `useDBSync: markAsReady.current is not set`)
    markAsReady.current()

    /** Cleanup when closing */
    return () => {
      idb.off('synced', onSynced)
      // eslint-disable-next-line no-underscore-dangle
      if (idb._storeTimeoutId) {
        logger.debug(`useDBSync: Pending database update. Not destroying the database.`)
      } else {
        idb.destroy()
      }
    }
  }, [disabled, doc, entityId])

  /**
   * Sync from local IndexedDB store to doc
   */
  const sync = useCallback(async () => {
    invariant(markAsReady.current, `useDBSync: markAsReady.current is not set`)
    await readyPromise.current
    invariant(idbRef.current, `useDBSync: idbRef.current is not set`)

    logger.debug(`useDBSync: starting sync with local IndexedDB...`)

    return idbRef.current.sync()
  }, [])

  return { isSynced, sync }
}
