import { logger, shortUuid } from '@tunasong/models'
import { features, useSelector, useStore, useThunkDispatch } from '@tunasong/redux'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import type { IAudioContext, IMediaElementAudioSourceNode } from 'standardized-audio-context'
import { useAudioEngine } from './audio-engine.js'
import { useMixer } from './mixer.hook.js'
import { useDelayedInvoke } from '@tunasong/ui-lib'
import invariant from 'tiny-invariant'

export interface PlayerProps {
  /** Name of the player to be shown in the Mixer */
  el: HTMLMediaElement | null
  name?: string
  volume?: number
  /** Automatically stop when another player is started */
  autoStop?: boolean

  onPlay?(): void
  onPause?(): void
}

export interface MixedPlayer {
  audioProps: Partial<React.DetailedHTMLProps<React.AudioHTMLAttributes<HTMLAudioElement>, HTMLAudioElement>>
  playing: boolean
  duration: number
  position: number
  play(): void
  pause(): void
  seek(seconds: number): void
  togglePlay(): void
}

/**
 * The AudioPlayer will play a single audio file. Events will he scheduled on the Timeline.
 * Only one AudioPlayer works at the same time because the Timeline is a singleton connected to
 * the AudioContext.
 */

export const useAudioPlayer = ({
  el,
  volume = 1.0,
  name = 'Audio Player',
  onPause,
  onPlay,
}: PlayerProps): MixedPlayer => {
  const mixer = useMixer()

  const audioEngine = useAudioEngine()
  const dispatch = useThunkDispatch()
  const { getState } = useStore()

  const playing = Boolean(el && el.paused !== true)

  /** Register channel in the mixer */
  const currentEl = useRef<HTMLMediaElement | null>(null)
  const elSource = useRef<IMediaElementAudioSourceNode<IAudioContext> | null>(null)

  const channelId = useRef<string>()

  const removeChannel = useCallback(() => {
    if (!channelId.current) {
      return
    }

    /**
     * We don't disconnect MediaElementSource here, we reuse it to avoid:
     * HTMLMediaElement already connected previously to a different MediaElementSourceNode
     */
    mixer.removeChannel(channelId.current)
    channelId.current = undefined
  }, [mixer])

  const setupChannel = useCallback(() => {
    invariant(el, 'No media element')
    invariant(mixer, 'No mixer')

    // Remove previous channel
    removeChannel()

    const id = `audioplayer-${shortUuid()}`
    channelId.current = id

    /** If we use the same MediaElement we cannot re-create the media source */

    const source =
      el === currentEl.current && elSource.current ? elSource.current : mixer.context.createMediaElementSource(el)

    elSource.current = source

    mixer.addChannel(
      {
        id,
        name,
        type: 'general',
        gain: volume,
        inputNode: source,
      },
      'media'
    )
    currentEl.current = el
    return el
  }, [el, mixer, name, removeChannel, volume])

  const play = useCallback(async () => {
    if (!el) {
      return
    }
    await audioEngine.start()

    setupChannel()
    if (onPlay) {
      onPlay()
    }
    dispatch(features.audio.actions.setPlayer({ isPlaying: true, name, url: el.src }))
    el.pause()

    return el.play().catch(err => {
      logger.error('Error playing', err)
      throw err
    })
  }, [audioEngine, dispatch, el, name, onPlay, setupChannel])

  const seek = (seconds: number) => {
    if (!el) {
      return
    }
    /** We will sync the transport to this when seek is done */
    // eslint-disable-next-line react-compiler/react-compiler
    el.currentTime = seconds
  }
  const pause = useCallback(() => {
    if (!el) {
      return
    }
    removeChannel()

    if (getState().audio.player.isPlaying === true && getState().audio.player.url === el.src) {
      dispatch(features.audio.actions.setPlayer({ isPlaying: false }))
    }
    el.pause()
    if (onPause) {
      onPause()
    }
  }, [dispatch, el, getState, onPause, removeChannel])

  /** Update position. We debounce to avoid a lot of updates */
  const [position, setPosition] = useState(0)
  const debouncePosition = useDelayedInvoke(100)
  useEffect(() => {
    if (!el) {
      return
    }
    const handleTimeUpdate = () => debouncePosition(() => setPosition(el.currentTime))
    el.addEventListener('timeupdate', handleTimeUpdate)
    return () => {
      el.removeEventListener('timeupdate', handleTimeUpdate)
    }
  }, [debouncePosition, el, playing])

  const togglePlay = async () => {
    if (!el) {
      return
    }
    if (el.paused) {
      await play()
    } else {
      pause()
    }
  }
  const [duration, setDuration] = useState(el?.duration ?? 0)

  /** The URL of the global playing audio */
  const { url: globalPlayingUrl, isPlaying } = useSelector(state => state.audio.player)
  useEffect(() => {
    if (!el) {
      return
    }

    if (isPlaying && globalPlayingUrl && globalPlayingUrl !== el.src) {
      pause()
    }
  }, [el, globalPlayingUrl, isPlaying, pause])

  /** Update playing status */
  useEffect(() => {
    if (!el) {
      return
    }
    // const handlePause = () => dispatch(features.audio.actions.setPlayer({ isPlaying: false }))
    const handleDuration = (ev: Event) => {
      setDuration((ev.target as HTMLMediaElement).duration)
    }
    // this prevents us from auto-playing the next track
    // el.addEventListener('ended', handlePause)
    el.addEventListener('durationchange', handleDuration)

    return () => {
      // el.removeEventListener('ended', handlePause)
      el.removeEventListener('durationchange', handleDuration)
    }
  }, [dispatch, el, name])

  return {
    playing,
    play,
    seek,
    pause,
    togglePlay,
    duration,
    position,
    audioProps: {
      crossOrigin: 'anonymous',
    },
  }
}
