/* eslint-disable tss-unused-classes/unused-classes */
import { createTheme } from '@mui/material'
import { makeStyles } from '@tunasong/ui-lib'

export const roomTheme = createTheme({
  typography: {
    fontSize: 12,
  },
})

export const useStyles = makeStyles()(theme => ({
  root: {
    height: '100%',
    display: 'flex',
  },
  grid: {
    height: '50%',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  item: {
    display: 'flex',
    flex: 1,
    height: '100%',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  entities: {
    overflowY: 'auto',
  },
  stream: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
  },

  text: {
    top: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flex: 1,
    // width: '100%',
    height: '100%',
    alignItems: 'center',
    overflow: 'hidden',
  },
  sidebar: {
    maxWidth: 300,
    position: 'relative',
  },
  overlay: {
    background: 'transparent',
    opacity: 0.9,
    width: 300,
    position: 'fixed',
    right: 0,
    bottom: 40,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  playIcon: {
    width: '4em',
    height: '4em',
  },
  playButton: {},
}))

export default useStyles
