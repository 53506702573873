import woodBlock from '@tunasong/media/audio/woodblock.wav'
import { useRedraw } from '@tunasong/ui-lib'
import { useAudioEngine, useMixer, useTransport } from '../hooks/index.js'
import { type Rhythm } from '@tunasong/models'
import { useCallback, useEffect, useState } from 'react'
import { Player } from 'tone'

export const useMetronome = (rhythm?: Rhythm) => {
  const engine = useAudioEngine()
  const mixer = useMixer()
  const refresh = useRedraw()
  const { transport } = useTransport()

  useEffect(() => {
    if (!rhythm) {
      return
    }
    engine.rhythm = rhythm
  }, [engine, rhythm, transport])

  const [playing, setPlaying] = useState(false)

  /** Tik / tok - use this to trigger when the metronome triggers */
  const [tik, setTik] = useState(false)

  useEffect(() => {
    const player = new Player(woodBlock)

    const metronomeEv = transport.scheduleRepeat(time => {
      player.start(time)

      /** Visual metronome support (tik / tok) */
      engine.draw.schedule(() => {
        setTik(tik => !tik)
      }, time)
    }, '4n')

    /** For custom context(s) we don't send output to the speakers. Clients will use this.output instead */
    mixer.addChannel(
      {
        id: 'metronome',
        gain: 1.0,
        inputNode: player.output as never,
        name: 'metronome',
      },
      'media'
    )
    return () => {
      mixer.removeChannel('metronome')
      transport.clear(metronomeEv)
    }
  }, [engine, mixer, transport])

  const start = useCallback(() => {
    transport.start()

    setPlaying(true)
  }, [transport])

  const stop = useCallback(() => {
    transport.stop()
    /** Reset color */
    setPlaying(false)
  }, [transport])

  const toggle = useCallback(() => {
    const playing = transport.state === 'started'
    if (playing) {
      stop()
    } else {
      start()
    }
    /** Since transport.state is not under react control, we need to refresh here */
    refresh()
  }, [refresh, start, stop, transport.state])

  return { start, stop, toggle, playing, tik: playing ? tik : false, tok: playing ? !tik : false }
}
