import { type NoteEvent } from '@tunasong/music-lib'
import { Note } from 'webmidi'
import { type MidiNoteEvent } from './midi-event.js'
import { noteToMidiNumber } from './note-to-midi.js'

/** Convert a NodeEvent to MIDI */
export const noteEventToMidi = ({ note, velocity = 1.0 }: NoteEvent): MidiNoteEvent[] => [
  {
    type: 'noteon',
    note: new Note(noteToMidiNumber(note)),
    value: velocity,
  },
  {
    type: 'noteoff',
    note: new Note(noteToMidiNumber(note)),
    // time: duration,
  },
]
