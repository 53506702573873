import { type BaseLayoutSpec } from '@tunasong/plugin-lib'
import type { Entity, Persisted } from '@tunasong/schemas'
import { FullPageProgress, HBox, useUIMode, VBox } from '@tunasong/ui-lib'
import { Suspense, type FC, type ReactNode } from 'react'
import { AppSideBar } from './app-sidebar.js'
import { MobileNavigation } from './mobile-navigation.js'

interface AppChromeProps {
  layouts?: BaseLayoutSpec[]
  entity?: Persisted<Entity>
  showNavigationBar?: boolean | 'dynamic'
  currentLayout?: string
  className?: string
  children?: ReactNode
}

export const AppChrome: FC<AppChromeProps> = ({
  entity,
  showNavigationBar: barMode = 'dynamic',
  children,
  layouts = [],
  currentLayout,
}) => {
  const layoutSpec = layouts.find(layout => layout.name === currentLayout) ?? layouts[0]

  const { appChrome = 'default' } = layoutSpec ?? {}

  const showChrome = appChrome !== 'none'

  const uiMode = useUIMode()

  const showNavigationBar = layoutSpec?.showNavigationBar
    ? true
    : barMode === 'dynamic'
      ? uiMode === 'mobile' && showChrome
      : Boolean(barMode)

  const content = (
    <VBox sx={{ flex: 1, overflow: 'hidden' }}>
      {children}
      {showNavigationBar ? <MobileNavigation entity={entity} /> : null}
    </VBox>
  )

  return (
    <Suspense fallback={<FullPageProgress />}>
      {showChrome ? (
        <VBox sx={{ padding: 0, flex: 1, overflow: 'hidden' }}>
          <HBox sx={{ overflow: 'hidden', flex: 1, pb: showNavigationBar ? 7 : 0 }}>
            {uiMode === 'full' && appChrome !== 'mobile' && <AppSideBar entity={entity} />}
            {content}
          </HBox>
        </VBox>
      ) : (
        <>{content}</>
      )}
    </Suspense>
  )
}
