import { type AudioBus, type AudioChannel, type BusName, logger, shortUuid } from '@tunasong/models'
import { type AudioSourceType } from '@tunasong/schemas'
import type { IAudioContext } from 'standardized-audio-context'
import { BusController } from './bus.js'
import { ChannelController } from './channel.js'
import { type IMixer } from './interfaces.js'

export class Mixer implements IMixer {
  defaultGain = 1.0
  defaultBusGain = 0.7

  /** Channels */
  private readonly channelMap: Record<string, ChannelController | undefined> = {}
  /** Busses. Used for routing for now */
  private readonly busMap: Record<string, BusController | undefined> = {}

  /**
   * The MediaElementAudioDestinationNode interface represents an audio destination consisting
   * of a WebRTC MediaStream with a single AudioMediaStreamTrack,
   * which can be used in a similar way to a MediaStream obtained from Navigator.getUserMedia.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamAudioDestinationNode
   */

  constructor(public context: IAudioContext) {
    /** Local microphone(s). Not monitored. */
    const micBus = this.createBus({ type: 'mic' })

    /** Local media playback. Monitored and possibly latency compensated. */
    const mediaBus = this.createBus({ type: 'media' })

    /** Audio received from peers. Monitored, but not echoed back to peers through mainMix */
    const peerBus = this.createBus({ type: 'peer' })

    /** Local instruments */
    const instrumentBus = this.createBus({ type: 'instrument' })

    /** Record bus. */
    const recordBus = this.createBus({ type: 'record' })
    micBus.connect(recordBus.input)
    mediaBus.connect(recordBus.input)
    peerBus.connect(recordBus.input)

    /** Share bus. This audio is sent to the peers */
    const shareBus = this.createBus({ type: 'share' })
    micBus.connect(shareBus.input)
    mediaBus.connect(shareBus.input)
    instrumentBus.connect(shareBus.input)

    /** Monitor. This audio is sent to the speakers */
    const monitorBus = this.createBus({ type: 'monitor' })
    mediaBus.connect(monitorBus.input)
    peerBus.connect(monitorBus.input)
    instrumentBus.connect(monitorBus.input)

    /** Connect monitor bus to the speakers */
    monitorBus.connect(this.context.destination)
  }

  get busses() {
    return Object.values(this.busMap).filter(Boolean)
  }

  get channels() {
    return Object.values(this.channelMap).filter(Boolean)
  }

  get solo() {
    return this.channels.reduce<boolean>((p, c) => p || c.solo, false)
  }

  createBus = ({ type, delayCompensation = 0 }: { type: BusName; delayCompensation?: number }) => {
    const newBus: AudioBus = {
      name: type,
      id: shortUuid(),
      muted: false,
      delay: delayCompensation,
      gain: this.defaultBusGain,
    }
    const controller = new BusController(this, newBus)

    this.busMap[type] = controller
    return controller
  }

  getBus = (busName: BusName) => {
    const bus = this.busMap[busName]
    if (!bus) {
      throw new Error(`Unknown bus: ${busName}`)
    }
    return bus
  }

  getChannel = (channelId: string) => this.channelMap[channelId]

  getChannelByName = (name: string) => {
    const channel = Object.values(this.channelMap).filter(c => c && c.name === name)
    return channel[0]
  }

  getStream = (name: BusName) => {
    const bus = this.busMap[name]
    if (!bus) {
      return null
    }
    const outputStream = this.context.createMediaStreamDestination()
    bus.connect(outputStream)

    return outputStream
  }

  /** Update the channels based on channel solo information. */
  updateSolo = () => {
    const hasSoloChannel = this.solo
    for (const channel of this.channels) {
      channel.syncSoloGain(hasSoloChannel)
    }
  }

  muteChannels = (type: AudioSourceType, muted = true) => {
    const ch = this.channels.filter(c => c.type === type)
    ch.forEach(c => (c.muted = muted))
  }

  getTracks = (name: BusName) => {
    const outputStream = this.getStream(name)
    return outputStream ? outputStream.stream.getTracks() : null
  }

  addChannel = (audioSource: AudioChannel, busName: BusName) => {
    /** Check the bus exists */
    const bus = this.busMap[busName]
    if (!bus) {
      throw new Error(`Bus ${busName} does not exist in Mixer`)
    }

    if (this.channelMap[audioSource.id]) {
      logger.warn(`Channel source ${audioSource.id} already exists. Replacing it.`)
      this.removeChannel(audioSource.id)
    }
    const channel = new ChannelController(this, audioSource)

    channel.connect(bus.input)

    this.channelMap[audioSource.id] = channel

    return channel
  }

  removeChannel = (id: string) => {
    const controller = this.channelMap[id]
    if (!controller) {
      return
    }
    controller.disconnect()

    delete this.channelMap[id]
    controller.stop()
  }

  removeSources = (type: AudioSourceType) => {
    this.channels.filter(c => c.type === type).forEach(c => this.removeChannel(c.id))
  }

  /** @todo is channel disconnect() enough to remove the channel? */
  stop = () => {
    for (const channel of this.channels) {
      channel.disconnect()
    }
  }
}
