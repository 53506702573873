import { isMention, shortUuid, type Owned } from '@tunasong/models'
import { entitiesApi, useChildrenIncremental, useEntityUpdate, useSelector } from '@tunasong/redux'
import {
  chatSchema,
  isComment,
  isPersisted,
  persistedSchema,
  type Chat,
  type Comment,
  type Entity,
  type EntityOrElement,
  type Persisted,
} from '@tunasong/schemas'
import { useCurrentUser } from '@tunasong/ui-lib'
import { useCallback, useMemo } from 'react'
import { Node } from 'slate'
import invariant from 'tiny-invariant'

export const useChat = <T extends EntityOrElement>({
  element,
  pageSize = 25,
}: {
  element?: T | null
  pageSize?: number
}) => {
  const { userId } = useCurrentUser()
  const presence = useSelector(state => (element?.id ? state.presence.activeClients[element.id] : null))
  const activeUsers = useMemo(() => (presence ? Object.values(presence) : []), [presence])
  const [createEntity] = entitiesApi.useCreateEntityMutation()
  const updateEntity = useEntityUpdate<Chat>({ debounceDelay: 0 })

  const externalItems = useSelector(state => state.entities.receivedEntities)

  const {
    entities: incrementalEntities = [],
    isFetching,
    isLoading,
    addOptimistic,
    getNext,
  } = useChildrenIncremental<Persisted<Comment>>({
    pageSize,
    includeSys: true,
    parentId: element?.id,
    filter: 'comment',
  })

  const comments = useMemo(
    () => [
      ...incrementalEntities,
      ...(externalItems.filter(
        e => element?.id && e.parentId && e.parentId === element.id && isComment(e)
      ) as Persisted<Comment>[]),
    ],
    [element?.id, externalItems, incrementalEntities]
  )

  const create = useCallback(
    async (parent: Persisted<Entity>) => {
      /** @todo we use AUTHENTICATED ACL here */
      const chat: Chat & Partial<Owned> = {
        type: 'chat',
        userId,
        parentId: parent.id,
        /** @todo previously for public chats in video rooms */
        // acls: [{ principal: PrincipalType.AUTHENTICATED, permission: 'READ' }],
      }
      const result = createEntity({ entity: chat, parent }).unwrap()
      return chatSchema.merge(persistedSchema).parse(result)
    },
    [createEntity, userId]
  )

  const addComment = useCallback(
    async (comment: Comment) => {
      invariant(userId && element?.id, `Cannot addComment: userId: ${userId}, id: ${element?.id}`)
      /** @todo the ACLs should not really be set here */
      const cc = {
        /** Set the ID to get immediate response in the UI */
        id: shortUuid(),
        parentId: element.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        ...comment,
        // acls: [{ principal: PrincipalType.AUTHENTICATED, permission: 'READ' }],
      }
      addOptimistic(cc)
      return createEntity({ entity: cc, parent: element as never }).unwrap() as Promise<Persisted<Comment>>
    },
    [addOptimistic, createEntity, element, userId]
  )

  const resolveComment = useCallback(
    (c: Comment) => {
      if (!(element && isPersisted(c))) {
        throw new Error(`Unable to update comment when element is null or comment.id is null`)
      }

      return updateEntity(c.id, { resolved: true })
    },
    [element, updateEntity]
  )

  const sortedComments = useMemo(() => comments?.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)), [comments])
  const activeComments = useMemo(() => sortedComments?.filter((e: Comment) => Boolean(!e.resolved)), [sortedComments])
  const resolvedComments = useMemo(() => sortedComments?.filter(e => Boolean(e.resolved)), [sortedComments])

  const isMentioned = useMemo(
    () =>
      activeComments
        ? Boolean(
            activeComments.find(comment => {
              for (const [node] of Node.descendants(comment)) {
                if (isMention(node) && node.userId === userId) {
                  return true
                }
              }
              return false
            })
          )
        : false,
    [activeComments, userId]
  )

  return {
    comments,
    getNext,
    isMentioned,
    activeComments,
    resolvedComments,
    activeUsers,
    create,
    addComment,
    resolveComment,
    isLoading,
    isFetching,
  }
}
