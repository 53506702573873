import {
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Typography,
  capitalize,
  type MenuItemProps,
} from '@mui/material'
import { dayjs, isTimeStamp, type SearchSummary } from '@tunasong/models'
import { useElementTypeMedia } from '@tunasong/plugin-lib'
import { isProfile } from '@tunasong/schemas'
import { TunaBreadcrumbs, useMainColors } from '@tunasong/ui-lib'
import React, { type ForwardedRef } from 'react'

export interface SearchResultListItemProps extends MenuItemProps {
  className?: string
  icon?: JSX.Element
  element: SearchSummary
  /** Show details */
  showDetails?: boolean
  /** Make the breadcrumbs active links */
  breadCrumbLinks?: boolean
  actions?: React.ReactElement | null
}

export const SearchResultListItem = React.forwardRef(
  (props: SearchResultListItemProps, ref: ForwardedRef<HTMLLIElement>) => {
    const { element, showDetails = true, actions, icon, breadCrumbLinks = true, ...restProps } = props
    const { classes: colorClasses } = useMainColors()
    const { materialColor: color } = useElementTypeMedia(element.type)

    const details = element?.type ? (
      <Typography variant="caption">
        <TunaBreadcrumbs
          sx={{ color: theme => theme.palette.action.disabled }}
          parentChain={[element, ...(element.parentChain ?? [])].reverse()}
          variant="caption"
          showHome={false}
          showLeaf={false}
          activeLinks={breadCrumbLinks}
        />
        <span className={colorClasses[color]}>{capitalize(element.type)}</span>.{' '}
        {isTimeStamp(element) ? `Updated ${dayjs(element.updatedAt).fromNow()}` : ''}
      </Typography>
    ) : null

    const name = isProfile(element) ? element.name ?? element.email : element.name
    return (
      <MenuItem {...restProps} ref={ref}>
        <ListItemIcon>{icon}</ListItemIcon>

        <ListItemText
          disableTypography
          sx={{
            overflow: 'hidden',
            paddingRight: 4,
          }}
          primary={
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 500,
              }}
            >
              {name}
            </Typography>
          }
          secondary={showDetails ? details : null}
        ></ListItemText>

        <ListItemSecondaryAction>{actions}</ListItemSecondaryAction>
      </MenuItem>
    )
  }
)

export default SearchResultListItem
