import type { SearchRequest } from '@tunasong/schemas'
import type { QueryProps } from './query-props.js'
/** Default full-text query for entities */
const query = ({ query, size = 100, filter }: QueryProps): SearchRequest =>
  ({
    index: 'entities',
    size,
    from: 0,
    /** embeddings are large - don't return them by default */
    _source_excludes: ['embedding', 'acls'],
    body: {
      query: {
        bool: {
          must: [
            {
              query_string: {
                query,
                fields: ['name^10', 'description^3', 'freetext'],
              },
            },
          ],
          filter: [
            {
              bool: {
                must_not: [
                  // additional filters here
                  { term: { trash: true } },
                ],
                must: [
                  // additional filters here
                  filter?.types && filter.types.length > 0
                    ? {
                        terms: { type: filter.types },
                      }
                    : null,
                  filter?.tags && filter.tags.length > 0 ? { terms: { tags: filter.tags } } : null,
                ].filter(Boolean),
              },
            },
          ],
        },
      },
    },
  }) satisfies SearchRequest

export default query
