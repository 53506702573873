import { Box, Divider, Link, Typography } from '@mui/material'

import React from 'react'
import { DeadFish } from '@tunasong/icons'

export interface ErrorProps {
  error?: unknown
}

export const ErrorGeneral = ({ error }: ErrorProps) => (
  <Box
    sx={{
      margin: theme => theme.spacing(4),
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'auto',
    }}
  >
    <DeadFish
      style={{
        // color: theme.vars.palette.error.main,
        width: 300,
        height: 300,
        marginBottom: 20,
      }}
    />
    <Typography variant="h3">A dead Tuna is a sad Tuna.</Typography>
    <Typography variant="subtitle1">
      If the problem persists, contact <Link href="mailto:hello@tunasong.com">hello@tunasong.com</Link>.
    </Typography>

    <Divider sx={{ p: 1 }} />
    {error instanceof Error ? <code>{error.stack}</code> : null}
  </Box>
)
