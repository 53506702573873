import { type FC, useRef } from 'react'
import { CoreBlock, type CoreBlockProps } from './core-block.js'
import { useDndBlock } from '@udecode/plate-dnd'

export const DndBlock: FC<CoreBlockProps> = props => {
  const nodeRef = useRef<HTMLDivElement>(null)

  const { dropLine, dragRef, isDragging } = useDndBlock({
    id: props.element.id as string,
    nodeRef,
  })

  return <CoreBlock {...props} dropLine={dropLine} dragRef={dragRef} blockRef={nodeRef} isDragging={isDragging} />
}
