import { type TunaEditor } from '@tunasong/plugin-lib'
import { useEffect, useState } from 'react'

export const useEditorHTMLElement = (editor?: TunaEditor | null) => {
  const [htmlElement, setHTMLElement] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (!editor?.id) {
      return
    }
    const domNode = document.querySelector(`[id="editor-container-${editor.id}"]`) as HTMLElement
    setHTMLElement(domNode)
  }, [editor?.id])

  return htmlElement
}
