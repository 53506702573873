import { Typography, useTheme } from '@mui/material'
import { type SerializedError } from '@reduxjs/toolkit'
import { type FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { formatError } from '@tunasong/redux'
import { makeStyles } from '@tunasong/ui-lib'
import { type FC } from 'react'
import { Browser } from 'react-kawaii'

const useStyles = makeStyles()(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(8),
  },
}))

export interface ErrorMessageProps {
  message?: string | JSX.Element
  rtkQueryError?: SerializedError | FetchBaseQueryError
}
export const ErrorMessage: FC<ErrorMessageProps> = props => {
  const { message = 'Whops. Something went wrong.', rtkQueryError } = props
  const { classes } = useStyles()
  const theme = useTheme()

  const rtkQueryErrorText = rtkQueryError ? formatError(rtkQueryError) : null

  const text = rtkQueryErrorText ?? message

  return (
    <div className={classes.root}>
      <Browser size={200} mood="sad" color={theme.vars.palette.secondary.main} />
      <Typography variant="subtitle1">{text}</Typography>
    </div>
  )
}
