import { type Track } from '@tunasong/schemas'
import { useCallback, useMemo } from 'react'
import { useDelayedInvoke, useRedraw } from '@tunasong/ui-lib'
import { useMixer } from '../../hooks/index.js'
import { useTrackChannel } from './track-channel.js'

export interface TrackControl {
  track: Track
  onUpdate?(track: Partial<Track>): void
}
export const useTrackControl = ({ track, onUpdate = () => {} }: TrackControl) => {
  const mixer = useMixer()
  const refresh = useRedraw()

  const channel = useTrackChannel(track)

  /** Create or get an existing channel for the Track */

  const toggleMute = useCallback(() => {
    if (!(mixer && channel)) {
      return
    }
    channel.muted = !channel.muted
    onUpdate({ muted: channel.muted })
    refresh()
  }, [channel, mixer, onUpdate, refresh])

  const toggleSolo = useCallback(() => {
    if (!channel) {
      return
    }
    channel.solo = !channel.solo
    onUpdate({ solo: channel.solo })
    refresh()
  }, [channel, onUpdate, refresh])

  const toggleArm = useCallback(() => {
    if (!channel) {
      return
    }
    channel.armed = !channel.armed

    /** @todo more flexible routing here */

    onUpdate({ armed: channel.armed })
    refresh()
  }, [channel, onUpdate, refresh])

  const delayedVolume = useDelayedInvoke(500)
  const onChangeVolume = useCallback(
    (vol: number) => {
      if (!(channel && mixer)) {
        return
      }
      channel.gain = vol
      delayedVolume(() => onUpdate({ gain: channel.gain }))
      refresh()
    },
    [channel, delayedVolume, mixer, onUpdate, refresh]
  )

  const delayedPan = useDelayedInvoke(500)
  const onChangePan = useCallback(
    (pan: number) => {
      if (!(channel && mixer)) {
        return
      }
      channel.pan = pan
      delayedPan(() => onUpdate({ pan: channel.pan }))
      refresh()
    },
    [channel, delayedPan, mixer, onUpdate, refresh]
  )

  const volume = channel?.gain ?? 0
  const pan = channel?.pan

  return useMemo(
    () => ({
      toggleMute,
      toggleSolo,
      toggleArm,
      onChangeVolume,
      onChangePan,
      volume,
      pan,
      channel,
    }),
    [channel, onChangePan, onChangeVolume, pan, toggleArm, toggleMute, toggleSolo, volume]
  )
}
