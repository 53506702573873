import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useCreateEditor } from '@tunasong/editor'
import { type Maturity } from '@tunasong/models'
import { useGlobalAppContext, usePlugins } from '@tunasong/plugin-lib'
import type { CommandHotkey } from '@tunasong/schemas'
import { RecordHotkey, makeLabel } from '@tunasong/ui-lib'
import { useCallback, useMemo, useState, type FC } from 'react'
import invariant from 'tiny-invariant'

export interface CommandHotkeysProps {
  maturity?: Maturity
  hotkeys: CommandHotkey[]
  onChange(hotkeys: CommandHotkey[]): void
}

export const CommandHotkeys: FC<CommandHotkeysProps> = props => {
  const { hotkeys, maturity, onChange } = props
  const plugins = usePlugins('all')
  const editor = useCreateEditor(null, plugins)
  const globalContext = useGlobalAppContext()

  /** The commands require an editor, so we fake it for now */
  const commands = useMemo(() => {
    if (!(plugins && editor)) {
      return []
    }
    return plugins
      .flatMap(plugin => plugin.getEditorCommands?.({ editor, maturity, text: '', globalContext }))
      .filter(Boolean)
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [editor, globalContext, maturity, plugins])

  const [showRecord, setShowRecord] = useState(false)
  const [activeCommandId, setActiveCommandId] = useState<string>()
  const handleShow = useCallback(
    (commandId: string) => () => {
      setActiveCommandId(commandId)
      setShowRecord(true)
    },
    []
  )
  const handleHide = useCallback(() => setShowRecord(false), [])
  const handleChange = useCallback(
    (hotkey: string) => {
      invariant(activeCommandId, 'No active command')
      /** Remove existing bindings for this key and commandId*/
      const filteredHotkey = hotkeys.filter(h => h.hotkey !== hotkey && h.commandId !== activeCommandId)

      const label = commands.find(cmd => cmd.id === activeCommandId)?.name
      invariant(label, 'No label for command')
      const updated = [...filteredHotkey, { commandId: activeCommandId, hotkey, label }]
      onChange(updated)
      setActiveCommandId(undefined)
      setShowRecord(false)
    },
    [activeCommandId, commands, hotkeys, onChange]
  )

  const handleClear = (hotkey: CommandHotkey) => () => {
    const filteredHotkeys = hotkeys.filter(h => h !== hotkey)
    onChange(filteredHotkeys)
  }

  return (
    <>
      <RecordHotkey open={showRecord} onChange={handleChange} onClose={handleHide} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Command</TableCell>
            <TableCell>Hotkey</TableCell>
            <TableCell>Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {commands.map(cmd => {
            const hotkey = hotkeys.find(h => h.commandId === cmd.id)
            return (
              <TableRow key={cmd.id}>
                <TableCell>
                  {cmd.name} ({cmd.id})
                </TableCell>
                <TableCell>
                  {hotkey ? (
                    <>
                      {makeLabel(hotkey.hotkey)}
                      <Button onClick={handleClear(hotkey)}>Clear</Button>
                    </>
                  ) : null}
                </TableCell>
                <TableCell>
                  <Button onClick={handleShow(cmd.id)}>Edit...</Button>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}

export default CommandHotkeys
