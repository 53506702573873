/** Get the default gradients we use */

import { type Theme } from '@mui/material'
import { type ColorStop } from './wave-canvas-draw.js'

interface GetWaveGradient {
  type?: 'audio' | 'monitor'
  theme: Theme
}

export const getWaveGradient = ({ theme, type = 'audio' }: GetWaveGradient): ColorStop[] => {
  if (type === 'audio') {
    return [
      [0, theme.palette.primary.main],
      [0.5, theme.palette.primary.light],
      [1, theme.palette.primary.main],
    ]
  }
  if (type === 'monitor') {
    return [
      [0, theme.palette.warning.dark],
      [0.5, theme.palette.text.primary],
      [1, theme.palette.warning.dark],
    ]
  }
  throw new Error(`Unknown wave type: ${type}`)
}
