import { isPersisted, type Entity } from '@tunasong/schemas'
import { useEffect, useRef, type FC } from 'react'
import invariant from 'tiny-invariant'
import { Box, CircularProgress } from '@mui/material'
import { Y } from '@tunasong/sync-lib'
import { useDBSync, useYDocWebSocket } from '../hooks/index.js'
import { CollabEditor, type CollabEditorProps } from './collab-editor.js'
import { logger } from '@tunasong/models'

export interface CollabWebSocketEditorProps
  extends Omit<CollabEditorProps, 'provider' | 'doc' | 'entityId' | 'awareness'> {
  webSocket: WebSocket | null
  element: Entity
  doc: Y.Doc
  color: string
  name: string
}

export const CollabWebsocketEditor: FC<CollabWebSocketEditorProps> = props => {
  const { doc, element: entity, webSocket, readOnly, ...restProps } = props

  invariant(isPersisted(entity), 'CollabWebsocketEditor requires a persisted entity')

  /** Resync with peers (step1) every minute */
  const resyncInterval = 60 * 1000

  const { isSynced, sync } = useDBSync({ entityId: entity.id, doc })

  const { provider, awareness } = useYDocWebSocket({
    doc,
    entityId: entity.id,
    initialSync: !readOnly,
    resyncInterval,
    localDiffSync: isSynced,
    webSocket,
    receiveOnly: Boolean(readOnly),
  })
  const hasSyncedWithProvider = useRef(false)

  useEffect(() => {
    if (isSynced) {
      return
    }
    sync()
  }, [isSynced, sync])

  useEffect(() => {
    if (hasSyncedWithProvider.current || !isSynced || !provider) {
      return
    }
    logger.debug(`Syncing local state with peers over Websocket`, { isSynced, provider })
    provider.persistLocalChanges()
    hasSyncedWithProvider.current = true
  }, [isSynced, provider])

  return isSynced ? (
    <CollabEditor
      key={entity.id}
      {...restProps}
      doc={doc}
      provider={provider}
      awareness={awareness}
      element={entity}
      readOnly={readOnly}
    />
  ) : (
    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </Box>
  )
}

export default CollabWebsocketEditor
