import { List, Paper } from '@mui/material'
import { Popup, type UIExtensionItemMenuProps, useHotkey } from '@tunasong/ui-lib'
import { type FC, useCallback, useState } from 'react'
import invariant from 'tiny-invariant'
import { useEntityMenuItems } from '../chrome/entity-menu.hook.js'

/** @note don't render this for every item in long list, use anchorEl instead to determine open/closed */
export const EntityActionsMenu: FC<UIExtensionItemMenuProps> = props => {
  const { entity, anchorEl, clickAway, onClose } = props

  useHotkey({
    hotkey: 'Escape',
    handler: onClose,
  })

  invariant(entity, 'Cannot render entity actions menu without an entity')

  const [open, setOpen] = useState(true)
  const handleClose = useCallback(
    (ev?: Event) => {
      if (ev) {
        ev.preventDefault()
        ev.stopPropagation()
      }
      setOpen(false)
      onClose()
    },
    [onClose]
  )

  const handleCloseMenu = useCallback(() => setOpen(false), [])

  const { menuItems, dialogs } = useEntityMenuItems({
    entity,
    onClose: handleClose,
    onCloseMenu: handleCloseMenu,
  })

  return (
    <>
      <Popup open={Boolean(anchorEl && open)} anchorEl={anchorEl} clickAway={clickAway} onClose={handleClose} autoFocus>
        <Paper variant="outlined">
          <List dense>{...menuItems}</List>
        </Paper>
      </Popup>
      {...dialogs}
    </>
  )
}

export default EntityActionsMenu
