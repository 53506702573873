/** Handle mixer and AudioContext */

import { useContext } from 'react'
import { AudioEngineContext } from '../engine/audio-engine-context.js'

/** Mixer. Will resume the audio context once on any click. */
export const useAudioEngine = () => {
  const engine = useContext(AudioEngineContext)
  if (!engine) {
    throw new Error('No audio engine set in AudioEngineContext')
  }
  return engine
}
