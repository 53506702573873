/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from '@tunasong/ui-lib'

export const useStyles = makeStyles()(theme => ({
  root: {
    flexDirection: 'column',
  },
  waveform: {
    '&:hover $controls': {
      display: 'flex',
    },
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  /** The wave container */
  waveContainer: {
    display: 'flex',
    maxHeight: 150,
    flex: 1,
    marginBottom: 2,
  },
  peaksPlaceholder: {
    display: 'block',
    padding: theme.spacing(0, 1, 0, 1),
    position: 'absolute',
    top: theme.spacing(4),
    zIndex: 1,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
  },
  timeline: {},
  rangeOverlay: {
    height: theme.spacing(0.5),
    width: '100%',
    display: 'flex',
  },
  fabs: {
    flexDirection: 'column',
    alignItems: 'space-between',
  },
  recorderFab: {
    marginTop: theme.spacing(),
  },
  hide: {
    display: 'none',
  },
  fab: {
    position: 'absolute',
    left: 'calc(50% - 28px)',
    right: theme.spacing(),
    zIndex: 100,
  },
  showFab: {
    display: 'flex',
  },
  controls: {
    width: '100%',
    position: 'absolute',
    display: 'none',
    alignItems: 'center',
    top: 0,
    right: 0,
    zIndex: 1300,
    opacity: 0.9,
  },
}))

export default useStyles
