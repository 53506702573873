import { type Template, type TunaPlugin } from '@tunasong/plugin-lib'
import { useCallback, useState, type FC } from 'react'
import TemplatePlugins from './templates-plugins.js'
import TemplateSearch from './templates-search.js'

interface TemplateGridProps {
  plugins: TunaPlugin[]
  onSelect: (template: Template) => void
}

export const TemplateGrid: FC<TemplateGridProps> = props => {
  const { onSelect, plugins } = props
  const [selected, setSelected] = useState<Template>()

  const handleSelect = useCallback(
    (template: Template) => {
      setSelected(template)
      onSelect(template)
    },
    [onSelect]
  )

  return (
    <>
      <TemplatePlugins plugins={plugins} selected={selected} onSelect={handleSelect} />

      <TemplateSearch plugins={plugins} selected={selected} onSelect={handleSelect} />
    </>
  )
}

export default TemplateGrid
