import { Typography, type TypographyProps } from '@mui/material'
import { type ChromaSpectrum } from '@tunasong/audio-dsp'
import { shortUuid } from '@tunasong/models'
import { standardNoteNames } from '@tunasong/schemas'
import { HBox } from '@tunasong/ui-lib'
import { useCallback, useEffect, useMemo, type FC } from 'react'
import { AudioController } from '../engine/index.js'

export interface ChromaViewProps extends TypographyProps {
  audioController: AudioController
}

/** Performant real-time view of chroma features */
export const ChromaView: FC<ChromaViewProps> = props => {
  const { audioController, ...typographyProps } = props

  const id = useMemo(() => shortUuid(), [])
  const getElId = useCallback((idx: number) => `chroma-band-${id}-${idx}`, [id])
  const updateOpacity = useCallback(
    (idx: number, opacity: number) => {
      const el = document.getElementById(getElId(idx))
      if (el) {
        el.style.opacity = opacity.toString()
      }
    },
    [getElId]
  )

  const handleChroma = useCallback(
    (ev: CustomEvent<ChromaSpectrum | null>) => {
      /** If the chroma processor is not enabled we can get a null message here if other processors */
      const chroma = ev.detail
      for (const [idx, val] of Object.entries(chroma ?? [])) {
        updateOpacity(Number(idx), val)
      }
    },
    [updateOpacity]
  )

  useEffect(() => {
    audioController.enableDSPFeature('chroma', true)
    audioController.addFeatureListener('chroma', handleChroma)

    return () => {
      /** @todo what if others require chroma? */
      /** @todo perhaps we should automatically check if we have  */
      audioController.enableDSPFeature('chroma', false)
      audioController.removeFeatureListener('chroma', handleChroma)
    }
  }, [audioController, handleChroma])

  return (
    <HBox justifyContent="space-between">
      {standardNoteNames.map((note, idx) => (
        <Typography
          {...typographyProps}
          id={getElId(idx)}
          key={idx}
          sx={{ ...typographyProps.sx, color: theme => theme.palette.secondary.light }}
        >
          {note}
        </Typography>
      ))}
    </HBox>
  )
}

export default ChromaView
