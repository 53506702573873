import { Person, Search } from '@mui/icons-material'
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material'
import { Add, Favorite, Home } from '@tunasong/icons'
import { usePlugins } from '@tunasong/plugin-lib'
import type { EntityType, Persisted } from '@tunasong/schemas'
import { type Entity } from '@tunasong/schemas'
import { useSearchDialog } from '@tunasong/search'
import { useLocation, useNavigate } from '@tunasong/ui-lib'
import { useCallback, useState } from 'react'
import CreateEntityDialog from '../entity/create-entity-dialog.js'

interface MobileNavigationProps {
  /** Name, icon of the views */
  entity?: Persisted<Entity>
  createNew?: EntityType[] | boolean
}
export function MobileNavigation(props: MobileNavigationProps) {
  const { entity, createNew = false } = props

  const location = useLocation()
  const isHome = location.pathname === '/app'
  const isProfile = location.pathname === '/app/profile'
  const isFavorities = location.pathname === '/app/favorites'

  const { dialog: searchDialog, setOpen, open: searchOpen } = useSearchDialog()

  const currentAction = searchOpen
    ? 'Search'
    : isHome
      ? 'Home'
      : isProfile
        ? 'Profile'
        : isFavorities
          ? 'Favorities'
          : null

  const [dialogOpen, setDialogOpen] = useState(false)

  const plugins = usePlugins('all')

  const handleClose = useCallback(({}: { restoreLayout?: boolean } = {}) => {
    setDialogOpen(false)
  }, [])

  const navigate = useNavigate()
  const handleFavorites = () => navigate('/app/favorites')

  if (!plugins) {
    return null
  }

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
        }}
      >
        <BottomNavigation
          sx={{
            backgroundColor: theme => theme.vars.palette.layers,
          }}
          value={currentAction}
        >
          <BottomNavigationAction
            key="home"
            value={'Home'}
            label={'Home'}
            icon={<Home />}
            onClick={() => navigate('/app')}
          />

          {createNew && plugins ? (
            <BottomNavigationAction
              key="nav-new"
              value={'New'}
              label={'New'}
              icon={<Add />}
              onClick={() => setDialogOpen(true)}
            />
          ) : null}
          <BottomNavigationAction
            key="search"
            value={'Search'}
            label={'Search'}
            icon={<Search />}
            onClick={() => setOpen(true)}
          />
          <BottomNavigationAction
            key="favorites"
            value={'Favorities'}
            label={'Favorities'}
            icon={<Favorite />}
            onClick={handleFavorites}
          />

          <BottomNavigationAction
            key="profile"
            value={'Profile'}
            label={'Profile'}
            icon={<Person />}
            onClick={() => navigate('/app/profile')}
          />
        </BottomNavigation>

        <CreateEntityDialog open={dialogOpen} onClose={handleClose} plugins={plugins} parent={entity} />
        {searchDialog}
      </Box>
    </>
  )
}
