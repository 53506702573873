import {
  type DSPFeatureName,
  createDSPWorkletNode,
  type DSPFeatures,
  type DSPFeaturesMessage,
} from '@tunasong/audio-dsp'
import dspWorklet from '@tunasong/audio-dsp/dsp.worklet?url&worker'
import { AudioWorkletNode, type IAudioContext } from 'standardized-audio-context'
import invariant from 'tiny-invariant'
import { AudioController } from './audio-controller.js'

export class DSPController {
  private dspNode: AudioWorkletNode<IAudioContext> | null = null
  private dspEnableFeature: ReturnType<typeof createDSPWorkletNode>['enableFeature'] | null = null
  private workletModule: Promise<void>

  constructor(
    private context: IAudioContext,
    private source: AudioController,
    private onFeatures: (features: DSPFeatures) => void
  ) {
    invariant(context.audioWorklet, 'Audio Context does not support audio worklets')
    invariant(source, 'Source Audio Controller must be specified')
    invariant(onFeatures, 'onFeatures callback must be specified')

    /**
     * Load the worklet module. The worklet will be available
     * as 'dsp-worklet'
     */
    this.workletModule = context.audioWorklet.addModule(dspWorklet)
    this.workletModule
      .then(() => {
        const { dspNode, enableFeature } = createDSPWorkletNode(this.context)

        this.dspNode = dspNode
        this.dspEnableFeature = enableFeature

        dspNode.port.onmessage = this.handleDSPMessage

        source.connect(this.dspNode)
      })
      .catch(err => {
        console.error('Error loading DSP worklet', err)
      })
  }

  get node() {
    return this.dspNode
  }

  disconnect = () => {
    this.dspNode?.disconnect()
    this.dspNode = null
  }

  enableFeature = async (feature: DSPFeatureName, enabled: boolean, atTime?: number) => {
    /** Ensure the module is loaded first */
    await this.workletModule
    invariant(this.dspEnableFeature, 'DSP worklet module not loaded properly')
    this.dspEnableFeature(feature, true, atTime)
  }

  private handleDSPMessage = (e: { data: DSPFeaturesMessage }) => {
    if (e.data.eventType !== 'audiofeatures') {
      return
    }
    const { features } = e.data

    /** Send to listener */
    this.onFeatures(features)
  }
}
