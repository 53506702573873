import { isSection, type Section, type TunaElement } from '@tunasong/models'
import { type NodeEntry, Path, Point, Range } from 'slate'
import { type TunaEditor, Editor, Transforms } from '@tunasong/plugin-lib'
import { type PositionMode } from './position.js'
import type { Scale } from '@tunasong/schemas'
import { RangeQueries } from './range.js'

/** Range for active section */
const currentRange = (editor: TunaEditor): Range | undefined => {
  const entry = Editor.above(editor, { match: node => isSection(node) })
  return entry
    ? {
        anchor: Editor.start(editor, entry[1]),
        focus: Editor.end(editor, entry[1]),
      }
    : undefined
}

const find = (editor: TunaEditor, props: { at?: Range } = {}) => {
  const { at = RangeQueries.all(editor) } = props
  const nodes = Editor.nodes(editor, {
    at,
    match: isSection,
  })
  return Array.from(nodes)
}

const sectionEntries = (editor: TunaEditor, props: { mode?: PositionMode } = {}) => {
  const result: NodeEntry<TunaElement<Section>>[] = []
  if (!editor.selection) {
    return result
  }
  let range: Range | Path | Point | undefined

  const { mode = 'current' } = props
  switch (mode) {
    case 'current':
      range = currentRange(editor) || editor.selection
      break

    case 'previous':
      /** Find first text before anchor */
      const a = Editor.above(editor, { match: isSection })
      const p = a
        ? Editor.previous(editor, {
            at: a[1],
          })
        : Editor.previous(editor)
      range = {
        anchor: Editor.start(editor, []),
        focus: p ? Editor.end(editor, p[1]) : Editor.start(editor, editor.selection.anchor),
      }
      break
    case 'next':
      const entry = Editor.above(editor, { match: isSection })
      const n = Editor.next(editor, { at: entry ? entry[1] : undefined })
      range = {
        anchor: n ? Editor.start(editor, n[1]) : editor.selection.focus,
        focus: Editor.end(editor, []),
      }
  }

  if (!range) {
    return result
  }
  const nodes = Editor.nodes<Section>(editor, { at: range, match: isSection })

  for (const nodeEntry of nodes) {
    result.push(nodeEntry)
  }
  return result
}

const sections = (editor: TunaEditor, props: { mode?: PositionMode } = {}) =>
  sectionEntries(editor, props).map(([section]) => section)

export const sectionEntry = (editor: TunaEditor, props: { mode?: PositionMode } = {}) => {
  const { mode = 'current' } = props
  const s = sectionEntries(editor, props)
  switch (mode) {
    case 'current':
    case 'next':
      return s[0]
    case 'previous':
      /** Need to return the last chord */
      return s.length > 0 ? s[s.length - 1] : undefined
  }
  return undefined
}
export const section = (editor: TunaEditor, props: { mode?: PositionMode } = {}) => sectionEntry(editor, props)?.[0]

export const inSection = (editor: TunaEditor, at?: Path) => {
  const path = at ?? editor.selection?.focus.path
  if (!path) {
    return false
  }
  const parents = Editor.nodes(editor, {
    at: path,
    match: element => isSection(element),
  })
  for (const [parent, parentPath] of parents) {
    const parentOrNode = Path.equals(parentPath, path) || Path.isAncestor(parentPath, path)
    if (parentOrNode && isSection(parent)) {
      return true
    }
  }
  return false
}

export const setSectionScale = (editor: TunaEditor, scale: Scale | null) => {
  const node: Partial<Section> = { scale }
  return Transforms.setNodes(editor, node, { match: isSection, mode: 'all' })
}

export const SectionQueries = {
  inSection,
  find,
  sections,
  sectionEntries,
  section,
  sectionEntry,
  currentRange,
  setSectionScale,
}
