import * as React from 'react'

import { Button } from '@mui/material'
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
  type GridColDef,
  type GridEventListener,
  type GridRowId,
  type GridRowModesModel,
  type GridRowsProp,
} from '@mui/x-data-grid'
import { useMidi } from '@tunasong/audio-ui'
import { Add, Cancel, Delete, Play, Save } from '@tunasong/icons'
import { shortUuid } from '@tunasong/models'
import type { MidiSpec } from '@tunasong/schemas'
import { useFormDialog } from '@tunasong/ui-lib'
import z from 'zod'

interface EditToolbarProps {
  channel?: number
  onChannelChange: (channel: number) => void
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props

  const handleClick = () => {
    const id = shortUuid()
    setRows(oldRows => [...oldRows, { id, name: '', cc: 0, isNew: true }])
    setRowModesModel(oldModel => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }))
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<Add />} onClick={handleClick}>
        Add CC
      </Button>
    </GridToolbarContainer>
  )
}

const toRows = (midi: MidiSpec) =>
  midi.cc.map((cc, index) => ({
    id: index.toString(),
    name: cc.name,
    cc: cc.cc,
    isNew: false,
  }))

type Row = ReturnType<typeof toRows>[number]

interface MidiCCGridProps {
  /** use the specified MIDI channel. If not specified, use Omni (all channels) */
  channel?: number
  spec: MidiSpec
  onChange(spec: MidiSpec): void
}

export function MidiCCEditor({ spec, onChange, channel }: MidiCCGridProps) {
  const [rows, setRows] = React.useState(toRows(spec))
  const { sendCC } = useMidi({ channel })
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const prompt = useFormDialog()

  const handlePlayClick = (id: GridRowId) => async () => {
    const row = rows.find(row => row.id === id)
    if (!row) {
      return
    }
    const formResult = await prompt({
      dialogProps: { title: 'Control Change', maxWidth: 'sm', fullWidth: true },
      schema: z.object({ ccValue: z.number().int().min(0).max(127) }).describe('CC value to send'),
    })
    if (formResult) {
      sendCC({ cc: row.cc, value: formResult.ccValue })
    }
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    const updatedRows = rows.filter(row => row.id !== id)
    setRows(updatedRows)
    saveRows(updatedRows)
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })

    const editedRow = rows.find(row => row.id === id)
    if (editedRow?.isNew) {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const saveRows = (rows: Row[]) => {
    onChange({
      ...spec,
      cc: rows.map(row => ({
        name: row.name,
        cc: Number(row.cc),
      })),
    })
  }

  const processRowUpdate = (newRow: Row) => {
    const updatedRow = { ...newRow, isNew: false }
    const updatedRows = rows.map(row => (row.id === newRow.id ? updatedRow : row))
    saveRows(updatedRows)
    setRows(updatedRows)
    return updatedRow
  }

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', minWidth: 250, editable: true },
    {
      field: 'cc',
      headerName: 'CC',
      type: 'number',
      minWidth: 150,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ]
        }

        return [
          <GridActionsCellItem icon={<Play />} label="Play" onClick={handlePlayClick(id)} color="inherit" />,
          <GridActionsCellItem icon={<Delete />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />,
        ]
      },
    },
  ]

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar as never,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </>
  )
}
