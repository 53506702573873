import { Range, Text } from 'slate'
import { type TunaEditor, Editor, Transforms } from '@tunasong/plugin-lib'
import { type PositionMode } from './position.js'
import type { TText } from '@udecode/slate'

export function getWordBoundaries(str = '', startPos: number, mode: PositionMode = 'current') {
  // Perform type conversions.
  str = String(str)
  // eslint-disable-next-line no-bitwise
  startPos = Number(startPos) >>> 0
  /** Index to begin the search. Use to cut-off for next search */
  let begin = 0
  switch (mode) {
    case 'previous':
      /** Find the first space in a reversed string*/
      begin = str
        .slice(0, startPos + 1)
        .split('')
        .reverse()
        .join('')
        .search(/\s/)
      startPos -= begin >= 0 ? begin + 1 : 0
      break
    case 'next':
      begin = str.slice(startPos).search(/\s/)
      startPos += begin >= 0 ? begin + 1 : 0
      break
  }
  /** If begin is defined we return data for non-matches at the end/beginning of the string */
  const hasBegin = typeof begin !== 'undefined' ? begin >= 0 : false
  const left = str.slice(0, startPos + 1).search(/\S+\s*$/) // Allow whitespace at the end
  const right = str.slice(startPos).search(/\s+/)

  /** For previous and next we require some sort of begin offset */
  if ((mode === 'next' || mode === 'previous') && !hasBegin) {
    return
  }

  if (right < 0) {
    /** If begin is >= 0 then we have a next */
    return [left, str.length]
  }
  if (left < 0) {
    return [0, right + startPos]
  }

  // Return the word, using the located bounds to extract it from the string, not including the space
  return [left, right + startPos]
}

export function getWordAt(str = '', startPos = 0) {
  const boundary = getWordBoundaries(str, startPos)
  return boundary ? str.slice(boundary[0], boundary[1]) : undefined
}

/** Set the selection to word as specified by the mode - i.e., previous, current or next */
function selection(editor: TunaEditor, props: { mode: PositionMode }) {
  if (!editor.selection) {
    return
  }
  const { mode = 'current' } = props
  const { focus } = editor.selection
  const { path } = focus
  const [node] = Editor.node(editor, focus)
  if (!Text.isText(node)) {
    return undefined
  }
  const boundaries = getWordBoundaries(node.text, focus.offset, mode)
  if (!boundaries) {
    return undefined
  }
  const [start, end] = boundaries
  return {
    anchor: { path, offset: start },
    focus: { path, offset: end },
  }
}

function move(editor: TunaEditor, props: { mode: PositionMode }) {
  const { mode } = props
  Transforms.move(editor, { unit: 'word', edge: 'anchor', reverse: mode === 'previous' })
  Transforms.move(editor, { unit: 'word', edge: 'focus', reverse: mode === 'previous' })
}

/** Get the word at the current selection */
function text(editor: TunaEditor, props: { mode: PositionMode }): string | undefined {
  const sel = selection(editor, props)
  if (!sel) {
    return
  }
  const [node] = Editor.node(editor, sel)
  if (!Text.isText(node)) {
    return
  }
  return node.text ? node.text.slice(sel.anchor.offset, sel.focus.offset) : undefined
}

/** Get all the words - i.e., all the text nodes joined together separated by whitespace. */
function find(editor: TunaEditor, at?: Range): string {
  const nodes = Editor.nodes<TText>(editor, { match: Text.isText, at })
  const texts: string[] = []
  for (const [node] of nodes) {
    texts.push(node.text)
  }

  return texts.filter(s => !!s && s !== ' ').join(' ')
}

export const WordQueries = {
  find,
  selection,
  text,
  move,
}
