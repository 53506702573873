import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ExpandMore } from '@tunasong/icons'
import React, { type FC } from 'react'

export interface CollapsableProps {
  title?: string
  children: React.ReactNode
}

export const Collapsable: FC<CollapsableProps> = ({ title, children }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMore />}>{title}</AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
)

export default Collapsable
