import { logger } from '@tunasong/models'
import { EventEmitter } from '@tunasong/ui-lib'
import { InputChannel, type MessageEvent } from 'webmidi'
import type { BTMidiInputAdapter } from './input-adapter.js'

/** Hack to use the InputChannel._processMidiMessageEvent private function */
interface InputChannelHack extends Omit<InputChannel, '_processMidiMessageEvent'> {
  _processMidiMessageEvent(event: MessageEvent): void
}

// Bluetooth MIDI Output Adapter
export class BtMidiInputChannelAdapter extends EventEmitter<'midimessage' | 'noteon' | 'noteoff'> {
  private webMidiInputChannel: InputChannelHack
  constructor(
    private input: BTMidiInputAdapter,
    private channel: number,
    private midiChar: BluetoothRemoteGATTCharacteristic
  ) {
    super()

    // we use this to decode the MIDI message(s)
    this.webMidiInputChannel = new InputChannel(input as never, channel) as unknown as InputChannelHack
    this.webMidiInputChannel.emit = this.emit.bind(this) as never
  }

  handleMessage = (msg: MessageEvent) => {
    logger.debug(`Input channel ${this.channel} MIDI message received`, msg)
    /** @see https://developer.mozilla.org/en-US/docs/Web/API/MIDIMessageEvent */
    const event = { ...msg, port: this.input as never } satisfies MessageEvent
    this.emit('midimessage', event)

    /**
     * this is a hack to use the private method _processMidiMessageEvent to map the MIDI message to
     * the correct MIDI event type (noteon, noteoff, etc.)
     * We have bound the emit function to the webMidiInputChannel object so all the
     * events are emitted from the BtMidiInputChannelAdapter instance.
     */
    // eslint-disable-next-line no-underscore-dangle
    this.webMidiInputChannel._processMidiMessageEvent(event)
  }
}
