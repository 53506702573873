import { dayjs, type Activity, type FeedItem } from '@tunasong/models'
import { entitiesApi, useProfiles, useStore } from '@tunasong/redux'
import { type Persisted } from '@tunasong/schemas'
import { Feed, type FeedProps } from '@tunasong/ui-lib'
import { useCallback, useMemo, type FC } from 'react'
import { summarizeEntityActivities } from './summarize-activities.js'

export interface ActivityStreamProps extends Omit<FeedProps, 'items'> {
  className?: string
  activities: Persisted<Activity>[]
}

export const ActivityStream: FC<ActivityStreamProps> = props => {
  const { className, activities = [], ...restProps } = props

  const { getState } = useStore()
  const getEntity = useCallback(
    (id: string) => entitiesApi.endpoints.loadEntity.select({ id })(getState()).data,
    [getState]
  )

  const userIds = useMemo(() => activities.map(i => i.userId), [activities])
  const { getName } = useProfiles({ userIds })

  const getEntityName = useCallback((id: string) => getEntity(id)?.name ?? 'Unknown', [getEntity])

  const formatContent = useCallback(
    (activity: Persisted<Activity>): string | JSX.Element => {
      if (activity.type === 'create-entity') {
        return `Created a new entity ${getEntityName(activity.entityId)}`
      }
      if (activity.type === 'change-entity') {
        return `Made some edits`
      }
      if (activity.type === 'share') {
        return `Shared with ${activity.principals.map(p => getName(p)).join(', ')}`
      }
      if (activity.type === 'mention') {
        return `Mentioned @${activity.name ?? activity.email}`
      }
      if (activity.type === 'summary') {
        return `Made ${activity.count} changes between ${dayjs(activity.createdAt).format('LL')} and ${dayjs(
          activity.updatedAt
        ).format('LL')}`
      }
      return `Unknown activity`
    },
    [getEntityName, getName]
  )

  const singleEntity = useMemo(() => activities.every(a => a.entityId === activities[0].entityId), [activities])

  const items: FeedItem[] = useMemo(
    () =>
      (singleEntity ? summarizeEntityActivities(activities) : activities).map(item => ({
        id: item.id,
        userId: item.lastEditedBy ?? item.userId,
        content: formatContent(item),
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
      })),
    [activities, formatContent, singleEntity]
  )

  return <Feed className={className} items={items} {...restProps} />
}

export default ActivityStream
