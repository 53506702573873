import { type VirtualElement } from '@popperjs/core'

/** Track the mouse - e.g., from a MouseEvent */
export const trackMouse = (ev: { pageX: number; pageY: number }, margin = 8): VirtualElement => {
  /** Need to use margin to avoid getting the popper in focus when tracking the mouse */
  const x = ev.pageX + margin
  const y = ev.pageY + margin
  const ref = {
    getBoundingClientRect: () => ({
      top: y,
      right: x,
      bottom: y,
      left: x,
      width: 0,
      height: 0,
      x,
      y,
      toJSON: () => '',
    }),
    clientWidth: 0,
    clientHeight: 0,
  }
  return ref
}
