import { Button, MenuItem, Select, type SelectChangeEvent } from '@mui/material'
import { useEditorClass } from '@tunasong/editor'
import { useEntity } from '@tunasong/redux'
import { Dialog } from '@tunasong/ui-lib'
import { useCallback, useState, type FC } from 'react'
import { useReleaseNotes } from './release-notes.hook.js'

export interface ReleaseNotesDialogProps {}

export const ReleaseNotesDialog: FC<ReleaseNotesDialogProps> = props => {
  const {} = props
  const { releaseNotes, latestUnread, setReadVersion } = useReleaseNotes()
  const PageEditor = useEditorClass('PageEditor')

  const [selectedVersion, setSelectedVersion] = useState<string>()

  const handleClose = useCallback(() => {
    if (latestUnread?.version) {
      setReadVersion(latestUnread?.version)
    }
    setSelectedVersion(undefined)
  }, [latestUnread?.version, setReadVersion])

  const version = selectedVersion || latestUnread?.version
  const handleVersion = useCallback((e: SelectChangeEvent<string>) => setSelectedVersion(e.target.value), [])

  const currentReleaseNote = releaseNotes.find(r => r.version === version)

  const { entity } = useEntity(currentReleaseNote?.entityId)

  if (!entity) {
    return null
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      title="Release Notes"
      open={true}
      onClose={handleClose}
      controls={
        <Select sx={{ mx: 2 }} variant="standard" size="small" value={version} onChange={handleVersion}>
          {releaseNotes.map(r => (
            <MenuItem key={r.version} value={r.version}>
              {r.version}
            </MenuItem>
          ))}
        </Select>
      }
    >
      <PageEditor element={entity} readOnly={true} />
      <Button onClick={handleClose}>Close</Button>
    </Dialog>
  )
}
