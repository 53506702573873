/** Hook to handle the signaling for a room */
import { logger, type WSMessage } from '@tunasong/models'
import { useThunkDispatch, type WSReduxType } from '@tunasong/redux'
import { useCallback, useEffect } from 'react'

/**
  Handle Redux messages received over Websocket
*/
export const useReceiveRedux = (socket: WebSocket | null) => {
  const dispatch = useThunkDispatch()

  /** Handle presence messages from peers */
  const handleRedux = useCallback(
    ({ data: payload }: MessageEvent) => {
      const wsMsg = JSON.parse(payload) as WSMessage<WSReduxType>
      if (wsMsg.action !== 'redux') {
        return
      }
      const msg = wsMsg.data
      logger.debug('Redux message received', msg)
      dispatch(msg)
    },
    [dispatch]
  )

  // Listen to events on the Websocket
  useEffect(() => {
    if (!socket) {
      return
    }
    logger.debug(`*** Redux signaling init`, socket)
    socket.addEventListener('message', handleRedux)

    return () => {
      logger.debug(`*** Redux signaling shutdown`)
      socket.removeEventListener('message', handleRedux)
    }
  }, [handleRedux, socket])
}
