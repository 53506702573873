import { ListItemIcon, MenuItem, Typography, type ListItemProps } from '@mui/material'
import { Tuner } from '@tunasong/icons'
import { Dialog } from '@tunasong/ui-lib'
import { useCallback, useState, type MouseEvent } from 'react'
import { TunerView } from './dsp/index.js'
import { useAudioEngine } from './hooks/index.js'

export interface TunerMenuItemProps extends Omit<ListItemProps, 'button'> {
  onClick?: () => void
}

export const useTunerMenuItem = (props: TunerMenuItemProps = {}) => {
  const { onClick, ...restProps } = props

  const [showTuner, setShowTuner] = useState(false)

  const engine = useAudioEngine()

  const enableMonitor = useCallback(
    (monitor = true) => {
      if (!engine?.defaultInput) {
        return
      }
      engine.defaultInput.enableDSPFeature('tuner', monitor)
      engine.defaultInput.enableDSPFeatures(monitor)
      engine.defaultInput.monitor = monitor
    },
    [engine.defaultInput]
  )

  const handleShowTuner = useCallback(
    (ev: MouseEvent) => {
      ev.preventDefault()
      enableMonitor(true)
      setShowTuner(true)
      if (onClick) {
        onClick()
      }
    },
    [enableMonitor, onClick]
  )

  const handleHideTuner = useCallback(() => {
    enableMonitor(false)
    setShowTuner(false)
  }, [enableMonitor])

  return {
    dialog: (
      <Dialog open={showTuner} onClose={handleHideTuner} title="Tuner" fullWidth={true} maxWidth="xs">
        {engine?.defaultInput ? <TunerView audioController={engine.defaultInput} showInput={true} /> : null}
        {!engine.defaultInput ? <Typography color="error">No input device available</Typography> : null}
      </Dialog>
    ),
    menuItem: (
      <MenuItem title="Tuner" color="inherit" onClick={handleShowTuner} disabled={!engine.defaultInput} {...restProps}>
        <ListItemIcon>
          <Tuner />
        </ListItemIcon>
        Tuner
      </MenuItem>
    ),
  }
}
