import { type AudioPeaks, shortUuid } from '@tunasong/models'
import { isSafari, makeStyles, useContainerSize } from '@tunasong/ui-lib'
import cn from 'classnames'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { generateBars } from '../lib/wave-drawer.js'
import { Box, type BoxProps } from '@mui/material'

const useStyles = makeStyles<{ clipPath: string }>()((theme, { clipPath }) => ({
  root: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    fontSize: 12,
  },
  badge: {
    display: 'block',
    padding: theme.spacing(0, 1, 0, 1),
    position: 'absolute',
    zIndex: 1,
    backgroundColor: theme.vars.palette.primary.light,
    color: theme.vars.palette.primary.contrastText,
  },

  waveformContainer: {
    flex: 1,
  },
  waveformBg: {
    clipPath,
    fill: theme.vars.palette.primary.light,
  },
  waveformProgress: {
    clipPath,
    fill: theme.vars.palette.success.main,
  },
}))

export interface WaveProps extends Omit<BoxProps, 'position'> {
  className?: string
  /** `fixed`: render each peak one time. `dynamic` fill the available box, calculate peak characteristics  */
  mode?: 'fixed' | 'dynamic'
  peaks?: AudioPeaks
  badge?: string | null
  /** Position in the wave, from 0.0 to 1.0 */
  position?: number
  barWidth?: number
}

export const Wave = React.forwardRef((props: WaveProps, rootRef: React.Ref<HTMLDivElement>) => {
  const { className, badge, peaks, barWidth = 5, position = 0, mode = 'dynamic', ...restProps } = props
  const clipId = useMemo(() => `waveform-${shortUuid()}`, [])
  const clipPath = `#${clipId}`
  const clipStyle = useMemo(() => ({ clipPath }), [clipPath])
  const { classes } = useStyles({ clipPath: `url("${clipPath}")` })
  const progressWidth = position * 100

  const localRef = useRef<HTMLElement | null>(null)
  const mergedRef = mergeRefs([localRef, rootRef])

  const maxBuckets = useMemo(
    () => (mode === 'fixed' ? peaks?.length ?? 0 : Math.min(peaks?.length ?? 200, 200)),
    [mode, peaks?.length]
  )

  const spacing = 0.2

  const containerSize = useContainerSize(localRef.current)
  const numBuckets = useMemo(() => {
    if (mode === 'fixed') {
      return peaks?.length ?? 0
    }
    return containerSize?.width ? Math.min(maxBuckets, containerSize.width / (barWidth + spacing)) : 0
  }, [barWidth, containerSize?.width, maxBuckets, mode, peaks?.length])

  const bars = useMemo(() => (peaks ? generateBars({ peaks, numBuckets, spacing }) : []), [numBuckets, peaks])

  /** Hack to enable wave is rendering on Safari */
  const [renderKey, setRenderKey] = useState(shortUuid())
  useEffect(() => {
    if (!isSafari()) {
      return
    }
    setTimeout(() => {
      setRenderKey(shortUuid())
    }, 1000)
  }, [])

  return (
    <Box {...restProps} className={cn(className, classes.root)} ref={mergedRef}>
      {badge ? <div className={classes.badge}>{badge}</div> : null}

      <svg className={classes.waveformContainer} viewBox="0 0 100 100" preserveAspectRatio="none">
        <rect className={classes.waveformBg} style={clipStyle} x="0" y="0" height="100" width="100" />
        <rect className={classes.waveformProgress} style={clipStyle} x={0} y={0} height={100} width={progressWidth} />
      </svg>
      {/* this SVG is the "clipping mask" - the waveform bars */}
      <svg height="0" width="0">
        <defs>
          <clipPath key={renderKey} id={clipId}>
            {bars}
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
})

export default Wave
