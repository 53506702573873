/** Hook to control the video and audio tracks of the local and shared streams */

import { isDev, logger } from '@tunasong/models'
import { useCallback, useRef, useState } from 'react'
import { micDefaults, micHeadset } from '../lib/media-util.js'
import { useAudioEngine } from './audio-engine.js'
import { useMixer } from './mixer.hook.js'

export const useMic = () => {
  const mixer = useMixer()
  const engine = useAudioEngine()

  const [headsetMode, setHeadsetMode] = useState(false)
  const [isMuted, setIsMuted] = useState(isDev())
  const micStream = useRef<MediaStream | null>(null)

  const isActive = Boolean(micStream.current)

  const mute = useCallback(
    (m = true) => {
      if (!mixer) {
        return
      }
      // const channel = mixer.getChannels('mic')
      mixer.muteChannels('mic', m)
      const bus = mixer.getBus('mic')
      bus.muted = m
      setIsMuted(m)
    },
    [mixer]
  )

  const stop = useCallback(() => {
    if (!engine.defaultInput) {
      return
    }
    engine.defaultInput.monitor = false
  }, [engine.defaultInput])

  /** Start the selected device */
  const start = useCallback(async () => {
    if (!engine.defaultInput) {
      return
    }
    engine.defaultInput.monitor = true
  }, [engine.defaultInput])

  const setDevice = useCallback(
    (newDevice: MediaDeviceInfo) => {
      engine.defaultInputDevice = newDevice
    },
    [engine]
  )

  const headset = useCallback((usingHeadset: boolean) => {
    if (!micStream.current) {
      return
    }
    setHeadsetMode(usingHeadset)
    /** If using headset, turn off echo cancellation */
    const constraints = usingHeadset ? micHeadset : micDefaults
    micStream.current.getAudioTracks().forEach(async t => {
      await t.applyConstraints(constraints)
      logger.debug('Applied constraints to audio track', t.getConstraints())
    })
  }, [])

  return {
    /** The local and share streams. Video and audio tracks are managed by this hook */
    ready: Boolean(engine.defaultInputDevice),
    micStream: micStream.current,
    selectedDevice: engine.defaultInputDevice,
    headsetMode,
    isMuted,
    isActive,
    mute,
    headset,
    setDevice,
    start,
    stop,
  }
}

export type MicControl = ReturnType<typeof useMic>
