import { type Theme } from '@mui/material'
import { type AudioPeaks } from '@tunasong/models'
import invariant from 'tiny-invariant'

/** Adapted from https://bloodb0ne.medium.com/drawing-good-looking-waveforms-on-the-web-d6df24ddff7d */

export interface DrawParams {
  canvas: HTMLCanvasElement | null
  theme: Theme
  peaks: AudioPeaks
}

export type ColorStop = [number, string]

const canvasLinearGradient = (canvasCtx: CanvasRenderingContext2D, colorStops: ColorStop[]) => {
  const l = canvasCtx.canvas.height // Gradient Size

  return colorStops.reduce((e, stop) => {
    const sValue = stop[0]
    const color = stop[1]

    return e.addColorStop(sValue, color), e
  }, canvasCtx.createLinearGradient(0, 0, 0, l))
}

const memoizeScale = (max: number, data: number[]) => {
  const m: number[] = []
  return (v: number) => {
    const s = data[v]
    if (!m[s]) {
      m[s] = (max - data[v]) / max
    }
    return m[s]
  }
}

interface DrawWaveform {
  canvas: HTMLCanvasElement
  dataSamples: number[]
  barWidth: number
  maxVal: number
  gapWidth: number
  linePercent: number
  waveGradient: ColorStop[]
  height: number
  width: number
}
export const drawWaveform = ({
  canvas,
  dataSamples,
  waveGradient,
  linePercent,
  barWidth,
  maxVal,
  gapWidth,
}: DrawWaveform) => {
  const c = canvas.getContext('2d')

  /** Scale properly */
  const PIXEL_RATIO = window.devicePixelRatio || 1

  canvas.width = canvas.clientWidth * PIXEL_RATIO
  canvas.height = canvas.clientHeight * PIXEL_RATIO

  let w = 0
  let x = 0
  const width = canvas.width
  const midHeight = linePercent * canvas.height
  const v = canvas.height - midHeight
  const r = dataSamples.length

  invariant(c, 'cannot get context from canvas')

  const scaledSamples = memoizeScale(maxVal, dataSamples)

  const increment = barWidth + gapWidth

  c.clearRect(0, 0, canvas.width, canvas.height)
  c.save()
  c.beginPath()
  for (let posX = 0; posX < width; posX += increment) {
    // eslint-disable-next-line no-bitwise
    const C = scaledSamples(((posX / width) * r) | 0)
    // eslint-disable-next-line no-bitwise
    const posY = (C * midHeight) | 0
    // eslint-disable-next-line no-bitwise
    const S = ((1 - C) * v + midHeight) | 0

    /** Draw the bar */
    c.rect(
      posX, // X
      posY, // Y
      barWidth, // W
      S - posY // H
    )

    // Draw Gap
    const A = Math.max(posY, w)
    c.fillStyle = 'transparent'
    c.fillRect(
      posX - gapWidth, // X
      A, // Y
      gapWidth, // W
      Math.min(S, x) - A // H
    )

    w = posY
    x = S
  }

  c.fillStyle = canvasLinearGradient(c, waveGradient)
  c.fill()
  /** Line in the middle */
  // c.clearRect(0, midHeight, width, 1)
  c.restore()
}
interface Overlay {
  canvas: HTMLCanvasElement
  fill: string | CanvasGradient | CanvasPattern
  from: number
  to: number
}
export const drawOverlay = ({ canvas, fill, from, to }: Overlay) => {
  const c = canvas.getContext('2d')
  invariant(c, 'canvas cannot get context')
  from = canvas.width * from
  to = canvas.width * to
  c.save()
  c.fillStyle = fill
  c.globalCompositeOperation = 'source-atop'
  c.fillRect(from, 0, Math.abs(from - to), canvas.height)
  c.restore()
}
