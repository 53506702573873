import { Box, Button, TextField } from '@mui/material'
import { useRef, type FC } from 'react'

export interface MidiChannelProps {
  defaultValue?: number
  onChange?(value: number | undefined): void
}

export const MidiChannel: FC<MidiChannelProps> = props => {
  const { defaultValue } = props
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev.preventDefault()
    const num = Number(ev.target.value)
    props.onChange?.(num)
  }
  const inputRef = useRef<HTMLInputElement>(null)
  const handleOmni = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    props.onChange?.(0)
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const omni = defaultValue === 0 || defaultValue === undefined

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        defaultValue={defaultValue && defaultValue > 0 ? defaultValue : undefined}
        label="MIDI Channel"
        placeholder="1-16"
        type="number"
        variant="outlined"
        size="small"
        sx={{ width: 150 }}
        inputRef={inputRef}
        inputProps={{
          min: 1,
          max: 16,
          placeholder: 'Omni',
        }}
        onChange={handleChange}
      />
      <Button disabled={omni} sx={{ ml: 1 }} variant="outlined" size="medium" onClick={handleOmni}>
        Omni
      </Button>
    </Box>
  )
}
