import React, { type FC, useEffect } from 'react'
import { AudioEngineContext } from './audio-engine-context.js'
import { AudioEngine } from './engine.js'
import { logger } from '@tunasong/models'

interface AudioContainerProps {
  engine: AudioEngine | null
  children?: React.ReactNode
}

export const AudioContainer: FC<AudioContainerProps> = ({ children, engine }) => {
  /** Handle start/resume of audio context */
  useEffect(() => {
    if (!engine || engine?.started) {
      return
    }

    const start = async (ev: Event) => {
      if (engine.started) {
        return
      }
      await engine.start()
      logger.debug(`*** AudioEngine started from event ${ev.type} ***`)
    }

    document.addEventListener('click', start)
    document.addEventListener('touch', start)
    return () => {
      document.removeEventListener('click', start)
      document.removeEventListener('touch', start)
    }
  }, [engine])

  return <AudioEngineContext.Provider value={engine}>{children}</AudioEngineContext.Provider>
}

export default AudioContainer
