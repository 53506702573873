export interface PercentageDB {
  value: number
  /** The reference level in dB. @default 6 */
  reference?: number
}

export const getDBPercentage = ({ value, reference = 6 }: { value: number; reference: number }) => {
  if (value <= -120 || !Number.isFinite(value)) {
    return 0
  }

  const val = value - reference

  const k = Math.pow(10, val / 20)

  return k * 100
}

/**
 * This scale is from -120 to +6dB. DB is logarithmic. Default reference level is 6 (dB)
 * @see https://www.animations.physics.unsw.edu.au/jw/dB.htm
 */

export const getPercentageDB = ({ value, reference = 6 }: PercentageDB) => {
  const x = (value / 100) * reference

  const dB = 20 * Math.log(x / reference) + reference
  return normalizeDB(dB)
}

export const normalizeDB = (dB: number) => {
  if (!Number.isFinite(dB)) {
    return -120
  }
  return dB
}
