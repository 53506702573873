/** We need to completely reload an editor when we restore an earlier version */

import { type Entity } from '@tunasong/schemas'
import type { Persisted } from '@tunasong/schemas'
import { useMemo, useRef } from 'react'

type EntityProps = Partial<Pick<Persisted<Entity>, 'id' | 'updatedAt'>>

export const getEntityKey = (entity: EntityProps) => `${entity.id}-${entity.updatedAt}`

export const getEditorKey = (previousEntity: EntityProps | undefined, next: EntityProps | undefined) => {
  if (!next) {
    return undefined
  }
  const lastUpdatedAt = previousEntity?.updatedAt
  const lastId = previousEntity?.id
  /** If updatedAt is LESS than the previousEntity updatedAt, we'll update they key */
  if (!next.updatedAt) {
    return next.id
  }
  if (!previousEntity) {
    return getEntityKey(next)
  }
  if (next.id !== lastId) {
    return getEntityKey(next)
  }
  if (lastUpdatedAt && lastUpdatedAt > next.updatedAt) {
    return getEntityKey(next)
  }

  return getEntityKey(previousEntity)
}

/** Provide a key to the editor that ensures it is refreshed when the entity have been restored to an older version */
export const useEntityEditorKey = (entity?: EntityProps) => {
  const previousEntity = useRef<EntityProps>()
  const previousKey = useRef<string>()

  const key = useMemo(() => {
    const key = getEditorKey(previousEntity.current, entity)
    if (key !== previousKey.current) {
      previousEntity.current = entity
      previousKey.current = key
    }
    return key
  }, [entity])
  return key
}
