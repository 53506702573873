import { dayjs } from '@tunasong/models'
import { type Clip, type AudioClip as IAudioClip, isAudioClip, isEntityClip } from '@tunasong/schemas'
import { type Recorder } from '../../recorder/index.js'
import AudioClip from './audio-clip.js'
import { type ClipProps } from './clip-container.js'
import EntityClip from './entity-clip.js'

export const getClipComponent = (clip: Clip) => {
  const Component = isAudioClip(clip)
    ? AudioClip
    : isEntityClip(clip)
      ? EntityClip
      : (props: ClipProps) => <>No clip component found for {props.clip.type}</>

  return Component
}

export const createClip = (recorder: Recorder, start: number, duration: number) => {
  const rec = recorder.getRecording()
  if (!rec) {
    return null
  }
  const url = URL.createObjectURL(rec)
  const clip: IAudioClip = {
    name: dayjs().format('LL'),
    start,
    duration,
    type: 'audioclip',
    url,
  }
  return clip
}
