import { type MouseEvent } from 'react'

/** Return the click position from click */
export const getClickPositionSeconds = (event: MouseEvent, duration: number) => {
  const rect = event.currentTarget.getBoundingClientRect()
  const pixelsPerSecond = rect.width / duration
  const pos = event.clientX - rect.left
  const clickSec = pos / pixelsPerSecond
  return clickSec
}
