/** Get context in a cross-browser compatible way */

import { logger } from '@tunasong/models'
import { AudioContext, type IAudioContextOptions } from 'standardized-audio-context'

const defaultOptions: IAudioContextOptions = {
  // sampleRate: defaultSampleRate,
  /**
   * 'playback': 150ms
   * 'interactive': 0ms
   * 'balanced': ~22ms
   * Can also set a double here for the actual latency
   */
  /** @note 'interactive' cause https://github.com/thovden/tunasong/issues/38 */
  latencyHint: 'balanced',
  sampleRate: 44100,
}

export const createAudioContext = (options?: Partial<IAudioContextOptions>) => {
  const allOptions = {
    ...defaultOptions,
    ...options,
  }
  const context = new AudioContext(allOptions)
  logger.debug('Created audio context', context.baseLatency, context.sampleRate, context.currentTime)
  return context
}
