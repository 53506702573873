/* eslint-disable no-bitwise */
import { Enumerations, Message } from 'webmidi'
import { BtMidiOutputChannelAdapter } from './output-channel-adapter.js'
import invariant from 'tiny-invariant'
import { logger } from '@tunasong/models'
import { Mutex } from 'async-mutex'

// Bluetooth MIDI Output Adapter
export class BtMidiOutputAdapter {
  channels: BtMidiOutputChannelAdapter[] = []

  // we need to lock the sending of messages so there's at most one message in flight at a time
  mutex: Mutex

  constructor(
    private device: BluetoothDevice,
    private characteristic: BluetoothRemoteGATTCharacteristic
  ) {
    this.mutex = new Mutex()
    // @note this is 1-indexed so you can get channel by number
    for (const channel of Enumerations.MIDI_CHANNEL_NUMBERS) {
      this.channels[channel] = new BtMidiOutputChannelAdapter(this, channel, characteristic)
    }
  }

  get deviceId() {
    return this.device.id
  }
  get name() {
    return this.device.name || 'Unnamed MIDI Device'
  }

  destroy() {
    this.channels.forEach(channel => channel.destroy())
  }

  send = async (
    midiMessage: number[] | Uint8Array | Message,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options?: {
      time?: number | string
    }
  ) => {
    invariant(midiMessage, 'No MIDI message to send')

    // convert midiMessage to Uint8Array
    let bytes: number[]
    if (midiMessage instanceof Message) {
      bytes = midiMessage.dataBytes
    } else if (midiMessage instanceof Uint8Array) {
      bytes = Array.from(midiMessage)
    } else {
      bytes = midiMessage
    }

    const packet = new Uint8Array(this.midiEncoder(bytes))

    // Send one message at a time
    const release = await this.mutex.acquire()
    try {
      await this.characteristic.writeValue(packet)
    } catch (e) {
      logger.error('BLE: Error sending packet', packet, e)
    } finally {
      release()
    }

    return this
  }

  async sendProgramChange(
    program?: number | undefined,
    options?: { channels?: number | number[] | undefined; time?: string | number | undefined } | undefined
  ) {
    if (program === undefined) {
      return this
    }
    const channels = this.getChannels(options)

    logger.debug('Sending Program Change: ', program, channels)

    // Send to all the channels
    for (const channel of channels) {
      const msg = [(Enumerations.MIDI_CHANNEL_MESSAGES.programchange << 4) + channel - 1, program]
      await this.send(msg)
    }

    return this
  }

  async sendControlChange(control: number, value: number, options?: { channels?: number | number[] }) {
    const channels = this.getChannels(options)

    // Send to all the channels
    logger.debug('Sending Control Change: ', control, value, channels)
    for (const channel of channels) {
      const msg = [(Enumerations.MIDI_CHANNEL_MESSAGES.controlchange << 4) + channel - 1, control, value]
      await this.send(msg)
    }

    return this
  }

  private getChannels(options?: { channels?: number | number[] }): number[] {
    if (options?.channels) {
      return Array.isArray(options.channels) ? options.channels : [options.channels]
    }
    return Enumerations.MIDI_CHANNEL_NUMBERS
  }

  private timestampGenerator() {
    const localTime = performance.now() & 8191
    const timestamp = [((localTime >> 7) | 0x80) & 0xbf, (localTime & 0x7f) | 0x80]
    return timestamp
  }

  private midiEncoder(midiData: number[]) {
    let midiBLEmessage = []
    let pos = 0
    let len = midiData.length

    midiBLEmessage.push(this.timestampGenerator()[0])

    for (pos = 0; pos < len; pos++) {
      if (midiData[pos] >>> 7 === 1) {
        midiBLEmessage.push(this.timestampGenerator()[1])
      }
      midiBLEmessage.push(midiData[pos])
    }

    return midiBLEmessage
  }
}
