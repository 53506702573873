/* eslint-disable tss-unused-classes/unused-classes */
import { Box, type PaperProps } from '@mui/material'
import type { FilterFunc, SearchSummary } from '@tunasong/models'
import { usePlugins } from '@tunasong/plugin-lib'
import { type Entity, type Persisted, type SearchFilter } from '@tunasong/schemas'
import React, { useCallback, useState } from 'react'
import { SearchInput } from './search-input.js'
import { SearchResults } from './search-results.js'

import { type Theme } from '@mui/material'
import { makeStyles } from '@tunasong/ui-lib'

const useStyles = makeStyles()((theme: Theme) => ({
  inputRoot: {
    color: 'inherit',
    backgroundColor: theme.palette.action.focus,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 4),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  searchIcon: {
    width: theme.spacing(4),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.action.focus,
  },
}))

export interface SearchBarProps<T extends SearchSummary | Persisted<Entity>> extends Pick<PaperProps, 'sx'> {
  className?: string
  placeholder?: string
  loading?: boolean
  entities: T[]
  similar?: T[]
  fullWidth?: boolean
  selected?: T
  query: string

  /** Filter in query. These are AND'ed  */
  filter?: SearchFilter

  /** @todo this filters after the result is returned */
  resultFilter?: FilterFunc

  /** If specified, show a filter button  */
  onChangeFilter?(filter: SearchFilter): void

  renderHotkeys?(entity: T, active: boolean): React.ReactElement | null
  /** Query has changed */
  onQuery(query: string): void
  onChange(entity: T): void
  onKeyDown?(ev: React.KeyboardEvent): void
  onKeyUp?(ev: React.KeyboardEvent): void
}

export function SearchBar<T extends SearchSummary>(props: SearchBarProps<T>) {
  const {
    query,
    loading = false,
    placeholder = 'Search...',
    fullWidth = true,
    entities,
    similar,
    selected,
    filter,
    renderHotkeys,
    onChangeFilter,
    onChange,
    onQuery,
    ...boxProps
  } = props
  const { classes } = useStyles()
  const plugins = usePlugins('all')

  const handleQuery = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const q = ev.target.value
      onQuery(q)
    },
    [onQuery]
  )

  const [inputRef, setInputRef] = useState<HTMLElement | null>(null)

  if (!plugins) {
    return null
  }

  const selectedIndex = selected ? entities.findIndex(e => e.id === selected.id) : undefined

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
        ...boxProps?.sx,
      }}
      {...boxProps}
    >
      <SearchInput
        classes={classes}
        inputRef={setInputRef}
        onChange={handleQuery}
        onChangeFilter={onChangeFilter}
        query={query}
        placeholder={placeholder}
        filter={filter}
        loading={loading}
        autoFocus={true}
        fullWidth={fullWidth}
        {...boxProps}
      />

      <SearchResults
        sx={{ overflow: 'auto' }}
        inputRef={inputRef}
        entities={entities}
        selectedIndex={selectedIndex}
        similar={similar}
        renderHotkeys={renderHotkeys}
        onChange={onChange}
      />
    </Box>
  )
}
