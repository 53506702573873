import { renderContent, usePlugin, usePlugins, type LayoutSpec, type TunaEditor } from '@tunasong/plugin-lib'
import type { Entity, EntityOrElement, Persisted } from '@tunasong/schemas'
import { EntityContent } from '@tunasong/ui-lib'
import { type BaseEditor } from 'slate'

interface EntityLayoutProps<T extends Persisted<Entity>> {
  entity: T
  layout: LayoutSpec<T> | null
  onEditor(editor: TunaEditor): void
}

/** Show the entity content view */
export const EntityLayout = <T extends Persisted<Entity>>(props: EntityLayoutProps<T>) => {
  const { entity, layout, onEditor, ...restProps } = props

  const plugin = usePlugin(entity)

  const plugins = usePlugins('all') ?? []
  const renderContentWithPlugins = (element: EntityOrElement) => renderContent({ element, plugins })

  const handleEditor = (editor: BaseEditor) => {
    onEditor(editor as TunaEditor)
  }

  return layout?.Component ? (
    <layout.Component
      {...restProps}
      entity={entity}
      schema={plugin?.schema}
      layout={layout}
      renderContent={renderContentWithPlugins}
      onEditor={handleEditor}
    />
  ) : (
    <EntityContent entity={entity} />
  )
}
export default EntityLayout
