import { type AudioEvent } from '@tunasong/schemas'
import { useEffect, useState } from 'react'
import type { Transport } from 'tone'
import { useAudioEngine } from './audio-engine.js'

/** Hooks to manage the state of the transport.
 * @see https://github.com/Tonejs/Tone.js/wiki/TransportTime
 */

export interface TransportProps {
  events?: AudioEvent[]
  /** Called when an event region is entered */
  onEnterEvent?(event: AudioEvent): void
  /** Called when an event region is left */
  onLeaveEvent?(event: AudioEvent): void
}
export interface TransportType {
  transport: typeof Transport
  activeEvent?: AudioEvent
}

export const useTransport = (props: TransportProps = {}): TransportType => {
  const { onLeaveEvent = () => {}, onEnterEvent = () => {}, events } = props
  const engine = useAudioEngine()
  const { transport } = engine

  const [activeEvent, setActiveEvent] = useState<AudioEvent>()
  useEffect(() => {
    if (!(transport && events)) {
      return
    }
    /** Schedule the events on the timeline */
    const eventIds = events
      .map(event => [
        transport.scheduleOnce(() => {
          onEnterEvent(event)
          setActiveEvent(event)
        }, event.start),
        event.end
          ? transport.scheduleOnce(() => {
              onLeaveEvent(event)
            }, event.end)
          : null,
      ])
      .flat()
      .filter(Boolean) as number[]
    return () => {
      /** Clear all the scheduled callbacks on unload */
      eventIds.forEach(id => transport.clear(id))
    }
  }, [events, onEnterEvent, onLeaveEvent, transport])

  return {
    activeEvent,
    transport,
  }
}
