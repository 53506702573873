import { useEffect } from 'react'
import { useMic } from './mic.js'

/** Ensure the Mic is started when this hook is in the scope. Disconnect the mic on exit. */
export const useStartMic = () => {
  const { start, stop, selectedDevice } = useMic()
  useEffect(() => {
    start()
    return () => {
      stop()
    }
  }, [start, stop])

  return { selectedDevice }
}
