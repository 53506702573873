import { Typography, type BoxProps } from '@mui/material'
import { type FeedItem } from '@tunasong/models'
import { VBox, useScroll } from '@tunasong/ui-lib'
import mergeRefs from 'merge-refs'
import { useEffect, useMemo, useState, type FC } from 'react'
import ChatFeedItem from './chat-feed-item.js'

export interface ChatFeedProps extends BoxProps {
  className?: string
  title?: string
  items: FeedItem[]
  maxItems?: number
  /** The first/top element. Set if you stable scroll behavior for dynamic lists */
  topRef?: React.RefObject<HTMLDivElement> | React.RefCallback<HTMLDivElement>
}

export const ChatFeed: FC<ChatFeedProps> = props => {
  const { className, topRef, title, maxItems = Number.MAX_SAFE_INTEGER, items = [], ...restProps } = props

  const showItems = useMemo(() => items.slice(0, maxItems), [items, maxItems])

  const { highlightId } = useScroll(items)
  const [lastRef, setLastRef] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!lastRef) {
      return
    }
    /** Since the editors take some time to render, we hack this */
    setTimeout(() => {
      lastRef.scrollIntoView({ block: 'end' })
    }, 100)
  }, [lastRef])

  return (
    <VBox {...restProps} sx={{ my: 1, ...restProps.sx }} className={className}>
      {title ? (
        <Typography sx={{ ml: 4 }} variant="subtitle1">
          {title}
        </Typography>
      ) : null}

      {showItems.map((item, idx) => (
        <ChatFeedItem
          key={item.id}
          ref={mergeRefs<HTMLDivElement>(
            idx === 0 ? topRef : undefined,
            // item.id === activeId ? focusRef : undefined,
            idx === showItems.length - 1 ? setLastRef : undefined
          )}
          item={item}
          highlight={highlightId === item.id}
        />
      ))}
    </VBox>
  )
}

export default ChatFeed
