import { type EditorContextProps, ElementEditor, useCreateEditor } from '@tunasong/editor'
import { isCollabEntity } from '@tunasong/models'
import { useYDoc } from '@tunasong/plugin-lib'
import { isCoreElement, isPersisted } from '@tunasong/schemas'
import { type FC } from 'react'

/** Will read the YJS document attribute and render it as initial value children for the editor */
export const YJSEditor: FC<EditorContextProps> = ({ element: entity, plugins, ...restProps }) => {
  if (!(isPersisted(entity) && isCollabEntity(entity))) {
    throw new Error(`Entity ${entity.type} ${entity.id} must be a collaborative entity`)
  }
  const editor = useCreateEditor(entity, plugins ?? null)
  const { element } = useYDoc(entity)

  /** Don't render before we have a value for children */
  if (!(isCoreElement(element) && editor)) {
    return null
  }

  return <ElementEditor {...restProps} editor={editor} element={element} initialValue={element.children} />
}

export default YJSEditor
