import type { SearchRequest } from '@tunasong/schemas'

/** Retrieve the embedding for a specified entity Id */
const query = (embedding: number[], size = 10): SearchRequest => ({
  index: 'entities',
  /**
   * size and k must be set to the same
   * @see https://docs.aws.amazon.com/opensearch-service/latest/developerguide/knn.html
   */
  size,
  body: {
    query: {
      knn: {
        embedding: {
          vector: embedding,
          k: size,
        },
      },
    },
  },
})

export default query
