import { capitalize, Typography } from '@mui/material'
import { useEditor, useEditorMessage } from '@tunasong/plugin-lib'
import { type CoreElement } from '@tunasong/schemas'
import { makeStyles, PulseProgress, useCmdHotkey } from '@tunasong/ui-lib'
import cn from 'classnames'
import React, { type FC, useCallback } from 'react'

export interface EmptyBlockProps {
  className?: string
  element: CoreElement
  name: string
  Icon: FC
  loading?: boolean
}

const useStyles = makeStyles()(theme => ({
  root: {
    color: theme.vars.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export const EmptyBlock: FC<EmptyBlockProps> = props => {
  const { className, name, Icon, element, loading = false } = props
  const { classes } = useStyles()
  const editor = useEditor()
  const propertiesCmd = useCmdHotkey('Block: Properties')
  const showProperties = useEditorMessage({ editor, type: 'show-block-properties' })

  const handleClick: React.MouseEventHandler<HTMLSpanElement> = useCallback(
    ev => {
      showProperties(element, ev.currentTarget)
    },
    [element, showProperties]
  )

  return (
    <Typography className={cn(className, classes.root)} contentEditable={false} variant="caption" onClick={handleClick}>
      <Icon />
      {loading ? (
        <PulseProgress />
      ) : (
        <>
          {capitalize(name)} block is not configured.
          {propertiesCmd} or click on this block to configure.
        </>
      )}
    </Typography>
  )
}
