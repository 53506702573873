import { logger } from '@tunasong/models'
import { AudioWorkletNode, type IAudioContext, type IAudioWorkletNode } from 'standardized-audio-context'
import type { IAudioController, IMixer } from '../engine/interfaces.js'
import { encodeWav, flatBuffers } from '../wav-util.js'
import { type Recorder, type RecordingState } from './recorder.js'
import workletUrl from './worklets/recorder.worklet.js?url&worker'

export interface WavRecorderOptions {}

/** @note this is duplicated here to avoid making recorder.worklet a module - @see https://github.com/thovden/tunasong/issues/52 */
interface AudioDataMessage {
  eventType: 'data'
  channels: Float32Array[]
}

const defaultOptions: WavRecorderOptions = {}

export class WavRecorder implements Recorder {
  static workletPromise: Promise<void> | null = null

  source: IAudioController
  // the WavRecorder has been destroyed and cannot be used
  destroyed = false
  options: WavRecorderOptions
  state: RecordingState = 'inactive'
  recorderNode: IAudioWorkletNode<IAudioContext> | null = null
  buffers: Float32Array[][] = []

  startTime?: Date | null | undefined
  startContextTime?: number
  startTransportTime?: number
  endContextTime?: number

  constructor(
    private mixer: IMixer,
    source?: IAudioController,
    options?: WavRecorderOptions
  ) {
    this.options = { ...defaultOptions, ...options }
    const audioWorklet = this.mixer.context.audioWorklet
    if (!audioWorklet) {
      throw new Error(`AudioContext does not support audio worklets`)
    }

    this.source = source ?? this.mixer.getBus('record')

    /** We need to add this once in our app, so we use a static promise here */
    if (WavRecorder.workletPromise === null) {
      WavRecorder.workletPromise = audioWorklet.addModule(workletUrl)
    }
    WavRecorder.workletPromise.then(() => {
      if (typeof AudioWorkletNode === 'undefined') {
        throw new Error(`Recorder requires AudioWorkletNode to work`)
      }

      if (this.recorderNode) {
        throw new Error('Recorder is already connected to a recorder Node')
      }

      if (this.destroyed) {
        // In this case we'll just silently ignore it since the Worklet promise may complete after destroy
        // logger.debug('Audio Recorder worklet activated after destroy - ignoring')
        return
      }

      /** Connect the recorder node */
      this.recorderNode = new AudioWorkletNode(this.mixer.context, 'recorder-worklet')
      this.recorderNode.port.onmessage = this.handleRecorderMessage

      this.source.connect(this.recorderNode)

      logger.debug(
        `Started recorder for ${this.source.name} and connected to ${this.recorderNode.numberOfInputs} recorder inputs`
      )
    })
  }

  get rawBuffers(): Float32Array[] {
    const buffers: Float32Array[] = []
    for (const [idx, channelBuffer] of this.buffers.entries()) {
      buffers[idx] = flatBuffers(channelBuffer)
    }
    return buffers
  }

  disconnect() {
    if (this.recorderNode) {
      this.source.output.disconnect(this.recorderNode)
      this.recorderNode = null
      logger.debug(`Disconnected recorder for ${this.source.name}`)
    }
  }

  destroy() {
    this.buffers = []
    this.disconnect()
    this.destroyed = true
  }

  handleRecorderMessage = (e: { data: AudioDataMessage }) => {
    if (e.data.eventType === 'data') {
      const { channels } = e.data
      for (const [idx, channel] of channels.entries()) {
        const b = (this.buffers[idx] = this.buffers[idx] ?? [])
        b.push(channel)
      }
    }
  }

  /**
   *
   * @param contextTime the audio context time to start the recording.
   */
  start(contextTime?: number, transportTime?: number) {
    if (!this.recorderNode) {
      throw new Error(`Recorder Worklet is not ready`)
    }

    if (this.recorderNode.channelCount === 0) {
      /** No input channels */
      throw new Error(`Recorder node has no channels for source ${this.source?.name}. Cannot record thin air.`)
    }

    this.buffers = []

    this.startTime = new Date()
    this.startContextTime = contextTime
    this.startTransportTime = transportTime
    this.endContextTime = undefined

    const currentTime = this.mixer.context.currentTime
    if (contextTime && contextTime < currentTime) {
      contextTime = currentTime
    }
    const time = contextTime ? contextTime : currentTime

    this.recorderNode.parameters.get('isRecording')?.setValueAtTime(1, time)

    this.state = 'recording'
  }
  /** Stop the recording at contextTime. If not specified, stop immediately. */
  stop(contextTime?: number) {
    if (!this.recorderNode) {
      throw new Error(`Recorder Worklet does not have a recorder node`)
    }
    this.startTime = null
    this.endContextTime = contextTime

    this.state = 'inactive'
    const time = contextTime ? contextTime : this.mixer.context.currentTime
    this.recorderNode.parameters.get('isRecording')?.setValueAtTime(0, time)
  }

  loop() {
    throw new Error(`Not implemented`)
  }

  getRecording = (): Blob => {
    const { sampleRate } = this.mixer.context

    return encodeWav(sampleRate, this.rawBuffers)
  }
}
