/** The audio engine. */

import { type Rhythm, logger, shortUuid } from '@tunasong/models'
import { type NoteEvent } from '@tunasong/music-lib'
import { isSafari } from '@tunasong/ui-lib'
import type { IAudioContext } from 'standardized-audio-context'
import invariant from 'tiny-invariant'
import { Ticks, Context as ToneContext, getContext, getDraw, getTransport, setContext, start } from 'tone'
import { getDevices } from '../devices.js'
import { type MidiNoteEvent } from '../events/midi-event.js'
import { noteEventToMidi } from '../events/note-event-to-midi.js'
import type { AudioInstrument } from '../instruments/index.js'
import { ChannelController } from './channel.js'
import { createAudioContext } from './context.js'
import { MidiControl } from '../midi/midi.js'
import { Mixer } from './mixer.js'
import { InputSource } from './sources/input-source.js'
import type { UserConfigService } from '@tunasong/redux'

const DEFAULT_INPUT_STORAGE_KEY = 'tunasong.audio.mic'

declare global {
  var TONE_SILENCE_LOGGING: boolean
}

/** The AudioEngine is a thin layer on top of Tone.JS  for now */
interface IAudioEngine {
  /** The number of seconds between each update interval. This will determine the latency between an event scheduled and. @default 0.1 */
  clockUpdateInterval?: number
  configService: UserConfigService
}

export class AudioEngine {
  public mixer: Mixer
  public midi: MidiControl | null
  private audioContext: IAudioContext
  private instruments: Record<string, AudioInstrument | undefined> = {}

  private inputSources: InputSource[] = []
  private inputDefault: InputSource | null = null
  private inputDefaultController: ChannelController | null = null
  private currentDefaultInputDevice: MediaDeviceInfo | null = null

  constructor({ clockUpdateInterval, configService }: IAudioEngine) {
    /** Remove existing ToneJS context to avoid having clocks eating CPU - @see https://github.com/thovden/tunasong/issues/105 */
    logger.debug('Creating new audio engine', shortUuid())
    const oldContext = getContext()
    oldContext.dispose()
    invariant(oldContext.disposed === true, 'Old context not disposed')

    const lsDevice = localStorage.getItem(DEFAULT_INPUT_STORAGE_KEY)
    this.currentDefaultInputDevice = lsDevice ? (JSON.parse(lsDevice) as MediaDeviceInfo) : null

    this.audioContext = createAudioContext()
    this.mixer = new Mixer(this.audioContext)

    /** MIDI support */
    this.midi = isSafari() ? null : new MidiControl({ configService })

    const toneContext = new ToneContext({
      context: this.audioContext as never,
      clockSource: 'worker',
      updateInterval: clockUpdateInterval,
    })
    setContext(toneContext)

    // Set up devices when the audio context is running
    this.audioContext.addEventListener('statechange', () => {
      if (this.audioContext.state === 'running') {
        this.setupDevices()
      }
    })

    /** Device changes */
    /** @todo do we need to remove this on destroy? */
    navigator.mediaDevices.addEventListener('devicechange', this.setupDevices)
  }

  get transport() {
    return getTransport()
  }

  get draw() {
    return getDraw()
  }

  get started() {
    return this.audioContext?.state === 'running'
  }

  get defaultInput() {
    return this.inputDefaultController
  }

  get availableInputSources() {
    return this.inputSources
  }

  get bpm() {
    return this.transport.bpm.value
  }

  /** Set bpm at the current position. */
  set bpm(bpm: number) {
    this.transport.bpm.value = bpm
  }

  /** Set Rhythm */
  set rhythm(rhythm: Rhythm) {
    const { tempo, meter } = rhythm
    if (tempo) {
      this.transport.bpm.value = tempo
    }
    if (meter) {
      this.transport.timeSignature = [meter.beatsPerBar, meter.notesPerBar]
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get defaultInputDevice() {
    return this.currentDefaultInputDevice
  }

  set defaultInputDevice(inputDevice: MediaDeviceInfo | null) {
    localStorage.setItem(DEFAULT_INPUT_STORAGE_KEY, JSON.stringify(inputDevice))
    this.currentDefaultInputDevice = inputDevice
    this.setupDevices()
  }

  /** Simple default routing */
  routeDefaultInput = (source: InputSource) => {
    /** We create an input channel for the default input, and route it to the recording bus */
    if (!source) {
      return
    }
    if (this.inputDefaultController) {
      this.inputDefaultController.stop()
      this.inputDefaultController.disconnect()
      this.mixer.removeChannel(this.inputDefaultController.id)
    }
    this.inputDefaultController = this.mixer.addChannel(source, 'record')
  }

  start = async () => {
    if (this.audioContext?.state === 'running') {
      return
    }

    await start()
  }

  stop = async () => {
    Object.keys(this.instruments).forEach(i => this.mixer?.removeChannel(i))
    this.transport.stop()
    this.audioContext.suspend()
  }

  play(notes: NoteEvent[], instrumentName?: string) {
    const instrumentId = instrumentName || Object.keys(this.instruments)[0]
    if (!instrumentId) {
      logger.warn('No instrument found - cannot play')
      return
    }
    const instrument = this.instruments[instrumentId]
    if (!instrument) {
      throw new Error(`Unknown instrument ID: ${instrumentId}`)
    }

    /** @todo is it OK to call this every time? */
    this.transport.start()

    /** Schedule the noteon and noteoff events */
    const start = '+0'

    for (const noteEvent of notes) {
      const end = `+${noteEvent.duration}`
      const events = noteEventToMidi(noteEvent)

      this.transport.scheduleOnce(() => {
        instrument.onEvent(events[0])
      }, start)
      this.transport.scheduleOnce(() => {
        instrument.onEvent(events[1])
      }, end)
    }
  }

  playMidiEvent(event: MidiNoteEvent, instrumentName?: string) {
    /** @todo cleanup */
    const instrumentId = instrumentName || Object.keys(this.instruments)[0]
    if (!instrumentId) {
      logger.warn('No instrument found - cannot play')
      return
    }
    const instrument = this.instruments[instrumentId]
    if (!instrument) {
      throw new Error(`Unknown instrument ID: ${instrumentId}`)
    }

    /** @todo is it OK to call this every time? */
    this.transport.start()

    /** Schedule the noteon and noteoff events */
    const start = '+0'

    this.transport.scheduleOnce(() => {
      instrument.onEvent(event)
    }, start)
  }

  /** Play a sequence of event, where each event is played for `duration`.  */
  playSequence(noteSequence: NoteEvent[], instrumentName?: string) {
    const instrumentId = instrumentName || Object.keys(this.instruments)[0]
    if (!instrumentId) {
      throw new Error('No instrument found')
    }
    const instrument = this.instruments[instrumentId]
    if (!instrument) {
      throw new Error(`Unknown instrument ID: ${instrumentId}`)
    }

    if (this.transport.state !== 'started') {
      this.transport.start()
    }

    /** Schedule the noteon and noteoff events */
    let ticks = 0

    for (const currentNoteEvent of noteSequence) {
      const eventTicks = Ticks(currentNoteEvent.duration).toTicks()
      const start = `+${ticks}i`
      const endTicks = ticks + eventTicks
      const end = `+${endTicks}i`
      ticks += eventTicks

      const events = noteEventToMidi(currentNoteEvent)
      this.transport.scheduleOnce(() => instrument.onEvent(events[0]), start)
      this.transport.scheduleOnce(() => instrument.onEvent(events[1]), end)
    }
  }

  /** @todo Event routing */
  /** @todo Channel routing */

  /** @todo route instruments to the correct track. Currently we create a new track for each instrument */
  addInstrument(name: string, instrument: AudioInstrument) {
    invariant(this.audioContext, 'AudioContext not initialized')
    invariant(this.mixer, 'Mixer not initialized')

    const id = shortUuid()
    this.instruments[id] = instrument
    const source = this.audioContext.createGain()
    /** @todo ToneJS requires InputNode which for some reason is not compatible with IAudioNode */
    instrument.connect(source as never)
    this.mixer.addChannel(
      {
        id,
        name,
        inputNode: source,
        type: 'synth',
      },
      'instrument'
    )
    return id
  }

  removeInstrument(id: string) {
    invariant(this.mixer, 'Mixer not initialized')

    this.mixer.removeChannel(id)
    const instrument = this.instruments[id]
    if (!instrument) {
      throw new Error(`Unknown instrument ID: ${id}`)
    }
    instrument.disconnect()
    delete this.instruments[id]
  }

  private setupDevices = () => {
    /** Get the input devices and set the active selected device */
    getDevices().then(devices => {
      if (this.audioContext.state !== 'running') {
        logger.warn('Cannot change input devices while audio context is running')
        return
      }

      if (devices.length === 0) {
        logger.warn('No audio devices found')
        return
      }

      const defaultDeviceId =
        this.defaultInputDevice && devices.find(d => d.deviceId === this.defaultInputDevice?.deviceId)
          ? this.defaultInputDevice.deviceId
          : devices[0].deviceId

      this.inputSources = devices.map(d => new InputSource(this.audioContext, d))
      this.inputDefault = this.inputSources.find(d => d.id === defaultDeviceId) ?? this.inputSources[0]

      logger.debug('Audio Devices set up', this.inputSources, this.inputDefault)

      this.routeDefaultInput(this.inputDefault)
    })
  }
}
