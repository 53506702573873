import { type CoreElement } from '@tunasong/schemas'

import { type RenderElementPropsExt, useEditor, useRenderContent } from '@tunasong/plugin-lib'
import { type FC } from 'react'

import { Block, type BlockProps } from './block.js'

export interface RenderContentProps extends RenderElementPropsExt {
  element: CoreElement
}

/** Render Content Block uses plugins `renderContent` to render the content */
export const RenderContentBlock: FC<BlockProps> = props => {
  const { element, attributes, children, ...restProps } = props

  const editor = useEditor()
  const contentView = element?.options?.activeView ?? 'default'

  const { render } = useRenderContent({ plugins: editor.plugins, contentView })
  return (
    <Block element={element} attributes={attributes} focus={true} border={true} menu={true} {...restProps}>
      <div contentEditable={false}>{render(element)}</div>
      {children}
    </Block>
  )
}
