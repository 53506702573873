/* eslint-disable */
import { Weblature } from './weblature.js'

export type Tuning = number[]
export type Fingering = number[]
export class Instrument extends Weblature {
  capo = 0
  stringCount = 0
  tuning: Tuning = []
  relativeTuning?: number[]

  constructor(tuning: number[]) {
    super()
    this.setTuning(tuning)
  }

  /* Set the tuning (and implicitly the number of strings) for the
       instrument.

       tuning - an array of semitones. The array entries should be
                ordered with the string with the heaviest gauge as
                the first array element. The elements of the array
                should be created using the Weblature.note method. */

  setTuning(tuning: Tuning) {
    this.stringCount = tuning.length
    this.tuning = tuning
  }

  /* Adjust the tuning with a relative number of semitones up or
                 down for each string. Negative numbers to tune down.
          
      relativeTuning - an array with an integer entry for each string
                      giving the number of semitones to tune the string up
                      or down. */

  setRelativeTuning(relativeTuning: Tuning) {
    if (this.tuning.length != relativeTuning.length) {
      throw new Object()
    } /* Weblature.StringCountException;*/
    this.relativeTuning = relativeTuning
  }

  /* Return the number of strings that this instrument has. */

  getStringCount() {
    return this.stringCount
  }

  /**/

  printTuning() {
    let str = ''
    for (let i = 0; i < this.stringCount; i++) {
      str += Weblature.noteString(this.tuning[i])
    }
    return str
  }

  /* Get the capo position. 0 means no capo, 1 means first fret, etc. */

  getCapoPosition() {
    return this.capo
  }

  /* Set capo position. 0 means no capo, 1 means first fret, etc.
          
                 fret - the fret position. */

  setCapoPosition(fret: number) {
    this.capo = fret
  }

  /* Find the semitones played for a given fingering. */

  getSemitones(fingering: Fingering) {
    const semitones = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let bassNote

    if (fingering.length != this.stringCount) {
      throw new Object()
    } /* StringCountException;*/

    for (let i = 0; i < this.stringCount; i++) {
      if (fingering[i] >= 0) {
        let semitone = this.tuning[i] + this.capo + fingering[i]
        if (this.relativeTuning) {
          semitone += this.relativeTuning[i]
        }
        if (bassNote == undefined || semitone < bassNote) {
          bassNote = semitone
        }
        semitones[semitone % 12] = 1
      }
    }

    if (bassNote != undefined) {
      bassNote = bassNote % 12
      semitones[bassNote] = 2
    }

    return semitones
  }

  /* Return an array of possible names for the chord given the fingering,
                 the current tuning, and the current capo position.
          
                 fingering - array of finger positions relative to capo position. */

  getChordNames(fingering: Fingering) {
    const chordNames: string[] = []
    let next = 0
    let bassIndex = -1

    const semitones = this.getSemitones(fingering)

    for (let i = 0; i < 12; i++) {
      if (semitones[i] == 2) {
        bassIndex = i
      }
    }

    for (let root = 0; root < 12; root++) {
      if (semitones?.[root] > 0) {
        let third = ''
        let fifth = ''
        let seventh = ''
        let sus = ''
        let ext = 0
        let adds = ''

        /* Major, minor, suspended, or no third? */
        if (semitones[(root + 4) % 12] == 0) {
          if (semitones[(root + 3) % 12] != 0) {
            third = 'm'
          } else {
            if (semitones[(root + 2) % 12] != 0) {
              sus += 'sus2'
            }
            if (semitones[(root + 5) % 12] != 0) {
              sus += 'sus4'
            }
            if (sus == '') {
              ext = 5
            }
          }
        }

        /* Diminished, augmented, or no fifth? */
        if (semitones[(root + 7) % 12] == 0) {
          if (semitones[(root + 6) % 12] != 0) {
            fifth = 'dim'
          } else if (semitones[(root + 8) % 12] != 0) {
            fifth = 'aug'
          }
        }

        /* Extended chords. */
        if (semitones[(root + 10) % 12] != 0) {
          ext = 7
        } else if (semitones[(root + 11) % 12] != 0) {
          seventh = 'maj'
          ext = 7
        }

        if (semitones[(root + 2) % 12] != 0) {
          if (ext == 7) {
            ext = 9
          } else if (!sus.match('sus2')) {
            adds += 'add9'
          }
        }

        if (semitones[(root + 5) % 12] != 0) {
          if (ext == 9) {
            ext = 11
          } else if (!sus.match('sus4')) {
            adds += 'add11'
          }
        }

        if (semitones[(root + 9) % 12] != 0) {
          if (ext == 11) {
            ext = 13
          } else if (ext < 7) {
            ext = 6
          } else {
            adds += 'add13'
          }
        }

        /* Root name + seventh + extension + third + fifth + adds + bass */

        let chordString = Weblature.noteString(root, false, false)

        chordString += third
        chordString += fifth
        chordString += seventh

        if (ext > 0) {
          chordString += ext.toString()
        }

        chordString += sus
        chordString += adds

        /* TODO: If the bass note is not the root note, and the chord
                             name would be simpler without the bass note, remove the bass
                             note from the chord. For instance Am6/F# could be written
                             Am/F#. */

        if (root != bassIndex) {
          chordString += '/' + Weblature.noteString(bassIndex, false, false)
        }

        chordNames[next++] = chordString
      }
    }

    return chordNames
  }
}
