/** Adapted from https://github.com/webrtc/samples/blob/gh-pages/src/content/getusermedia/record/js/main.js */

import { type Recorder } from './recorder.js'
import { getMediaOptions } from './recorder-options.js'
import invariant from 'tiny-invariant'
import { logger } from '@tunasong/models'

export interface RecorderOptions {
  chunkSizeMs: number
  maxLengthSeconds: number
  type: 'audio' | 'video'
}

const defaultRecorderOptions: RecorderOptions = {
  chunkSizeMs: 10,
  maxLengthSeconds: 300,
  type: 'video',
}

export class MediaStreamRecorder implements Recorder {
  private recording: Blob | null = null

  private blobs: { current: Blob[]; previous: Blob[] | null } = {
    current: [],
    previous: null,
  }

  private mediaRecorder: MediaRecorder | null
  private options: RecorderOptions
  private mediaOptions: MediaRecorderOptions
  private startDate: Date | null = null

  constructor(private stream: MediaStream, options?: Partial<RecorderOptions>) {
    this.options = { ...defaultRecorderOptions, ...options }
    this.mediaOptions = getMediaOptions(this.options.type)

    this.mediaRecorder = new MediaRecorder(stream, this.mediaOptions)
    this.mediaRecorder.addEventListener('dataavailable', this.handleData)
  }

  get state() {
    invariant(this.mediaRecorder, 'MediaRecorder is used after it has been destroy()ed')
    return this.mediaRecorder.state
  }

  get startTime() {
    return this.startDate
  }

  handleData = (event: Event) => {
    const { data } = event as BlobEvent
    if (!(data && data.size > 0)) {
      return
    }
    this.blobs.current.push(data)
  }

  start = async () => {
    this.startDate = new Date()
    this.blobs.previous = null
    this.blobs.current = []

    invariant(this.mediaRecorder, 'MediaRecorder is used after it has been destroy()ed')

    this.mediaRecorder.start(this.options.chunkSizeMs) // collect 10ms of data
    logger.debug('MediaRecorder started', this.mediaRecorder, this.options.chunkSizeMs)
  }

  /** Loop stores the current buffer, and starts a new buffer.  */
  loop = () => {
    logger.debug('MediaRecorder loop segment. Current segment: ', this.blobs.current.length)
    this.blobs.previous = this.blobs.current
    this.blobs.current = []
  }

  stop = () => {
    this.startDate = null
    invariant(this.mediaRecorder, 'MediaRecorder is used after it has been destroy()ed')

    this.mediaRecorder.stop()
    logger.debug('MediaRecorder stopped. Segments: ', this.blobs.current.length)
  }

  /** Get the recording. If the current is less than 90% the previous, return previous */
  getRecording = (alwaysCurrent = false) => {
    const { current, previous } = this.blobs
    const usePrevious = Boolean(previous && current.length < 0.9 * previous.length && !alwaysCurrent)
    logger.debug('MediaRecording. Using previous segment: ', usePrevious)
    const blobs = usePrevious ? (previous as Blob[]) : current
    return new Blob(blobs, { type: this.mediaOptions.mimeType })
  }

  destroy(): void {
    invariant(this.mediaRecorder, 'MediaRecorder destroy() called multiple times')

    this.mediaRecorder.removeEventListener('dataavailable', this.handleData)
    this.mediaRecorder = null
  }
}
