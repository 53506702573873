import { Element } from 'slate'
import { ReactEditor } from 'slate-react'

export function isTopLevel(editor: ReactEditor, element: Element) {
  const path = ReactEditor.findPath(editor, element)
  const isTopLevel = path?.length === 1
  return isTopLevel
}

export const getElementPath = (editor: ReactEditor, element: Element) => ReactEditor.findPath(editor, element)

export const ElementQueries = {
  isTopLevel,
  getElementPath,
}
