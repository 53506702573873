import { logger } from '@tunasong/models'
import { isMidiCC, isMidiProgramChange, type Midi } from '@tunasong/schemas'
import { WebMidi, OutputChannel as WebMidiOutputChannel } from 'webmidi'
import { BluetoothMidi } from './bt-midi/bt-midi.js'
import type { MidiInput, MidiOutput } from './midi-types.js'
import { EventEmitter } from '@tunasong/ui-lib'
import type { UserConfigService } from '@tunasong/redux'

export interface OutputChannel extends Pick<WebMidiOutputChannel, 'send' | 'sendProgramChange' | 'sendControlChange'> {}

export class MidiControl extends EventEmitter<'iochanged'> {
  btMidi: BluetoothMidi
  connected = false

  inputs: MidiInput[] = []
  outputs: MidiOutput[] = []

  constructor({ configService }: { configService: UserConfigService }) {
    super()

    /** Enable Bluetooth MIDI */
    this.btMidi = new BluetoothMidi({
      onConnectionStatus: this.handleConnected,
      configService,
    })

    /** Enable the Web MIDI */
    WebMidi.enable({
      callback: this.handleConnected,
    })
  }

  /** Handle both BT MIDI and regular midi */
  sendProgramChange(program: number, options?: { channels?: number[] | number; time?: string | number }) {
    // Send to all outputs
    logger.debug('Sending Program Change: ', program, options, this.outputs)
    for (const output of this.outputs) {
      output.sendProgramChange(program, options)
    }
  }

  /** MIDI commands are MIDI entities */
  sendMidiCommands({ output, commands }: { output?: MidiOutput; commands: Midi[] }) {
    const outputs = output ? [output] : this.outputs

    for (const output of outputs) {
      for (const cmd of commands) {
        if (isMidiProgramChange(cmd)) {
          if (typeof cmd.patchBank === 'number') {
            // Send patch bank first - that is CC 0
            logger.debug('Sending MIDI Bank select (CC: 0): ', cmd.patchBank)
            output.sendControlChange(0, cmd.patchBank, { channels: cmd.channel })
          }
          if (typeof cmd.patchBankLsb === 'number') {
            // Send patch bank first - that is CC 0
            logger.debug('Sending MIDI Bank select LSB (CC: 32): ', cmd.patchBank)
            output.sendControlChange(32, cmd.patchBankLsb, { channels: cmd.channel })
          }
          logger.debug('Sending MIDI Program Change: ', cmd, output.name)
          output.sendProgramChange(cmd.program, { channels: cmd.channel })
        } else if (isMidiCC(cmd)) {
          logger.debug('Sending MIDI CC: ', cmd, output.name, { channels: cmd.channel })
          output.sendControlChange(cmd.cc, cmd.value)
        } else {
          logger.error('Unsupported MIDI command: ', cmd)
        }
      }
    }
  }
  sendMidiCommand({ output, command }: { output?: MidiOutput; command: Midi }) {
    this.sendMidiCommands({ output, commands: [command] })
  }

  destroy() {
    WebMidi.disable()
    this.inputs = []
    this.outputs = []

    this.btMidi.destroy()
    super.destroy()
  }

  handleConnected = () => {
    this.connected = true

    this.inputs = [...this.btMidi.inputs, ...WebMidi.inputs]
    this.outputs = [...this.btMidi.outputs, ...WebMidi.outputs]

    logger.debug('MIDI connected. Inputs: ', this.inputs, ', Outputs: ', this.outputs)

    this.emit('iochanged')
  }
}
