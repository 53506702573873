import { type QueryProps } from './query-props.js'
import { SystemTags } from '@tunasong/models'
import type { SearchRequest } from '@tunasong/schemas'

/** Find all queries that the user has access to */
const query = ({ query: entityType, size = 1000 }: QueryProps): SearchRequest =>
  ({
    index: 'entities',
    size,
    from: 0,
    _source_excludes: ['embedding'],
    body: {
      sort: [{ updatedAt: { order: 'desc' } }, '_score'],
      query: {
        bool: {
          must: [
            {
              term: {
                tags: SystemTags.Template,
              },
            },
            {
              term: {
                type: entityType,
              },
            },
          ],
        },
      },
    },
  } satisfies SearchRequest)

export default query
