import { Box, IconButton } from '@mui/material'
import { green } from '@mui/material/colors'
import { InputSelect, type MicControl, useMixer } from '@tunasong/audio-ui'
import { Dialog, makeStyles, useRedraw } from '@tunasong/ui-lib'
import { Headset, MicOff, Microphone, Present, PresentOff, Settings, VideoCam, VideoCamOff } from '@tunasong/icons'
import cn from 'classnames'
import { type FC, useCallback, useState } from 'react'
import { type StreamControl } from '../hooks/index.js'

const canShare = () => true

export interface RoomControlsProps {
  entityId: string

  disabled?: boolean

  /** Number of media items available */
  mediaCount?: number

  micControl: MicControl
  streamControl: StreamControl

  onMedia(open: boolean): void
}

const useStyles = makeStyles()(theme => ({
  root: {},
  off: {
    color: theme.palette.error.main,
  },
  on: {
    color: green[500],
  },
  icon: {
    height: '1.3em',
    width: '1.3em',
  },
}))

export const RoomControls: FC<RoomControlsProps> = props => {
  const { classes } = useStyles()
  const { micControl, disabled, streamControl } = props
  const [inputSelectOpen, setInputSelectOpen] = useState(false)
  const mixer = useMixer()
  const redraw = useRedraw()

  const { mute: muteVideo, isSharing, isMuted: isMutedVideo, start: selectVideo } = streamControl
  const { headset, headsetMode, mute: muteMic, isMuted: isMutedMic } = micControl

  const handleHeadset = useCallback(() => headset(!headsetMode), [headset, headsetMode])

  const handleMuteVideo = useCallback(() => muteVideo(!isMutedVideo), [isMutedVideo, muteVideo])
  const handleMuteAudio = useCallback(() => {
    if (!mixer) {
      return
    }

    muteMic(!isMutedMic)
    redraw()
  }, [isMutedMic, mixer, muteMic, redraw])

  const toggleShareScreen = useCallback(() => {
    if (isSharing) {
      selectVideo('webcam')
    } else {
      selectVideo('screenshare')
    }
  }, [isSharing, selectVideo])

  const handleAudioInput = useCallback(
    (device: MediaDeviceInfo) => {
      micControl.setDevice(device)
    },
    [micControl]
  )
  const handleVideoInput = useCallback(
    (device: MediaDeviceInfo) => {
      streamControl.setDevice(device)
    },
    [streamControl]
  )

  const openInputSelect = useCallback(() => setInputSelectOpen(true), [])
  const closeInputSelect = useCallback(() => setInputSelectOpen(false), [])

  return (
    <div className={classes.root}>
      <IconButton disabled={disabled} title="Mute audio" onClick={handleMuteAudio}>
        {isMutedMic ? <MicOff className={cn(classes.off, classes.icon)} /> : <Microphone className={classes.icon} />}
      </IconButton>
      <IconButton disabled={disabled} title="Mute video" onClick={handleMuteVideo}>
        {isMutedVideo ? (
          <VideoCamOff className={cn(classes.off, classes.icon)} />
        ) : (
          <VideoCam className={classes.icon} />
        )}
      </IconButton>
      <IconButton disabled={disabled} title="Low Latency (use headset)" onClick={handleHeadset}>
        {headsetMode ? <Headset className={cn(classes.on, classes.icon)} /> : <Headset className={classes.icon} />}
      </IconButton>

      <IconButton disabled={disabled || !canShare()} title="Share screen" onClick={toggleShareScreen}>
        {isSharing ? <PresentOff className={cn(classes.off, classes.icon)} /> : <Present className={classes.icon} />}
      </IconButton>

      {/* {rhythm ? <MetronomeButton title="Metronome" metronome={metronome} rhythm={rhythm} /> : null} */}

      <IconButton title="Show input settings" onClick={openInputSelect}>
        <Settings className={classes.icon} />
      </IconButton>

      <Dialog open={inputSelectOpen} title="Select input source" onClose={closeInputSelect} showClose={true}>
        <InputSelect type="audioinput" selectedId={micControl.selectedDevice?.deviceId} onChange={handleAudioInput} />
        <Box mt={2} />
        <InputSelect
          type="videoinput"
          selectedId={streamControl.selectedDevice?.deviceId}
          onChange={handleVideoInput}
        />
      </Dialog>
    </div>
  )
}

export default RoomControls
