import { Box, type BoxProps, Button, Typography } from '@mui/material'
import { type Entity } from '@tunasong/schemas'
import { isSong, type Persisted } from '@tunasong/schemas'
import { HBox, VBox, isMobileDevice, makeStyles, useAlert, useRedrawInterval } from '@tunasong/ui-lib'
import { type FC, useCallback, useEffect, useState } from 'react'
import { useMixer, useRecorder, useStartMic, useTransport } from '../hooks/index.js'
import { Meter } from '../meter.js'
import RecordingButton from '../recording-button.js'
import { Time } from '../time.js'
import { Waveform } from '../waveform/waveform.js'
import { FFT } from './fft.js'
import { logger } from '@tunasong/models'

export interface RecorderProps extends BoxProps {
  className?: string
  /** The parent entity for the audio. If specified, the recording experience can be customized, e.g., count-in tempo for songs */
  parentEntity?: Persisted<Entity>
  showMeter?: boolean
  autoFocus?: boolean
  /** The maximum recording time, in seconds */
  maxSeconds?: number
  /** Use either MediaRecorder (Webm / Opus) or Webaudio worklet Wav recorder. @default stream */
  recorderType?: 'stream' | 'wav'
  /** Called during recording */
  onRecording?(recording: boolean): void
  onComplete(audio: Blob): void
}

const useStyles = makeStyles()(() => ({
  recorderControls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

export const AudioRecorder: FC<RecorderProps> = props => {
  const {
    className,
    parentEntity,
    showMeter = true,
    autoFocus = false,
    onRecording,
    onComplete,
    recorderType = 'stream',
    maxSeconds = 10 * 60,
    ...restProps
  } = props
  const { classes } = useStyles()
  const mixer = useMixer()

  const { selectedDevice } = useStartMic()

  const mediaRecorder = useRecorder(recorderType)
  const { transport } = useTransport()
  const [audioData, setAudioData] = useState<Blob>()
  const [recordingUrl, setRecordingUrl] = useState<string>()
  const [isRecording, setIsRecording] = useState(false)

  const handleSave = useCallback(
    (audioData: Blob) => () => {
      setAudioData(undefined)
      setRecordingUrl(undefined)
      onComplete(audioData)
    },
    [onComplete]
  )

  useEffect(() => {
    if (!onRecording) {
      return
    }
    onRecording(isRecording)
  }, [isRecording, onRecording])

  /** Example https://developer.mozilla.org/en-US/docs/Web/API/MediaStream_Recording_API/Using_the_MediaStream_Recording_API */
  const startRecording = useCallback(async () => {
    if (!mediaRecorder) {
      throw new Error(`Cannot record without a mixer and a mediaRecorder`)
    }

    logger.debug(`Starting transport for recording. Recording type: ${recorderType}`)
    transport.start()
    mediaRecorder.start()

    setIsRecording(true)
  }, [mediaRecorder, recorderType, transport])

  const handleDelete = useCallback(() => {
    setRecordingUrl(undefined)
    setAudioData(undefined)
    setIsRecording(false)
  }, [])
  const stopRecording = useCallback(() => {
    if (!mediaRecorder) {
      return
    }
    mediaRecorder.stop()
    transport.stop()

    const audioData = mediaRecorder.getRecording()

    logger.debug('Completed recording. Pending upload....')
    // save(audioData)
    const url = URL.createObjectURL(audioData)
    setAudioData(audioData)
    setRecordingUrl(url)
    setIsRecording(false)
  }, [mediaRecorder, transport])

  /** Redraw every 500 ms to cover the time */
  useRedrawInterval(500)

  /** Stop the recording when maxSeconds has been recorded */
  const { alert } = useAlert()
  useEffect(() => {
    if (!isRecording) {
      return
    }
    const id = setTimeout(() => {
      alert({
        severity: 'warning',
        message: `Recording stopped after ${maxSeconds} seconds. This is currently the maximum recording time.`,
      })
      stopRecording()
    }, 1000 * maxSeconds)
    return () => clearTimeout(id)
  }, [alert, isRecording, maxSeconds, stopRecording])

  return (
    <VBox {...restProps}>
      {recordingUrl && audioData ? (
        <>
          <Waveform url={recordingUrl} renderPeaks={true} timeline={false} />
          <HBox sx={{ justifyContent: 'space-around' }}>
            <Button color={'secondary'} onClick={handleDelete}>
              Delete
            </Button>
            <Button onClick={handleSave(audioData)} autoFocus={autoFocus}>
              Save
            </Button>
          </HBox>
        </>
      ) : (
        <>
          {!isMobileDevice() ? (
            <VBox sx={{ minHeight: 140, maxHeight: 140, flex: 1, alignItems: 'center', justifyContent: 'center' }}>
              <FFT
                sx={{
                  position: 'relative',
                  height: '100%',
                  width: '100%',
                  color: theme => theme.palette.secondary.dark,
                }}
              />
            </VBox>
          ) : null}
          {selectedDevice ? <Typography variant="caption">{selectedDevice.label}</Typography> : null}
          {showMeter && (
            <Box className={classes.recorderControls}>
              <Meter controller={mixer?.getBus('record')} />
              <Box flex={1} />
              <Time startTime={mediaRecorder?.startTime} />
            </Box>
          )}
          <RecordingButton
            className={className}
            autoFocus={autoFocus}
            label="Start Recording"
            rhythm={isSong(parentEntity) ? parentEntity.rhythm : undefined}
            onStart={startRecording}
            onStop={stopRecording}
          />
        </>
      )}
    </VBox>
  )
}

export default AudioRecorder
