import { Button } from '@mui/material'
import { Add } from '@tunasong/icons'
import { usePlugins, type TunaPlugin } from '@tunasong/plugin-lib'
import type { Persisted } from '@tunasong/schemas'
import { type Entity } from '@tunasong/schemas'
import { useDimensions } from '@tunasong/ui-lib'
import React, { useCallback, useState, type FC } from 'react'
import { CreateEntityDialog } from './create-entity-dialog.js'

export interface CreateEntityFabProps {
  plugins?: TunaPlugin[]
  parent?: Persisted<Entity>
}

export const CreateEntityButton: FC<CreateEntityFabProps> = props => {
  const { plugins: providedPlugins, parent } = props
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handleClose = useCallback(() => setAnchorEl(null), [])
  const handleToggleDial = useCallback((ev: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(ev.currentTarget), [])
  const { isSmallOrSmaller } = useDimensions()

  const allPlugins = usePlugins()
  const plugins = providedPlugins ?? allPlugins ?? []

  return (
    <>
      <Button color="primary" onClick={handleToggleDial} endIcon={<Add />}>
        {isSmallOrSmaller ? '' : 'New'}
      </Button>
      <CreateEntityDialog open={Boolean(anchorEl)} onClose={handleClose} plugins={plugins} parent={parent} />
    </>
  )
}

export default CreateEntityButton
