import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { getPlugin, useEditor, usePlugins } from '@tunasong/plugin-lib'
import { useSuggestions } from '@tunasong/plugin-suggestion/ui'
import { type ElementType } from '@tunasong/schemas'
import { type SidebarComponent } from '@tunasong/ui-lib'
import { type FC } from 'react'

export const SidebarSuggestion: FC<SidebarComponent> = ({}) => {
  const editor = useEditor({ allowNull: true })

  const { suggestions } = useSuggestions(editor)
  const plugins = usePlugins('all')

  if (!suggestions) {
    return null
  }

  return (
    <Table>
      <TableBody>
        {suggestions.map(({ name, type: suggestionType, description, score }, idx) => {
          /** @todo suggestion type and plugin type are different, although they may overlap... */
          const type = suggestionType as ElementType
          const Icon = getPlugin({ type }, plugins)?.icon
          return (
            <TableRow key={idx}>
              <TableCell>{Icon ? <Icon /> : null}</TableCell>
              <TableCell>{name}</TableCell>
              <TableCell>{description}</TableCell>
              <TableCell>{(Math.round(score * 100) / 100).toFixed(2)}</TableCell>
            </TableRow>
          )
        })}

        {suggestions.length === 0 && (
          <TableRow>
            <TableCell colSpan={4}>No suggestions</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
