import { Box, IconButton, Tab, Tabs } from '@mui/material'
import { useEditorClass } from '@tunasong/editor'
import { Check as ResolveIcon } from '@tunasong/icons'
import { type EditorEvent, type TunaPluginContentProps } from '@tunasong/plugin-lib'
import { useEntityUpdate } from '@tunasong/redux'
import { isEntity, isPersisted, type Comment, type CoreElement, type Entity, type Persisted } from '@tunasong/schemas'
import { Feed, ScrollContainer, makeStyles, useCurrentUser } from '@tunasong/ui-lib'
import cn from 'classnames'
import { useCallback, useMemo, useRef, useState, type FC } from 'react'
import invariant from 'tiny-invariant'
import { ChatBox } from './chat-box.js'
import ChatFeed from './chat-feed.js'
import { useChat } from './chat.hook.js'

const useStyles = makeStyles()(() => ({
  root: {
    overflow: 'hidden',
    minWidth: '30%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  chatItem: {
    fontSize: '14px !important',
    justifyContent: 'center',
  },
  feed: {
    overflow: 'auto',
  },
}))

export interface ChatProps extends TunaPluginContentProps<Entity> {
  className?: string
  showTabs?: boolean
  onComment?(comment: Comment): void
}

export const Chat: FC<ChatProps> = props => {
  const { element, className, onComment, showTabs = true } = props
  const { classes } = useStyles()
  const updateEntity = useEntityUpdate()

  const ChatEditor = useEditorClass('ChatEditor')

  const { userId } = useCurrentUser()

  const { activeComments, resolvedComments, addComment, resolveComment, getNext } = useChat({
    element,
    pageSize: 10,
  })
  const handleResolve = useCallback((c: Comment) => () => resolveComment(c), [resolveComment])

  const commentTemplate = useMemo(
    () =>
      element?.id
        ? ({
            type: 'comment',
            parentId: element.id,
            userId: userId ?? 'unknown',
            children: [{ type: 'line', children: [{ text: '' }] }],
          } satisfies CoreElement<Comment>)
        : null,
    [element.id, userId]
  )
  const commentRef = useRef<Comment | null>(commentTemplate)

  /** While the editor is readOnly, updates may occur - e.g., todo items */
  const handleCommentUpdate = useCallback(
    (e: CoreElement) => {
      if (!(isPersisted(e) && isEntity(e))) {
        return
      }
      updateEntity(e.id, e)
    },
    [updateEntity]
  )

  const createFeedItems = useCallback(
    (feedComments: Persisted<Comment>[] = []) =>
      feedComments?.map(c => ({
        ...c,
        content: (
          <>
            <Box flex={1}>
              {/* @todo this will flicker when receiving updates. Can we render this offline? */}
              <ChatEditor
                id={c.id}
                className={classes.chatItem}
                readOnly={true}
                autoFocus={false}
                element={c}
                onChange={handleCommentUpdate}
              />
            </Box>
          </>
        ),
        actions:
          showTabs && isPersisted(c) && showTabs && !c.resolved && c.userId === userId ? (
            <IconButton
              sx={{
                color: 'transparent',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
              onClick={handleResolve(c)}
            >
              <ResolveIcon color="inherit" />
            </IconButton>
          ) : undefined,
      })) ?? [],
    [ChatEditor, classes.chatItem, handleCommentUpdate, handleResolve, showTabs, userId]
  )

  /**
   * comment state is set to the comment object
   */
  /** We keep a counter, so that we ignore pending comments when the expected count is at what we expect */
  const expectedCommentCount = useRef(0)

  const handleAddComment = useCallback(
    async (ev: EditorEvent) => {
      ev.preventDefault()
      const comment = commentRef.current
      invariant(comment, 'Comment should be set')

      expectedCommentCount.current = activeComments.length + 1

      /** @todo The problem here is that the update from server side comes before this code is executed */
      const createdComment = await addComment(comment)

      if (onComment && createdComment) {
        onComment(createdComment)
      }
      commentRef.current = commentTemplate
    },
    [activeComments?.length, addComment, onComment, commentTemplate]
  )

  const comments = useMemo(() => createFeedItems(activeComments), [createFeedItems, activeComments])
  const resolved = useMemo(() => createFeedItems(resolvedComments), [createFeedItems, resolvedComments])

  const handleComment = useCallback((c: Comment) => (commentRef.current = c), [])

  const [tab, setTab] = useState(0)
  const handleTab = useCallback((ev: unknown, num: number) => setTab(num), [])
  // we use state to trigger a re-render since we need topRef to be set for ScrollContainer
  const [topEl, setTopEl] = useState<HTMLDivElement | null>(null)

  return (
    <Box className={cn(classes.root, className)}>
      {showTabs && (
        <Tabs value={tab} onChange={handleTab}>
          <Tab label={`Active`} />
          <Tab label={`Resolved (${resolved.length})`} />
        </Tabs>
      )}

      {tab === 0 && (
        <>
          <ScrollContainer onUp={getNext} topEl={topEl}>
            <ChatFeed items={comments} topRef={setTopEl} />
          </ScrollContainer>
          {commentRef.current ? <ChatBox onChange={handleComment} onComplete={handleAddComment} /> : null}
        </>
      )}

      {tab === 1 ? <Feed items={resolved} className={classes.feed} /> : null}
    </Box>
  )
}
