import { useMic } from '@tunasong/audio-ui'
import { useEntity, useProfiles, useSelector } from '@tunasong/redux'
import { Draggable, withSuspense } from '@tunasong/ui-lib'
import classNames from 'classnames'
import React, { type FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useStream } from '../hooks/index.js'
import { useVideoSignaling } from '../ws/index.js'
import { VideoConnection } from './lib/connection.js'
import { useStyles } from './room.styles.js'
import { logger } from '@tunasong/models'

const Stream = withSuspense(React.lazy(async () => import('./stream.js')))

export interface RoomOverlayProps {
  className?: string
  entityId?: string
  open: boolean
}

/** Video overlay on an entity - can be a room or any other entity */
export const RoomOverlay: FC<RoomOverlayProps> = props => {
  const { className, entityId, open } = props
  const { classes } = useStyles()
  const { entity } = useEntity(entityId)
  const allConnections = useSelector(state => state.webrtc.connections as Record<string, VideoConnection>)
  const connections = useMemo(() => Object.values(allConnections).filter(Boolean), [allConnections])
  const started = useRef(false)
  const clientInfo = useSelector(state => state.presence.activeClients)
  const { profiles } = useProfiles()

  const [stream, setStream] = useState<MediaStream | null>(null)

  const getProfileFromPeerId = useCallback(
    (peerId: string) => {
      const client = clientInfo[peerId]
      return client?.userId ? profiles?.find(p => p.userId === client.userId) : undefined
    },
    [clientInfo, profiles]
  )

  const micControl = useMic()
  const streamControl = useStream()
  /** Start the webcam and mic before starting the peer connection */
  const { startSession, stopSession } = useVideoSignaling({ entity, stream })

  const starting = useRef(false)
  const handleStart = useCallback(async () => {
    if (started.current || starting.current) {
      return
    }
    /** Need to set this here to avoid a race condition for the async below */
    starting.current = true

    /** Start the webcam and mic before starting the peer connection */
    const { shareStream } = await streamControl.start('webcam')
    if (!shareStream) {
      starting.current = false
      return logger.warn(`shareStream is null`)
    }

    await micControl.start()
    setStream(shareStream)
    startSession()

    starting.current = false
    started.current = true
  }, [micControl, startSession, streamControl])

  const handleStop = useCallback(() => {
    if (!started.current) {
      return
    }
    setStream(null)
    stopSession()
    streamControl.stop()
    micControl.stop()
    started.current = false
  }, [micControl, stopSession, streamControl])

  useEffect(() => {
    if (!open || started.current) {
      return
    }

    handleStart().catch(err => logger.error('Failed to start', err))
    return () => {
      handleStop()
    }
  }, [handleStart, handleStop, open])

  if (!open) {
    return null
  }

  return (
    <div className={classNames(className, classes.overlay)}>
      <Draggable>
        <Stream
          className={classes.stream}
          description={'Connected'}
          srcObject={streamControl.localStream}
          muted={true}
        />
      </Draggable>
      {connections.map(c => (
        <Draggable key={c.clientId}>
          <Stream
            className={classes.stream}
            peer={getProfileFromPeerId(c.peerClientId)}
            description="Connected"
            srcObject={c.stream}
          />
        </Draggable>
      ))}
    </div>
  )
}

export default RoomOverlay
