import { Delete } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  MenuList,
  type ListProps,
  type SxProps,
} from '@mui/material'
import { Add } from '@tunasong/icons'
import {
  entityEvents,
  type Entity,
  type EntityCommandSpec,
  type EntityEvent,
  type EventCommands,
  type Persisted,
} from '@tunasong/schemas'

import { DialogTitle, isMobileDevice } from '@tunasong/ui-lib'
import { useState, type FC, type MouseEvent } from 'react'
import invariant from 'tiny-invariant'
import EntityEventSelect from './entity-event-select.js'

interface EventCommandsListProps extends Omit<ListProps, 'onChange'> {
  eventCommands: EventCommands
  // If the target entity is known provide it here
  targetEntity?: Persisted<Entity>
  onChange?(eventCommands: EventCommands): void
}

export const EventCommandsList: FC<EventCommandsListProps> = props => {
  const { onChange, targetEntity, eventCommands } = props

  const [editEventType, setEditEventType] = useState<EntityEvent>()

  const [selectedCommand, setSelectedCommand] = useState<EntityCommandSpec | null>(null)

  const readOnly = Boolean(!onChange)

  const handleClose = () => {
    setEditEventType(undefined)
    setSelectedCommand(null)
  }

  const handleSave = (command?: EntityCommandSpec | null) => (ev: MouseEvent) => {
    invariant(editEventType, 'No event type selected')
    invariant(onChange)
    if (!command) {
      return
    }
    ev.preventDefault()
    const existingCommands = eventCommands?.[editEventType] ?? []
    onChange({
      ...eventCommands,
      [editEventType]: [...existingCommands, command],
    })
    handleClose()
  }

  const handleRemove = (eventType: EntityEvent, command: EntityCommandSpec) => (ev: MouseEvent) => {
    invariant(onChange)
    ev.preventDefault()
    const commands =
      eventCommands?.[eventType]?.filter(
        cmd => !(cmd.commandId === command.commandId && cmd.entityId === command.entityId)
      ) ?? []
    onChange({ ...eventCommands, [eventType]: commands })
  }
  const showOnHover: SxProps = !isMobileDevice()
    ? {
        '&:hover': {
          opacity: 1,
        },
        opacity: 0,
      }
    : {}

  return (
    <>
      <MenuList>
        {Object.entries(entityEvents)
          .map(([eventTypeUntyped, { description }]) => {
            const eventType = eventTypeUntyped as EntityEvent
            const commands = eventCommands?.[eventType] ?? []
            return [
              !readOnly ? (
                <MenuItem disabled={true} key={`${eventType}-label`}>
                  <ListItemText primary={eventType} secondary={description} />
                </MenuItem>
              ) : null,

              ...commands.map(command => (
                <MenuItem key={`${command.entityId}-${command.commandId}}`}>
                  <ListItemText
                    primary={command.label}
                    secondary={`${eventType} ${command.commandParams ? JSON.stringify(command.commandParams) : ''}`}
                  />

                  {!readOnly ? (
                    <ListItemSecondaryAction sx={showOnHover}>
                      <IconButton onClick={handleRemove(eventType, command)} color="error">
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  ) : null}
                </MenuItem>
              )),

              !readOnly ? (
                <MenuItem key={`${eventType}-add`} color="primary" onClick={() => setEditEventType(eventType)}>
                  <ListItemIcon>
                    <Add />
                  </ListItemIcon>
                  <ListItemText primary="Add entity command" secondary={eventType} />
                </MenuItem>
              ) : null,
            ]
          })
          .flat()}
      </MenuList>
      <Dialog open={Boolean(editEventType)} onClose={handleClose} maxWidth="md" fullWidth fullScreen={isMobileDevice()}>
        <DialogTitle onClose={handleClose}>Event command: {editEventType}</DialogTitle>
        <DialogContent sx={{ display: 'flex' }}>
          <EntityEventSelect
            key={`${targetEntity?.id}-editEventType`}
            onSelect={setSelectedCommand}
            targetEntity={targetEntity}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={!selectedCommand} onClick={handleSave(selectedCommand)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
