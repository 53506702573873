/** https://developers.google.com/web/updates/2016/01/mediarecorder */

export const getMediaOptions = (type: 'audio' | 'video'): MediaRecorderOptions => {
  const candidates: MediaRecorderOptions[] =
    type === 'video'
      ? [{ mimeType: 'video/webm;codecs=vp9' }, { mimeType: 'video/webm' }]
      : [{ mimeType: 'audio/webm;codecs=opus', audioBitsPerSecond: 256000 }]

  for (const options of candidates) {
    if (options.mimeType && MediaRecorder.isTypeSupported(options.mimeType)) {
      return options
    }
  }
  throw new Error(`Cannot find any supported codecs for ${type}`)
}
