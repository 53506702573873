import { Dialog, DialogContent, type DialogProps } from '@mui/material'
import { type SearchSummary } from '@tunasong/models'
import { DialogTitle } from '@tunasong/ui-lib'
import { type FC } from 'react'
import { FullSearch, type FullSearchProps } from './full-search.js'

// Dialog to select an entity from search results
interface SearchSelectDialogProps extends Omit<DialogProps, 'onClose'>, Pick<FullSearchProps, 'defaultFilter'> {
  onSelected(result: SearchSummary): void
  onCanceled(): void
}

export const SearchSelectDialog: FC<SearchSelectDialogProps> = props => {
  const { defaultFilter, onCanceled, onSelected, ...restProps } = props

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      PaperProps={{ sx: { position: 'fixed', top: 32, m: 0 } }}
      onClose={onCanceled}
      autoFocus={false}
      {...restProps}
    >
      <DialogTitle onClose={onCanceled}>Select an entity</DialogTitle>
      <DialogContent>
        <FullSearch
          onOpen={e => {
            onSelected(e)
          }}
          defaultFilter={defaultFilter}
          onClose={onCanceled}
          autoFocus={false}
        />
      </DialogContent>
    </Dialog>
  )
}
