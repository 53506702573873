import { logger } from '@tunasong/models'

export const muteStreams = (stream?: MediaStream | null, mute = true, type: 'audio' | 'video' | 'all' = 'all') => {
  {
    logger.debug('Mute for stream', mute, stream)
    if (!stream) {
      return
    }
    if (type === 'audio' || type === 'all') {
      stream.getAudioTracks().forEach(s => (s.enabled = !mute))
    }
    if (type === 'video' || type === 'all') {
      stream.getVideoTracks().forEach(s => (s.enabled = !mute))
    }
  }
}

export const isMuted = (stream?: MediaStream, type: 'audio' | 'video' = 'audio') => {
  {
    if (!stream) {
      return true
    }
    if (type === 'audio') {
      return Boolean(!stream.getAudioTracks()[0].enabled)
    }
    if (type === 'video') {
      return Boolean(!stream.getVideoTracks()[0].enabled)
    }
    return false
  }
}
