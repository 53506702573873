import { type InstrumentType } from '@tunasong/schemas'
import { type FC } from 'react'
import { type InstrumentEditorProps } from '../instrument-editor.props.js'
import Bass from './bass.js'
import Drums from './drums.js'
import Guitar from './guitar.js'
import Keyboard from './keyboard.js'
import Piano from './piano.js'
import Vocals from './vocals.js'

const Editors: Record<InstrumentType, FC<InstrumentEditorProps>> = {
  guitar: Guitar,
  vocals: Vocals,
  bass: Bass,
  keyboard: Keyboard,
  piano: Piano,
  drums: Drums,
}

export { Editors }
