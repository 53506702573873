import { NoteLib, type NoteStr, type Octave } from '@tunasong/music-lib'

export const midiToNote = (midi: number): NoteStr => {
  const index = midi % 12
  const octave = (Math.floor(midi / 12) - 1) as Octave

  const note = NoteLib.getNoteByIndex(index)

  return `${note}${octave}`
}
