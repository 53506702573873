import { Box, Divider, Tab, Tabs } from '@mui/material'
import type { MidiSpec } from '@tunasong/schemas'
import { useState } from 'react'
import { MidiCCEditor } from './midi-cc-editor.js'
import { MidiChannel } from './midi-channel.js'
import { MidiPCEditor } from './midi-pc-editor.js'

export interface MidiGridProps {
  /** use the specified MIDI channel. If not specified, use Omni (all channels) */
  channel?: number
  spec: MidiSpec
  onChange(spec: MidiSpec): void
  onChannelChange?(channel: number): void
}

export function MidiEditor({ spec, onChange, channel, onChannelChange }: MidiGridProps) {
  const [tab, setTab] = useState(0)
  const handleTab = (ev: React.SyntheticEvent, value: number) => setTab(value)

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <MidiChannel defaultValue={channel} onChange={onChannelChange} />
      <Divider sx={{ my: 2 }} />

      <Tabs value={tab} onChange={handleTab}>
        <Tab value={0} label="Program Change (PC)" />
        <Tab value={1} label="Continuous Control (CC)" />
      </Tabs>
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        {tab === 0 && <MidiPCEditor spec={spec} onChange={onChange} />}
        {tab === 1 && <MidiCCEditor spec={spec} onChange={onChange} />}
      </Box>
    </Box>
  )
}
