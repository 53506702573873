import { ListItemIcon, ListItemText, MenuItem as MuiMenuItem } from '@mui/material'
import { Transform } from '@tunasong/icons'
import { type RenderMenuItemProps } from '@tunasong/plugin-lib'
import { useEntityUpdate } from '@tunasong/redux'
import { type EntityType, isPersisted } from '@tunasong/schemas'
import { useNavigateToEntity } from '@tunasong/ui-lib'
import { type FC, type MouseEvent, useCallback } from 'react'

export interface ConvertMenuItemProps extends RenderMenuItemProps {
  to: EntityType
}

const ConvertMenuItem: FC<ConvertMenuItemProps> = props => {
  const { to, element, onClose, ...restProps } = props
  const navigateToEntity = useNavigateToEntity()
  const updateEntity = useEntityUpdate()

  const disabled = false
  const handleConvert = useCallback(
    (ev: MouseEvent) => {
      if (!isPersisted(element)) {
        return
      }
      ev.preventDefault()
      ev.stopPropagation()
      updateEntity(element.id, {
        type: to,
      })

      navigateToEntity({ ...element, type: to })
      if (onClose) {
        onClose()
      }
    },
    [element, navigateToEntity, onClose, to, updateEntity]
  )

  return (
    <MuiMenuItem {...restProps} disabled={disabled} onClick={handleConvert}>
      <ListItemIcon>
        <Transform />
      </ListItemIcon>
      <ListItemText>Convert to {to}...</ListItemText>
    </MuiMenuItem>
  )
}

export default ConvertMenuItem
