import { Box } from '@mui/material'
import type { Clip, Track } from '@tunasong/schemas'
import React, { useCallback, useMemo, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { ClipContainer } from './clips/clip-container.js'
import useClipStyles from './clips/clip.styles.js'
import { getClipComponent } from './clips/index.js'
import { RecordingClip } from './clips/recording-clip.js'
import { useTrackChannel } from './hooks/track-channel.js'

export interface TrackProps {
  className?: string
  /** Duration of the view */
  pixelsPerSecond: number
  /** The height of this track is controlled by the channel height. */
  // height: number
  track: Track
  onUpdate?(track: Track): void
}

export const TrackLane = React.forwardRef<unknown, TrackProps>((props, ref) => {
  const { track, pixelsPerSecond, onUpdate, ...restProps } = props
  const { classes: clipClasses } = useClipStyles()

  const localRef = useRef<HTMLElement | null>(null)
  const mergedRef = mergeRefs([localRef, ref])

  const channel = useTrackChannel(track)

  const handleClipUpdate = useCallback(
    (idx: number) => (clip: Clip) => {
      if (!onUpdate) {
        return
      }
      const clips = [...(track.clips ?? [])]
      clips[idx] = clip
      onUpdate({ ...track, clips })
    },
    [onUpdate, track]
  )
  const handleClipDelete = useCallback(
    (idx: number) => () => {
      if (!onUpdate) {
        return
      }
      const clips = [...(track.clips ?? [])]
      clips.splice(idx, 1)
      onUpdate({ ...track, clips })
    },
    [onUpdate, track]
  )

  const sortedClips = useMemo(
    () => [...(track.clips ?? [])].sort((a, b) => (a.start === b.start ? 0 : a.start < b.start ? -1 : 1)),
    [track.clips]
  )

  if (!channel) {
    return null
  }

  return (
    <Box ref={mergedRef} {...restProps}>
      {sortedClips.map((clip, idx) => (
        <ClipContainer
          key={idx}
          clip={clip}
          channel={channel}
          pixelsPerSecond={pixelsPerSecond}
          onDelete={handleClipDelete(idx)}
          onUpdate={handleClipUpdate(idx)}
          Component={getClipComponent(clip)}
        />
      ))}
      {/* Dynamic record clip  */}
      <RecordingClip
        className={clipClasses.recordingClip}
        recorder={channel.recorder}
        pixelsPerSecond={pixelsPerSecond}
      />
    </Box>
  )
})

export default TrackLane
