import { isCoreElement } from '@tunasong/schemas'
import { EditorQueries, useEditor } from '@tunasong/plugin-lib'
import { type EntityOrElement } from '@tunasong/schemas'
import { useCallback } from 'react'
import invariant from 'tiny-invariant'

/** Update the Slate element. @note children are not updated, for that other transforms must be used. */
export const useElementUpdate = <T extends EntityOrElement>(element: T) => {
  const editor = useEditor()
  const handleChange = useCallback(
    (update: Partial<T>) => {
      invariant(isCoreElement(element), `Element is not a Slate element: ${JSON.stringify(element)}`)
      EditorQueries.updateElement(editor, element, update)
    },
    [editor, element]
  )

  return handleChange
}
