/** Midi indicator */

import { Midi } from '@tunasong/icons'
import { useMainColors } from '@tunasong/ui-lib'
import { useCallback, useEffect, useState } from 'react'
import { useMidi } from './midi.hook.js'

export const MidiIndicator = () => {
  const [activityCounter, setActivityCounter] = useState(0)
  const onMidiEvent = useCallback(() => {
    setActivityCounter(activityCounter => activityCounter + 1)
  }, [])
  // Count down activity
  useEffect(() => {
    if (activityCounter === 0) {
      return
    }
    const timeout = setInterval(() => {
      setActivityCounter(activityCounter => activityCounter - 1)
    }, 100)
    return () => clearInterval(timeout)
  })
  const { inputs, outputs } = useMidi({ onMidiEvent })

  const hasInputs = inputs?.length ?? 0
  const hasOutputs = outputs?.length ?? 0
  const isConnected = hasInputs || hasOutputs

  const { classes: colorClasses } = useMainColors()

  return isConnected ? <Midi className={activityCounter > 0 ? colorClasses.red : undefined} /> : null
}
