/** ToneJS based Wav player */

import { logger } from '@tunasong/models'
import { PolySynth, Synth } from 'tone'
import { type MidiNoteEvent, midiToNote } from '../events/index.js'
import { type AudioInstrument } from './instrument.js'

export class BasicSynth extends PolySynth implements AudioInstrument {
  constructor() {
    super({
      voice: Synth,
    })
  }
  onEvent(event: MidiNoteEvent): void {
    logger.debug(`BasicSynth play`, event)

    const { type, note } = event
    const name = midiToNote(note.number)
    /**  Handle events  */
    if (type === 'noteon') {
      const { value: velocity } = event
      this.triggerAttack(name, 0, velocity as number)
    } else if (type === 'noteoff') {
      this.triggerRelease(name, 0)
    } else {
      throw new Error(`Unknown event type ${type}`)
    }
  }
}
