import { Slide } from '@mui/material'
import { TunaEditorContext, useLayout, type TunaEditor } from '@tunasong/plugin-lib'
import {
  ContentContainer,
  EntityEventsContainer,
  FullPageProgress,
  HBox,
  VBox,
  useDimensions,
  type ScrollState,
} from '@tunasong/ui-lib'
import { usePresenceSignaling } from '@tunasong/ws'
import { useState } from 'react'
import Sidebar from '../sidebar/sidebar.js'
import type { ChromeProps } from './chrome.js'
import { EntityHeader } from './entity-header.js'
import { EntityLayout } from './entity-layout.js'

export interface EntityChromeProps extends ChromeProps {
  // Override showSidebar from the layout
  showContainer?: boolean
}

export function EntityChrome(props: EntityChromeProps) {
  const {
    entity,
    layoutName,
    children,
    showSidebar = true,
    showHeader = true,
    showContainer: providedShowContainer,
  } = props

  const { isSmallOrSmaller } = useDimensions()

  usePresenceSignaling(entity)

  const [scrollState, setScrollState] = useState<ScrollState>()
  const scrollHideHeader = scrollState === 'down' && isSmallOrSmaller

  const layout = useLayout({ entity, layoutName })

  const [editor, setEditor] = useState<TunaEditor | null>(null)

  const layoutContent = (
    <>
      {entity ? <EntityLayout entity={entity} layout={layout} onEditor={setEditor} /> : <FullPageProgress />}
      {children}
    </>
  )

  // If no layout is specidied, we show the container.
  // If layout is specified, we show the container if it's specified in the layout.
  const showContainer =
    typeof providedShowContainer !== 'undefined' ? providedShowContainer : layout?.container !== false

  const container = layout?.container ? layout.container : {}

  return (
    <TunaEditorContext.Provider value={editor}>
      <EntityEventsContainer entity={entity}>
        <VBox
          sx={{
            overflow: 'hidden',
            flex: 1,
          }}
        >
          <HBox sx={{ flex: 1, overflow: 'hidden' }}>
            <VBox
              sx={{
                overflow: 'auto',
                flex: 1,
                // We use absolute positioning for the header, so we need to reset the coordinate system
                transform: 'translateZ(0)',
              }}
            >
              {showHeader ? (
                <Slide direction="down" in={!scrollHideHeader}>
                  <EntityHeader entity={entity} layoutName={layoutName} showSidebar={container?.showSidebar ?? true} />
                </Slide>
              ) : null}

              {!showContainer ? (
                <VBox
                  sx={{
                    pt: showHeader ? 7 : 0,
                    overflow: 'hidden',
                    flex: 1,
                    containerName: 'EntityChromeNonContainer',
                  }}
                >
                  {layoutContent}
                </VBox>
              ) : (
                <ContentContainer
                  sx={{
                    pt: showHeader ? 7 : 0,
                    pb: 7,
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    containerName: 'EntityChromeContainer',
                    ...container?.containerProps?.sx,
                  }}
                  slots={{
                    EntityControls: layout?.slots?.EntityControls,
                  }}
                  entity={entity}
                  onScrollChange={setScrollState}
                  {...container?.containerProps}
                >
                  {layoutContent}
                </ContentContainer>
              )}
            </VBox>
            {showSidebar && showContainer ? <Sidebar entity={entity} /> : null}
          </HBox>
        </VBox>
      </EntityEventsContainer>
    </TunaEditorContext.Provider>
  )
}
