import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useTransport } from '@tunasong/audio-ui'
import { type TunaEditor, WordQueries } from '@tunasong/editor'
import { clearIndexedDBCache } from '@tunasong/plugin-collab-yjs'
import { EditorQueries, useYDoc } from '@tunasong/plugin-lib'
import { useEntity, useMusicContext } from '@tunasong/redux'
import { Dialog, useRedraw } from '@tunasong/ui-lib'
import { type FC, useEffect, useState } from 'react'
import ReactJson from 'react-json-view'

export interface DebugInfoProps {
  editor: TunaEditor
}

export const DebugInfo: FC<DebugInfoProps> = ({ editor }) => {
  const currentBlock = EditorQueries.currentElement(editor)
  const { transport } = useTransport()
  const entityId = editor.rootElement?.id
  const { entity } = useEntity(entityId)
  const { v1, v2 } = useYDoc(entity)
  const [context = null] = useMusicContext<object>({ editorId: editor.id, selector: state => state })
  const [details, setShowDetails] = useState<object | null>(null)
  const redraw = useRedraw()
  useEffect(() => {
    const timer = setInterval(redraw, 200)
    return () => clearInterval(timer)
  }, [redraw])

  return (
    <>
      <Button onClick={() => WordQueries.move(editor, { mode: 'previous' })}>Prev Word</Button>
      <Button onClick={() => WordQueries.move(editor, { mode: 'next' })}>Next Word</Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Transport state</TableCell>
            <TableCell>{transport.state}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Transport Ticks</TableCell>
            <TableCell>{transport.ticks}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Current Block Type</TableCell>
            <TableCell>
              {currentBlock?.type} {currentBlock?.id ? ` (${currentBlock.id})` : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Current Block ID</TableCell>
            <TableCell>{currentBlock?.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Previous Word</TableCell>
            <TableCell>{WordQueries.text(editor, { mode: 'previous' })}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Current Word</TableCell>
            <TableCell>{WordQueries.text(editor, { mode: 'current' })}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Next Word</TableCell>
            <TableCell>{WordQueries.text(editor, { mode: 'next' })}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>Previous Selection</TableCell>
            <TableCell>{JSON.stringify(WordQueries.selection(editor, { mode: 'previous' }))}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell>Current Selection</TableCell>
            <TableCell>{JSON.stringify(WordQueries.selection(editor, { mode: 'current' }))}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>Next Selection</TableCell>
            <TableCell>{JSON.stringify(WordQueries.selection(editor, { mode: 'next' }))}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell>Selection</TableCell>
            <TableCell>{JSON.stringify(editor.selection)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Current Block Data</TableCell>
            <TableCell>{JSON.stringify(currentBlock, null, 2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Context</TableCell>
            {/* <TableCell>{JSON.stringify(editor.children, null, 2)}</TableCell> */}
            <TableCell>
              <Button onClick={() => setShowDetails(context)}>Show Context</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>YDoc info</TableCell>
            <TableCell>
              {v1 && !v2 ? 'v1 (legacy) ' : null}
              {v2 ? 'v2' : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Full Doc</TableCell>
            {/* <TableCell>{JSON.stringify(editor.children, null, 2)}</TableCell> */}
            <TableCell>
              <Button onClick={() => setShowDetails(editor.children)}>Open...</Button>
            </TableCell>
          </TableRow>
          {entityId ? (
            <TableRow>
              <TableCell>Clear IndexedDB Operations</TableCell>
              {/* <TableCell>{JSON.stringify(editor.children, null, 2)}</TableCell> */}
              <TableCell>
                <Button onClick={() => clearIndexedDBCache(entityId)}>Clear</Button>
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
      <Dialog title="Full doc" open={Boolean(details)} onClose={() => setShowDetails(null)}>
        <ReactJson src={details as object} theme="monokai" />
      </Dialog>
    </>
  )
}
