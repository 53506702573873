import { useState } from 'react'
import { AudioEngine } from './engine.js'
import { useAsyncUserConfigService } from '@tunasong/ui-lib'

export const useCreateAudioEngine = (enabled = true) => {
  const configService = useAsyncUserConfigService()
  const [engine] = useState(typeof window !== 'undefined' ? new AudioEngine({ configService }) : null)

  return enabled ? engine : null
}
