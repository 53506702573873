import { useTheme } from '@mui/material'
import { isPositionEvent } from '@tunasong/schemas'
import { formatTime } from '../../lib/index.js'
import { type RendererPlugin } from '../renderer.js'
import useStyles from './styles.js'

const Label: RendererPlugin = ({ laneHeight, event, pixelsPerSecond }) => {
  const { classes } = useStyles()
  const theme = useTheme()

  if (!isPositionEvent(event)) {
    return null
  }
  const labelPadding = theme.spacing(0.5)
  const label = formatTime(event.start, { millis: false, minutePrefix: '' })

  const x = event.start * pixelsPerSecond + labelPadding
  const y = laneHeight

  return (
    <text className={classes.label} x={x} y={y} fill={theme.palette.text.primary}>
      {label}
    </text>
  )
}

export default Label
