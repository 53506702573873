import { ListItem, ListItemText } from '@mui/material'
import { capitalize } from '@tunasong/models'
import { type FC } from 'react'
import type { MidiInput, MidiOutput } from './midi-types.js'

export interface MidiIOItemProps {
  type: 'input' | 'output'
  item: MidiInput | MidiOutput
}

export const MidiIOItem: FC<MidiIOItemProps> = props => {
  const { item } = props
  const typeLabel = capitalize(props.type)
  return (
    <ListItem key={item.name}>
      <ListItemText primary={item.name} secondary={typeLabel} />
    </ListItem>
  )
}

export default MidiIOItem
