import { type TunaPlugin } from '@tunasong/plugin-lib'
import { isEntity, isProfile, type CoreElement } from '@tunasong/schemas'
import { EntityIcon, UserAvatar } from '@tunasong/ui-lib'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getAvatarOrIcon = (element: Pick<CoreElement, 'type' | 'userId'>, plugins: TunaPlugin[]) => {
  const avatar = isProfile(element) ? (
    <UserAvatar userId={element.userId} size="small" />
  ) : isEntity(element) ? (
    // <EntityAvatar size="small" type={element.type} />
    <EntityIcon entity={element} />
  ) : (
    <>?</>
  )

  return avatar
}
