import { type FilterFunc, type SearchSummary } from '@tunasong/models'
import type { SearchRequest } from '@tunasong/schemas'
import { type Entity } from '@tunasong/schemas'
import { useMemo } from 'react'
import typeQuery from './queries/type-query.js'
import { SearchResults } from './search-results.js'
import { useAdvancedSearch } from './search.hook.js'

export interface SearchListProps {
  query?: SearchRequest
  size?: number
  inputRef?: HTMLElement | null
  resultFilter?: FilterFunc
  selected?: Pick<Entity, 'id'>
  onChange(summary: SearchSummary): void
}

export function SearchList(props: SearchListProps) {
  const { query: initialQuery, inputRef, resultFilter, onChange, selected } = props

  const query = useMemo(
    () => initialQuery ?? typeQuery({ query: 'song', size: props.size }),
    [initialQuery, props.size]
  )
  /** @todo filter on type */
  const { results, searching, getNext } = useAdvancedSearch(query, {
    resultFilter,
    debounceDelay: 0,
    queryType: 'type',
  })

  return (
    <SearchResults
      searching={searching}
      inputRef={inputRef}
      entities={results}
      onChange={onChange}
      onEndOfResults={getNext}
      selectedIndex={results.findIndex(r => r.id === selected?.id)}
    />
  )
}
