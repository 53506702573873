import {
  AudioWorkletNode,
  type IAudioContext,
  type IAudioParam,
  type IAudioWorkletNode,
  type IAudioWorkletNodeOptions,
  type IReadOnlyMap,
} from 'standardized-audio-context'
import invariant from 'tiny-invariant'
import { type DSPFeatureName } from './features.js'

export interface IDSPWorkletNode extends IAudioWorkletNode<IAudioContext> {
  parameters: IReadOnlyMap<DSPFeatureName, IAudioParam>
}

export const createDSPWorkletNode = (context: IAudioContext, options?: Partial<IAudioWorkletNodeOptions>) => {
  invariant(AudioWorkletNode, 'AudioWorkletNode is not defined')
  invariant(context.audioWorklet, 'audioWorklet not defined in context')

  const node = new AudioWorkletNode(context, 'dsp-worklet', options) as IAudioWorkletNode<IAudioContext> &
    IDSPWorkletNode

  const enableFeature = (feature: DSPFeatureName, enabled: boolean, atTime: number = node.context.currentTime) => {
    const val = enabled ? 1 : 0
    node.parameters.get(feature)?.setValueAtTime(val, atTime)
  }

  return {
    dspNode: node,
    enableFeature,
  }
}
