export const writeUTFBytes = (view: DataView, offset: number, s: string) => {
  const lng = s.length
  for (let i = 0; i < lng; i++) {
    view.setUint8(offset + i, s.charCodeAt(i))
  }
}

export const interleave = (channels: Float32Array[]) => {
  const noChannels = channels.length
  if (noChannels === 1) {
    return channels[0]
  }

  const len = channels[0]?.length ?? 0
  const fullLength = len * noChannels
  const result = new Float32Array(fullLength)

  for (const channel of channels) {
    if (channel.length !== len) {
      throw new Error(`Interleave: cannot interleave buffers of different length: ${channel.length} !== ${len}`)
    }
  }

  let inputIndex = 0
  for (let i = 0; i < fullLength; ) {
    for (const channel of channels) {
      result[i++] = channel[inputIndex]
    }
    inputIndex++
  }
  return result
}

export const flatBuffers = (buffers: Float32Array[]): Float32Array => {
  const recordingLength = buffers.map(b => b.length).reduce((a, b) => a + b, 0)
  const result = new Float32Array(recordingLength)
  let offset = 0
  for (const buffer of buffers) {
    result.set(buffer, offset)
    offset += buffer.length
  }
  return result
}

export const encodeWav = (sampleRate: number, channels: Float32Array[]): Blob => {
  // we interleave both channels together
  const interleaved = interleave(channels)

  const numChannels = channels.length
  // we create our wav file
  const buffer = new ArrayBuffer(44 + interleaved.length * 2)
  const view = new DataView(buffer)

  // RIFF chunk descriptor
  writeUTFBytes(view, 0, 'RIFF')
  view.setUint32(4, 44 + interleaved.length * 2, true)
  writeUTFBytes(view, 8, 'WAVE')
  // FMT sub-chunk
  writeUTFBytes(view, 12, 'fmt ')
  view.setUint32(16, 16, true)
  view.setUint16(20, 1, true)
  // stereo (2 channels)
  view.setUint16(22, numChannels, true)
  view.setUint32(24, sampleRate, true)
  view.setUint32(28, sampleRate * 4, true)
  view.setUint16(32, numChannels * 2, true)
  view.setUint16(34, 16, true)
  // data sub-chunk
  writeUTFBytes(view, 36, 'data')
  view.setUint32(40, interleaved.length * 2, true)

  // write the PCM samples
  const lng = interleaved.length
  let index = 44
  const volume = 1
  for (let i = 0; i < lng; i++) {
    view.setInt16(index, interleaved[i] * (0x7fff * volume), true)
    index += 2
  }

  // our final binary blob
  return new Blob([view], { type: 'audio/wav' })
}
