import { Box, type BoxProps } from '@mui/material'
import { UserAvatar, useCurrentUser } from '@tunasong/ui-lib'
import { type CoreElement, type Comment } from '@tunasong/schemas'
import { type CompleteEvent, Editor, type EditorEvent, Transforms } from '@tunasong/plugin-lib'
import { type FC, useCallback, useMemo } from 'react'
import { ChatEditor } from '../editor/chat-editor.js'

export interface ChatBoxProps extends Omit<BoxProps, 'onChange'> {
  onChange: (comment: Comment) => void
  onComplete: (event: EditorEvent) => void
  size?: 'small' | 'medium' | 'large'
}

export const ChatBox: FC<ChatBoxProps> = props => {
  const { onChange, onComplete, size = 'medium', ...restProps } = props

  const { userId = 'unknown' } = useCurrentUser()
  const chatElement = useMemo(
    () =>
      ({
        type: 'comment',
        userId,
        children: [{ type: 'line', children: [{ text: '' }] }],
      } satisfies Partial<CoreElement<Comment>>),
    [userId]
  )

  const handleComplete = useCallback(
    (event: EditorEvent) => {
      const { editor } = event.detail as CompleteEvent

      /** Clear the editor */
      Editor.withoutNormalizing(editor, () => {
        const at = {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        }
        Transforms.delete(editor, { at })
        Transforms.setNodes(editor, { type: 'line', children: [{ text: '' }] })
      })

      onComplete(event)
    },
    [onComplete]
  )
  const fontSize = size === 'medium' ? 16 : size === 'small' ? 14 : 18

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 1,
        my: 1,
        borderStyle: 'solid',
        borderColor: theme => theme.palette.divider,
        borderWidth: 1,
        borderRadius: 1,
        ...restProps,
      }}
    >
      <UserAvatar size={size} />
      <ChatEditor
        sx={{
          flex: 1,
          flexDirection: 'column',
          fontSize,

          padding: theme => theme.spacing(0, 1, 0, 1),
          display: 'flex',
          justifyContent: 'center',
        }}
        element={chatElement}
        autoFocus={true}
        onChange={onChange}
        onComplete={handleComplete}
      />
    </Box>
  )
}
