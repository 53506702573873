import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  type FormControlProps,
  type SelectChangeEvent,
} from '@mui/material'
import { type FC, useCallback, useEffect, useState, useMemo } from 'react'
import { getDevices } from './devices.js'
import { logger } from '@tunasong/models'

export interface InputSelectProps extends Omit<FormControlProps, 'onChange'> {
  className?: string
  type: MediaDeviceKind
  selectedId?: string | null
  onChange(selected: MediaDeviceInfo): void
}

export const InputSelect: FC<InputSelectProps> = props => {
  const { onChange, selectedId = 'default', type, ...formControlProps } = props

  const [devices, setDevices] = useState<MediaDeviceInfo[] | null>(null)
  useEffect(() => {
    getDevices(type).then(devices => {
      logger.debug('Input devices available', devices)
      setDevices(devices)
    })
  }, [type])

  const handleChange = useCallback(
    (event: SelectChangeEvent<string | null>) => {
      const val = event.target.value as string
      const d = devices?.find(item => item.deviceId === val)
      if (d) {
        onChange(d)
      }
    },
    [devices, onChange]
  )

  const label = type === 'audioinput' ? 'Audio Input' : 'Video Input'

  const validDevices = useMemo(() => devices?.filter(d => d.deviceId), [devices])

  if (!validDevices || validDevices.length === 0) {
    return (
      <Typography variant="caption" color="error">
        No {label} devices found
      </Typography>
    )
  }

  return (
    <FormControl fullWidth={true} {...formControlProps} sx={{ mt: 1 }}>
      <InputLabel htmlFor={`${type}-select`}>{label}</InputLabel>
      <Select
        fullWidth={true}
        label={label}
        inputProps={{
          id: `${type}-select`,
        }}
        value={selectedId}
        onChange={handleChange}
      >
        {validDevices.map(device => (
          <MenuItem key={device.deviceId} value={device.deviceId}>
            {device.deviceId === 'default' ? 'Default' : device.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default InputSelect
