import { logger } from '@tunasong/models'

export const indexeddbCacheName = (entityId: string) => entityId

export const clearIndexedDBCache = (entityId: string) => {
  const indexedDB = window.indexedDB
  const request = indexedDB.deleteDatabase(indexeddbCacheName(entityId))
  request.onsuccess = () => {
    logger.debug('Deleted database successfully')
  }
  request.onerror = () => {
    logger.error("Couldn't delete database")
  }
  request.onblocked = () => {
    logger.warn("Couldn't delete database due to the operation being blocked")
  }
}
