/* eslint-disable */

/*
    Weblature

    Javascript library for chords and tablature.
    (for fretted string instruments)


    Copyright (C) 2013 by Rune Lillesveen

    Permission is hereby sgranted, free of charge, to any person obtaining a copy
    of this software and associated documentation files (the "Software"), to deal
    in the Software without restriction, including without limitation the rights
    to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
    copies of the Software, and to permit persons to whom the Software is
    furnished to do so, subject to the following conditions:

    The above copyright notice and this permission notice shall be included in
    all copies or substantial portions of the Software.

    THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
    IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
    FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
    AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
    LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
    OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
    THE SOFTWARE.
*/

/* Weblature namespace. */

type Note =
  | 'C'
  | 'C#'
  | 'Db'
  | 'D'
  | 'D#'
  | 'Eb'
  | 'E'
  | 'F'
  | 'F#'
  | 'Gb'
  | 'G'
  | 'G#'
  | 'Ab'
  | 'A'
  | 'A#'
  | 'Bb'
  | 'B'

export class Weblature {
  constructor() {}

  /* Utility function for (a div b). */

  static div(a: number, b: number) {
    return Math.floor(a / b)
  }

  /* Mapping from note string to semitone index. */

  static C = 0
  static 'C#' = 1
  static Db = 1
  static D = 2
  static 'D#' = 3
  static Eb = 3
  static E = 4
  static F = 5
  static 'F#' = 6
  static Gb = 6
  static G = 7
  static 'G#' = 8
  static Ab = 8
  static A = 9
  static 'A#' = 10
  static Bb = 10
  static B = 11

  /* Return the semitone number for a named note in a given octave.

   noteString - one of the strings in the mapping above. Case-sensitive.
   octave - integer, 0 or positive, to indicate octave. */

  static note(noteString: Note, octave: number) {
    return Weblature[noteString] + octave * 12
  }
  /* Return the string representation of a semitone value created by the Note
   function above.

   note - the semitone value.
   includeOctave - add the octave number to the string. For instance "E4"
                   instead of simply "E".
   flat - If true, choose a flat name in favour of a sharp.
          E.g. Ab instead of G#. */

  static noteString(note: number, includeOctave = false, flat = false) {
    var idx = note % 12
    var leastF = idx >= 5
    var sharpOrFlat = (idx % 2 == 0) == leastF
    var offset = Weblature.div(idx, 2)

    if ((sharpOrFlat && flat) || (!sharpOrFlat && leastF)) offset++

    var retStr = String.fromCharCode('A'.charCodeAt(0) + ((offset + 2) % 7))

    if (sharpOrFlat) retStr += flat ? 'b' : '#'

    if (includeOctave == true) retStr += Weblature.div(note, 12)

    return retStr
  }
}

export default Weblature
