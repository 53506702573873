import { Editor, type TunaEditor } from '@tunasong/plugin-lib'
import { Node } from 'slate'
import { RangeQueries } from './range.js'

export const find = (editor: TunaEditor, match: (e?: Node) => boolean, at = RangeQueries.all(editor)) => {
  const nodes = Editor.nodes(editor, {
    at,
    match,
  })
  return Array.from(nodes)
}

export const EntityQueries = { find }
