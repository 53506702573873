import { features, type RootState, useThunkDispatch } from '@tunasong/redux'
import { useCallback, useEffect, useRef } from 'react'
import { useStore } from 'react-redux'
import { VideoConnection } from './lib/connection.js'
import { logger } from '@tunasong/models'

/** Connection pool will keep track of the WebRTC connections */
export interface UseConnectionPoolProps {
  stream: MediaStream | null
}
export const useConnectionPool = ({ stream }: UseConnectionPoolProps) => {
  const dispatch = useThunkDispatch()
  const { getState } = useStore<RootState>()

  const streamRef = useRef(stream)

  /** We don't useSelector here to avoid re-evaluating hooks that require getConnections when a new connection occurs */
  const getConnections = useCallback(
    () => Object.values(getState().webrtc.connections).filter(Boolean) as VideoConnection[],
    [getState]
  )

  const addConnection = useCallback(
    (connection: VideoConnection) => {
      dispatch(features.webrtc.actions.addConnection({ connection }))
    },
    [dispatch]
  )

  const removeConnection = useCallback(
    (peerClientId: string) => {
      dispatch(features.webrtc.actions.removeConnection({ peerClientId }))
    },
    [dispatch]
  )

  const getConnection = useCallback(
    (peerClientId: string) => getState().webrtc.connections[peerClientId] as VideoConnection | undefined,
    [getState]
  )

  const hasConnection = useCallback((peerClientId: string) => Boolean(getConnection(peerClientId)), [getConnection])

  useEffect(() => {
    const currentStream = streamRef.current
    streamRef.current = stream
    if (stream === currentStream) {
      return
    }
    if (!stream) {
      return
    }

    /** New stream - update all the connections */
    logger.info('*** Updating stream in connections', stream)
    const conns = getConnections()

    conns.forEach(c => {
      c.replaceAudioTrack(stream.getAudioTracks()[0])
    })
    /** @todo Need to update this to  support multiple peer streams */
    conns.forEach(c => {
      c.replaceVideoTrack(stream.getVideoTracks()[0])
    })
  }, [stream, getConnections])

  return { addConnection, removeConnection, getConnection, hasConnection, getConnections }
}
