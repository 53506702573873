import { Button, FormLabel, TextField, Typography } from '@mui/material'
import { getImageMimeTypes, usePlugins } from '@tunasong/plugin-lib'
import { features, OAuth, profilesApi, useSelector, useThunkDispatch } from '@tunasong/redux'
import { type Entity, type Persisted, type Profile } from '@tunasong/schemas'
import { DropZone, HBox, UserAvatar, VBox } from '@tunasong/ui-lib'
import React, { useCallback, type FC } from 'react'

export interface AccountProps {
  className?: string
  profile: Persisted<Profile>
}

export const Account: FC<AccountProps> = props => {
  const { profile } = props

  const dispatch = useThunkDispatch()
  const user = useSelector(state => state.user)

  const [updateProfile] = profilesApi.useUpdateProfileMutation()

  const logout = useCallback(() => {
    OAuth.logout()
    dispatch(features.user.actions.signout())
  }, [dispatch])

  const imageMimeTypes = getImageMimeTypes(usePlugins() ?? [])

  if (!profile) {
    return null
  }

  const quotaMB = (profile.quotaUsed ?? 0) / (1024 * 1024)
  const quotaGB = quotaMB / 1024
  const quotaLabel = `${quotaGB > 1 ? Math.round(quotaGB) + ' GB' : Math.round(quotaMB) + ' MB'}`

  const updateProfileName = (ev: React.FocusEvent<HTMLInputElement>) => {
    updateProfile({ id: profile.id, profile: { name: ev.target.value } })
  }
  const handleUpdatePicture = (picture: Persisted<Entity>) => {
    // Update the profile picture. This link will expire, but the server profile API will overwrite it.
    updateProfile({ id: profile.id, profile: { picture: picture.storageUrls?.url } })
  }

  const handleUploading = () => {
    // Update the profile picture. This link will expire, but the server profile API will overwrite it.
    updateProfile({ id: profile.id, profile: { picture: null } })
  }

  return (
    <VBox>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Profile
      </Typography>

      <HBox gap={2}>
        <DropZone
          parentId={profile.id}
          accept={imageMimeTypes}
          onUploaded={handleUpdatePicture}
          onUploading={handleUploading}
          uploadOnClick
        >
          <UserAvatar userId={user.userId} size="large" aria-label="user" />
        </DropZone>
        <TextField
          label="My name"
          defaultValue={profile.name}
          variant="standard"
          fullWidth
          onBlur={updateProfileName}
        />
      </HBox>

      <Typography variant="h6" sx={{ mt: 2 }}>
        Account
      </Typography>

      <FormLabel component="legend">E-mail</FormLabel>
      <Typography>{user.email} </Typography>
      <Typography variant="caption">{user.userId}</Typography>
      <FormLabel sx={{ mt: 1 }} component="legend">
        Identity Provider
      </FormLabel>
      <Typography> {user.authProvider}</Typography>

      <Typography variant="h6">Quota</Typography>
      <Typography variant="body2">You uploaded {quotaLabel} content.</Typography>

      <Typography variant="h6" sx={{ my: 2 }}>
        Account
      </Typography>

      <Button size="small" variant="outlined" color="inherit" onClick={logout}>
        Sign out
      </Button>
    </VBox>
  )
}

export default Account
