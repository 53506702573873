import {
  type MidiNoteEvent,
  noteOffMessage,
  noteOnMessage,
  Soundfont,
  useAudioEngine,
  useInstrument,
  useMidi,
} from '@tunasong/audio-ui'
import { useCallback, useMemo } from 'react'
import { type KeyboardNoteInfo } from './keyboard/keyboard.js'

/** Hook to provide MIDI integration for keyboards */
export const useMidiKeyboard = () => {
  const engine = useAudioEngine()

  const baseInstrument = useMemo(
    () => new Soundfont(engine.mixer.context, 'acoustic_grand_piano'),
    [engine.mixer.context]
  )
  useInstrument('base', baseInstrument)

  const onEvent = useCallback(
    (event: MidiNoteEvent) => {
      engine.playMidiEvent(event)
    },
    [engine]
  )
  const onPlayNote = useCallback(
    ({ midiNumber }: KeyboardNoteInfo) => {
      const msg = noteOnMessage(midiNumber, 0.8)
      engine.playMidiEvent(msg)
    },
    [engine]
  )

  const onStopNote = useCallback(
    ({ midiNumber }: KeyboardNoteInfo) => {
      const msg = noteOffMessage(midiNumber)
      engine.playMidiEvent(msg)
    },
    [engine]
  )

  const { activeNotes } = useMidi({ onNoteEvent: onEvent })

  return { onPlayNote, onStopNote, activeNotes }
}
