import { List } from '@mui/material'
import { type FC } from 'react'
import MidiIOItem from './midi-io-item.js'
import type { MidiInput, MidiOutput } from './midi-types.js'

export interface MidiIOListProps {
  type: 'input' | 'output'
  items: (MidiInput | MidiOutput)[]
}

export const MidiIOList: FC<MidiIOListProps> = props => {
  const { items, type } = props

  return (
    <List>
      {items.map((input, idx) => (
        <MidiIOItem key={idx} item={input} type={type} />
      ))}
    </List>
  )
}

export default MidiIOList
