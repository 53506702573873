/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from '@tunasong/ui-lib'

export default makeStyles()(theme => ({
  root: {},

  label: {
    fontSize: 10,
    userSelect: 'none',
  },
  badge: {
    fontSize: 10,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  marker: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  markerText: {
    color: theme.palette.secondary.contrastText,
  },
  loop: {
    '&:hover': {
      cursor: 'ew-resize',
    },
  },
  loopHandle: {
    cursor: 'pointer',
  },
}))
