/** Retrieve a registered Editor class from the context  */

import { useContext } from 'react'
import { type EditorType } from '../editor-type.js'
import { EditorsContext } from '../editor/editors-context.js'

export const useEditorClass = (name: EditorType) => {
  const editors = useContext(EditorsContext)
  const editorClass = editors?.[name]
  if (!editorClass) {
    throw new Error(`Editor ${name} not registered or EditorsContext is null`)
  }
  return editorClass
}
