import { Box } from '@mui/material'
import Midi from '@tonaljs/midi'
import { isBoolean } from '@tunasong/models'
import { NoteLib, ScaleLib } from '@tunasong/music-lib'
import { type FC } from 'react'
import { Note } from '../../note/note.js'
import type { NoteLabelProps, NoteLabelType } from './keyboard.js'
import type { NoteName, Scale } from '@tunasong/schemas'

export const NoteLabel: FC<NoteLabelProps> = props => {
  const { note: providedNote, isAccidental, isActive, scale, label } = props
  const note = NoteLib.toNote(providedNote)

  const inScale = scale ? ScaleLib.noteInScale(scale, note) : undefined
  const renderDot = isBoolean(inScale) && label === 'note-scale'

  const renderLabel = label && label !== 'none'

  return renderLabel ? (
    <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
      {renderDot ? (
        <Box
          sx={{
            height: 16,
            width: 16,
            backgroundColor: theme =>
              inScale === true ? theme.notes?.inScale : inScale === false ? theme.notes?.outOfScale : theme.notes?.main,
            borderRadius: '50%',
            alignSelf: 'center',
            my: 1,
          }}
        />
      ) : null}
      <Note
        note={note}
        scale={scale}
        sx={{ fontSize: '0.8rem', color: isAccidental && !isActive ? 'white' : 'black' }}
      />
    </Box>
  ) : null
}

/** HOC for a scale */
export const NoteLabelForScale =
  (scale?: Scale | null, label: NoteLabelType = 'note-scale', showLabelOnActiveOnly = false) =>
  (props: NoteLabelProps) => {
    const { midiNumber, octave, isActive } = props
    const noteName = Midi.midiToNoteName(midiNumber, { pitchClass: true }) as NoteName

    const note = scale ? ScaleLib.getScaleNoteName(scale, noteName) : noteName

    if (!isActive && showLabelOnActiveOnly) {
      return null
    }
    /** Is pitchName in the scale? */
    return <NoteLabel {...props} note={note} scale={scale} octave={octave} label={label} />
  }
