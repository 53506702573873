import { useTheme } from '@mui/material'
import { isChordEvent, isMarkerEvent } from '@tunasong/schemas'
import { getColor } from '@tunasong/ui-lib'
import cn from 'classnames'
import { type MouseEvent, useCallback, useState } from 'react'
import { type RendererPlugin } from '../renderer.js'
import useStyles from './styles.js'

const Marker: RendererPlugin = ({ laneHeight, event, pixelsPerSecond, onEventClick }) => {
  const theme = useTheme()
  const { classes } = useStyles()
  const [textRef, setTextRef] = useState<SVGTextElement | null>(null)

  const handleEventClick = useCallback(
    (ev: MouseEvent) => {
      if (!onEventClick) {
        return
      }
      onEventClick(ev, event)
    },
    [event, onEventClick]
  )

  if (!(isMarkerEvent(event) || isChordEvent(event))) {
    return null
  }

  const { color } = event
  const fill = color ? getColor(color)[200] : theme.palette.secondary.main
  const textColor = theme.palette.getContrastText(fill)

  const labelPadding = 4

  const label = event.name
  const x = event.start * pixelsPerSecond
  const y = laneHeight * 3

  if (Number.isNaN(x) || Number.isNaN(y)) {
    return null
  }

  const rectY = laneHeight * 2
  const textX = x + labelPadding
  const textY = y - labelPadding

  const bbox = textRef?.getBBox() ?? { width: 0, height: 0 }
  const rectWidth = bbox.width + 2 * labelPadding
  const rectHeight = bbox.height + 2 * labelPadding

  /** We want to have a label on  */
  return (
    <g onClick={handleEventClick} className={classes.marker}>
      <title>{label}</title>
      <rect fill={fill} x={x} y={rectY} width={rectWidth} height={rectHeight} />
      <text ref={setTextRef} x={textX} y={textY} fill={textColor} className={cn(classes.label, classes.markerText)}>
        {label}
      </text>
    </g>
  )
}

export default Marker
