import { Card, CardContent, CardHeader, Typography } from '@mui/material'
import type { Template, TunaPlugin } from '@tunasong/plugin-lib'
import type { EntityType } from '@tunasong/schemas'
import { EntityAvatar } from '@tunasong/ui-lib'
import { type FC } from 'react'

interface CreateEntityCardsProps {
  plugin: TunaPlugin
  title: string
  description: string
  template?: Template
  selected?: boolean
  onSelect: () => void
}

/** Create one or more cards from a plugin */
export const TemplateCard: FC<CreateEntityCardsProps> = props => {
  const { onSelect, title, description, selected, plugin, template } = props

  const { template: templateContent } = template ?? {}

  const header = <CardHeader avatar={<EntityAvatar type={plugin.type as EntityType} />} title={title} />

  if (!templateContent) {
    return null
  }

  return (
    <Card
      onClick={onSelect}
      sx={{
        borderColor: theme => (selected ? theme.vars.palette.primary.dark : 'inherit'),
        borderWidth: selected ? 2 : 0,
        borderStyle: 'solid',
        cursor: 'pointer',
      }}
    >
      {header}

      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default TemplateCard
