import { Box, useTheme, type BoxProps } from '@mui/material'
import { type AudioPeaks } from '@tunasong/models'
import { useContainerSize } from '@tunasong/ui-lib'
import React, { useEffect, useMemo, useRef, type ElementRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { drawOverlay, drawWaveform } from './wave-canvas-draw.js'
import { getWaveGradient } from './wave-gradient.js'

/** Wave render using a Canvas */
interface WaveProps extends Omit<BoxProps, 'position'> {
  className?: string
  /** `fixed`: render each peak one time. `dynamic` fill the available box, calculate peak characteristics  */
  mode?: 'fixed' | 'dynamic'
  peaks?: AudioPeaks
  badge?: string | null
  /** Position in the wave, from 0.0 to 1.0 */
  position?: number
  barWidth?: number
  gapWidth?: number
  barColor?: string
  waveType?: 'audio' | 'monitor'
}

export const WaveCanvas = React.forwardRef((props: WaveProps, rootRef: React.Ref<HTMLDivElement>) => {
  const { className, peaks, barWidth = 5, gapWidth = 2, badge, waveType = 'audio', position = 0, ...restProps } = props
  const theme = useTheme()
  const canvasRef = useRef<ElementRef<'canvas'>>(null)
  const ref = mergeRefs<HTMLElement>([rootRef, canvasRef])
  const containerRef = useRef<ElementRef<'div'>>(null)

  const size = useContainerSize(containerRef.current)

  const waveGradient = useMemo(() => getWaveGradient({ theme, type: waveType }), [theme, waveType])

  const lastPosRef = useRef(0)
  useEffect(() => {
    const canvas = canvasRef.current

    if (!(canvas && peaks && size?.height && size?.width)) {
      return
    }
    const id = requestAnimationFrame(() => {
      if (position < lastPosRef.current || lastPosRef.current === 0) {
        drawWaveform({
          canvas,
          barWidth,
          gapWidth,
          linePercent: 0.5,
          waveGradient,
          maxVal: 1,
          dataSamples: Array.from(peaks.data),
          height: size.height,
          width: size.width,
        })
      }
      drawOverlay({
        from: 0,
        to: position,
        fill: theme.palette.success.light,
        canvas,
      })
      lastPosRef.current = position
    })
    return () => cancelAnimationFrame(id)
  }, [barWidth, gapWidth, peaks, position, size?.height, size?.width, theme, waveGradient])

  return (
    <Box className={className} sx={{ overflow: 'hidden' }} {...restProps} ref={containerRef}>
      {badge ? (
        <Box
          sx={{
            display: 'block',
            fontSize: 11,
            padding: theme.spacing(0, 1, 0, 1),
            position: 'absolute',
            zIndex: 1,
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          {badge}
        </Box>
      ) : null}
      <canvas ref={ref} style={{ width: '100%', height: '100%' }} />
    </Box>
  )
})

export default WaveCanvas
