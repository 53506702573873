import { Box, Divider, Typography } from '@mui/material'
import { capitalize } from '@tunasong/models'
import { getPlugin, usePlugins } from '@tunasong/plugin-lib'
import { ElementForm, type SidebarComponent } from '@tunasong/ui-lib'
import { type FC } from 'react'

export const SidebarSettings: FC<SidebarComponent> = ({ entity, onChange }) => {
  const plugins = usePlugins('all')
  const EntityProperties = entity ? getPlugin(entity, plugins)?.components?.Properties : null

  return (
    <Box sx={{ overflow: 'auto' }}>
      <ElementForm element={entity} onChange={onChange} />
      {EntityProperties ? (
        <>
          <Divider sx={{ my: 1 }}>
            <Typography variant="caption">{capitalize(entity.type)} properties</Typography>
          </Divider>
          <EntityProperties element={entity} onChange={onChange} />
        </>
      ) : null}
    </Box>
  )
}
