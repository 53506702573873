import React from 'react'
import { type AudioPeaks } from '@tunasong/models'

/**
 * adapted from https://github.com/kenhoff/winds-waveform-example/blob/master/index.html
 */

export interface GenerateBarsParams {
  peaks?: AudioPeaks
  /** Number of buckets / bars. @default 100 */
  numBuckets?: number
  // from 0 (no gaps between bars) to 1 (only gaps - bars won't be visible)
  spacing?: number
}

/** Sample a region in the peaks data */
export const sampleBucket = (samples: Float32Array | number[], bucket: number, bucketSize: number) => {
  const start = Math.floor(bucket * bucketSize)
  const end = Math.floor(start + bucketSize)
  let sum = 0
  for (let i = start; i < end; i++) {
    sum = sum + Math.abs(samples[i])
  }
  const val = sum / (end - start)
  return Number.isNaN(val) ? 0 : val
}

/** Generate the bars from peaks */
export interface GenerateBars {
  peaks: AudioPeaks
  numBuckets: number
  spacing?: number
}
export const generateBars = ({ peaks, numBuckets, spacing = 0.2 }: GenerateBars) => {
  const peakData = peaks?.data ?? []
  const bucketSize = peakData.length / numBuckets
  const buckets: number[] = []
  for (let i = 0; i < numBuckets; i++) {
    buckets.push(sampleBucket(peakData, i, bucketSize))
  }

  const bucketSVGWidth = buckets.length > 0 ? 100.0 / buckets.length : 0
  return buckets.map((bucket, i) => {
    const bucketSVGHeight = bucket * 100.0
    return (
      <rect
        key={i}
        x={bucketSVGWidth * i + spacing / 2.0}
        y={(100 - bucketSVGHeight) / 2.0}
        width={bucketSVGWidth - spacing}
        height={bucketSVGHeight}
      />
    )
  })
}
