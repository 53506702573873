import { useColorScheme, useTheme } from '@mui/material'
import { GuitarLib } from '@tunasong/music-lib'
import { makeStyles } from '@tunasong/ui-lib'
import classNames from 'classnames'
import React, { type FC, useCallback, useEffect, useState } from 'react'

export interface FretboardProps {
  className?: string

  /** Specify start and end frets. If not specified, fingerings will be used */
  start?: number
  end?: number

  watermark?: boolean

  /** Fret fingerings */
  fingerings?: GuitarLib.FingeringWithMetadata[]

  /** Callbacks */
  onSelectFingering?(fingering: GuitarLib.Fingering): void
}

const useStyles = makeStyles()(() => ({
  root: {
    flex: 1,
    width: '100%',
    height: 150,
  },
}))

interface Dimensions {
  canvas: HTMLCanvasElement
  pixelRatio: number
  height: number
  width: number
  fingerRadius: number
  fretSpacing: number
  nutWidth: number
  stringSpacing: number
  inlayRadius: number
}

export const Fretboard: FC<FretboardProps> = props => {
  const { className, fingerings = [], onSelectFingering, watermark = true } = props
  const { colorScheme } = useColorScheme()
  const { classes } = useStyles()
  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  const [startFret] = useState(1)
  const [endFret] = useState(12)
  const theme = useTheme()

  /** Constants */
  const outlineStyle = theme.vars.palette.grey[100]

  const lineWidth = 4
  const fretLineWidth = 2
  const fretStyle = theme.vars.palette.grey[500]
  const numStrings = 6
  const nutWidth = 15
  const nutStyle = theme.vars.palette.grey[500]

  const inlayStyle = colorScheme === 'light' ? theme.vars.palette.grey[200] : theme.vars.palette.grey[700]
  const stringWidth = 2
  const stringPadding = 8
  const stringStyle = theme.vars.palette.grey[700]

  const fingeringStyle = theme.notes?.main
  const fingeringStyleInScale = theme.notes?.inScale
  const fingeringStyleOutOfScale = theme.notes?.outOfScale

  const numFrets = endFret - startFret
  /** End constants */

  const getDimensions = useCallback((): Dimensions | null => {
    const canvas = canvasRef.current
    if (!canvas) {
      return null
    }
    const pixelRatio = window.devicePixelRatio || 1
    canvas.height = canvas.clientHeight * pixelRatio
    canvas.width = canvas.clientWidth * pixelRatio

    const width = canvas.offsetWidth
    const height = canvas.offsetHeight

    /** Find the start and end frets. If they are defined used those */
    // const startFret = start ? start : Math.min.apply(null, fingerings.map(f => f.fret)) || 1
    // const endFret = end ? end : Math.max.apply(null, fingerings.map(f => f.fret)) || 22
    // setStartFret(startFret)
    // setEndFret(endFret)

    return {
      canvas,
      pixelRatio,
      width,
      height,
      fingerRadius: 8,
      nutWidth,
      fretSpacing: (width - nutWidth) / numFrets,
      inlayRadius: 6,
      stringSpacing: (height - 2 * stringPadding) / (numStrings - 1),
    }
  }, [numFrets, stringPadding])

  const draw = useCallback(() => {
    const dimensions = getDimensions()
    if (!dimensions) {
      return
    }
    const { canvas, pixelRatio, width, height, inlayRadius, fretSpacing, stringSpacing, fingerRadius, nutWidth } =
      dimensions
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      return
    }
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    const fontSize = 12
    ctx.font = `${fontSize}px`

    /**
     * Get the starting X for a fret area. There is an area of size fretSpacing **before** the fret.
     */
    const getFretX = (fret: number) => nutWidth + (fret - startFret + 1) * fretSpacing

    const drawMute = (str: number) => {
      ctx.beginPath()
      const y = stringPadding + stringSpacing * (str - 1)
      const label = 'X'
      const size = 20
      ctx.font = `bold ${size}px courier`
      ctx.fillStyle = theme.vars.palette.grey[900]
      ctx.fillText(label, -2, y + size / 3.5)
    }

    /** Use e.g., 2.5 to draw between strings */
    const drawDot = (fret: number, str: number, radius: number, style: string) => {
      ctx.beginPath()
      /** The dot is halfway in the area before the fret */
      const x = getFretX(fret) - fretSpacing / 2
      const y = stringPadding + stringSpacing * (str - 1)
      ctx.fillStyle = style
      ctx.moveTo(x, y)
      ctx.arc(x, y, radius, 0, Math.PI * 2)
      ctx.fill()
    }

    /** Draw fretboard box, including nut */
    ctx.beginPath()
    ctx.moveTo(0, 0)
    ctx.strokeStyle = outlineStyle
    ctx.lineWidth = lineWidth
    ctx.strokeRect(0, 0, width, height)
    ctx.stroke()

    /** Nut */
    ctx.beginPath()
    ctx.lineWidth = nutWidth * pixelRatio
    ctx.strokeStyle = nutStyle
    ctx.moveTo(0, 0)
    ctx.lineTo(0, height)
    ctx.stroke()

    /** Set the line width for the frets */
    ctx.beginPath()
    ctx.lineWidth = fretLineWidth
    ctx.strokeStyle = fretStyle

    for (let fret = startFret; fret <= endFret; fret++) {
      /**
       * Fret position is nutWidth + fretSpacing times the frets,
       * We need to add an initial spacing for the first displayed fret
       */
      const x = getFretX(fret)
      const y = height

      /** Watermark */
      if (watermark) {
        const label = `${fret}`
        ctx.fillStyle = theme.vars.palette.grey[300]
        const labelSize = ctx.measureText(label)
        ctx.fillText(label, x - fretSpacing / 2 - labelSize.width / 2, y / 2 + fontSize / 3)
      }

      /** @todo debugging  */
      ctx.strokeStyle = theme.vars.palette.text.primary

      ctx.moveTo(x, 0)
      ctx.lineTo(x, y)
      ctx.stroke()
    }

    /** Strings */
    ctx.beginPath()
    ctx.lineWidth = stringWidth
    ctx.strokeStyle = stringStyle
    for (let str = 0; str < numStrings; str++) {
      const y = stringPadding + stringSpacing * str
      ctx.moveTo(0, y)
      ctx.lineTo(width, y)
      ctx.stroke()
    }

    /** Inlays */
    const inlays = [3, 5, 7, 10, 15, 17, 19, 22]
    ctx.beginPath()
    ctx.lineWidth = fretLineWidth
    for (const fret of inlays) {
      drawDot(fret, 3.5, inlayRadius, inlayStyle)
    }
    const doubleInlays = [12, 24]
    for (const fret of doubleInlays) {
      drawDot(fret, 2.5, inlayRadius, inlayStyle)
      drawDot(fret, 4.5, inlayRadius, inlayStyle)
    }

    /** Fingerings */

    for (const { fret, str, inScale } of fingerings) {
      if (fret === GuitarLib.MUTED_STRING) {
        drawMute(str)
      } else if (fret === GuitarLib.OPEN_STRING) {
        /** No-op */
      } else {
        const color =
          inScale === true ? fingeringStyleInScale : inScale === false ? fingeringStyleOutOfScale : fingeringStyle

        drawDot(fret, str, fingerRadius, color)
      }
    }
  }, [
    endFret,
    fingeringStyle,
    fingeringStyleInScale,
    fingeringStyleOutOfScale,
    fingerings,
    fretStyle,
    getDimensions,
    inlayStyle,
    nutStyle,
    outlineStyle,
    startFret,
    stringStyle,
    theme.vars.palette.grey,
    theme.vars.palette.text.primary,
    watermark,
  ])

  const handleClick = useCallback(
    (ev: React.MouseEvent) => {
      const canvas = canvasRef.current as HTMLCanvasElement
      const dimensions = getDimensions()
      if (!(canvas && dimensions)) {
        return
      }

      const rect = canvas.getBoundingClientRect()
      const x = ev.pageX - rect.left
      const y = ev.pageY - rect.top

      /** @todo this will not work unless displaying the whole fingerboard */
      const fret = x <= nutWidth ? -1 : Math.floor((x - nutWidth) / dimensions.fretSpacing) + startFret

      const str = Math.round((y + stringPadding) / dimensions.stringSpacing + 0.5) as GuitarLib.StringNumber

      if (onSelectFingering) {
        onSelectFingering({ fret, str })
      }
      draw()
    },
    [draw, getDimensions, onSelectFingering, startFret]
  )

  useEffect(() => {
    /** Redraw on resize */
    const redraw = () => draw()
    window.addEventListener('resize', redraw)

    /** Unsubscribe on unmount */
    return () => {
      window.removeEventListener('resize', redraw)
    }
  })

  useEffect(() => draw(), [canvasRef, draw, fingerings])

  return <canvas className={classNames(className, classes.root)} ref={canvasRef} onClick={handleClick} />
}

export default Fretboard
