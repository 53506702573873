import type { IAudioContext } from 'standardized-audio-context'

export const createLimiter = (context: IAudioContext) => {
  /** Hard limiter to avoid digital distortion */
  const limiter = context.createDynamicsCompressor()
  limiter.threshold.value = -1.0 // this is the pitfall, leave some headroom
  limiter.knee.value = 0.0 // brute force
  limiter.ratio.value = 20.0 // max compression
  limiter.attack.value = 0.005 // 5ms attack
  limiter.release.value = 0.05 // 50ms release

  return limiter
}
