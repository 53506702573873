import { Avatar } from '@mui/material'
import { type FeedItem } from '@tunasong/models'
import { useProfile } from '@tunasong/redux'
import { UserAvatar, getProfileColor, useCurrentUser } from '@tunasong/ui-lib'
import { forwardRef } from 'react'
import ChatItem from './chat-item.js'

export interface ChatFeedItemProps {
  item: FeedItem
  highlight?: boolean
}

const avatarSx = {
  '& > *': {
    margin: 0.5,
  },
}
const userAvatarWithUser = (userId: string) => () => <UserAvatar sx={avatarSx} userId={userId} size="small" />
const DummyAvatar = () => <Avatar sx={avatarSx} />

export const ChatFeedItem = forwardRef<HTMLDivElement, ChatFeedItemProps>((props, ref) => {
  const { item, highlight = false, ...restProps } = props
  const itemUserId = item.userId
  const { userId: currentUserId } = useCurrentUser()

  const profile = useProfile(item.userId)

  const UserIcon = itemUserId ? userAvatarWithUser(itemUserId) : DummyAvatar
  const type = currentUserId === item.userId ? 'message' : item.userId === 'assistant' ? 'assistant' : 'response'
  const { colorName } = getProfileColor(profile)

  return (
    <ChatItem
      key={item.id}
      {...restProps}
      ref={ref}
      highlight={highlight}
      type={type}
      color={colorName}
      name={profile?.name ?? 'Unknown'}
      actions={item.actions}
      UserIcon={UserIcon}
      timestamp={item.createdAt}
    >
      {item.content}
    </ChatItem>
  )
})

export default ChatFeedItem
