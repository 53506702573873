import { EventEmitter } from '@tunasong/ui-lib'
import type { BtMidiOutputAdapter } from './output-adapter.js'

// Bluetooth MIDI Output Adapter
export class BtMidiOutputChannelAdapter extends EventEmitter {
  constructor(
    output: BtMidiOutputAdapter,
    channel: number,
    private midiChar: BluetoothRemoteGATTCharacteristic
  ) {
    super()
  }
}
