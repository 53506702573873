import { makeStyles } from '@tunasong/ui-lib'
import { type FC } from 'react'
import { type InstrumentEditorProps } from '../instrument-editor.props.js'

const useStyles = makeStyles()(() => ({
  root: {},
}))

const BassEditor: FC<InstrumentEditorProps> = props => {
  const {} = props
  const { classes } = useStyles()

  return <div className={classes.root}></div>
}

export default BassEditor
