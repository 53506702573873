import type { Midi } from '@tunasong/schemas'

// Create MIDI command objects from standard MIDI messages
export const MidiFactory = {
  programChange({
    program,
    patchBank,
    patchBankLsb,
    channel,
  }: {
    program: number
    patchBank?: number
    patchBankLsb?: number
    channel?: number
  }): Midi {
    return { type: 'midi', program, patchBank, patchBankLsb, midiType: 'ProgramChange', channel }
  },
  cc({ cc, value, channel }: { cc: number; value: number; channel?: number }): Midi {
    return { type: 'midi', cc, value, midiType: 'CC', channel }
  },
}
