import { type BoxProps, Button, Divider, LinearProgress, Typography } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { EntityEditor } from '@tunasong/editor'
import { dayjs, logger } from '@tunasong/models'
import { usePlugins } from '@tunasong/plugin-lib'
import { entitiesApi } from '@tunasong/redux'
import { type CoreElement, type Entity, type Persisted } from '@tunasong/schemas'
import { SyncHandler } from '@tunasong/sync-lib'
import { VBox } from '@tunasong/ui-lib'
import { Dayjs } from 'dayjs'
import { type FC, useCallback, useEffect, useMemo, useState } from 'react'
import invariant from 'tiny-invariant'

export interface TimeTravelSelectProps extends Omit<BoxProps, 'onChange'> {
  entity?: Persisted<Entity>
  disableRestore?: boolean
  onChange(spec: { entity: Persisted<Entity>; travelTo: string }): void
}

export const TimeTravelSelect: FC<TimeTravelSelectProps> = props => {
  const { entity, disableRestore, onChange, ...boxProps } = props

  const plugins = usePlugins('all')

  const [restoreTo, setRestoreTo] = useState<string | null>(null)
  const { currentData: travelDoc, isLoading } = entitiesApi.useTimeTravelQuery(
    { entityId: entity?.id ?? '', createdAtId: restoreTo ?? '' },
    { skip: !(entity && restoreTo) }
  )

  const [preview, setPreview] = useState<Persisted<Entity & CoreElement>>()

  useEffect(() => {
    const yDoc = travelDoc?.yDoc
    if (!(yDoc && restoreTo)) {
      return
    }

    /** Create a preview of the YDoc */
    const handler = SyncHandler.createFromYDocString({ entityId: travelDoc.id, yDoc })
    setPreview({
      ...travelDoc,
      children: handler.toJSON(),
    })

    logger.debug('Preview', travelDoc?.updatedAt, travelDoc)
  }, [onChange, restoreTo, travelDoc])

  const handleRestore = useCallback(() => {
    invariant(travelDoc && restoreTo)
    onChange({ entity: travelDoc, travelTo: restoreTo })
  }, [onChange, restoreTo, travelDoc])

  const createdAtDate = useMemo(() => dayjs(entity?.createdAt), [entity?.createdAt])

  const shouldDisableTime = useCallback(
    (date: Dayjs) => date.toISOString() < createdAtDate.toISOString(),
    [createdAtDate]
  )
  const shouldDisableDate = useCallback(
    (date: Dayjs) => date.endOf('day') < createdAtDate.endOf('day'),
    [createdAtDate]
  )
  const shouldDisableYear = useCallback(
    (date: Dayjs) => {
      if (!entity?.createdAt) {
        return true
      }
      const year = new Date(entity.createdAt).getFullYear()
      return year > date.year()
    },
    [entity?.createdAt]
  )

  const handleChange = useCallback((val: Dayjs | null) => {
    setRestoreTo(val ? val.toISOString() : null)
    setPreview(undefined)
  }, [])

  return entity ? (
    <VBox {...boxProps}>
      <DateTimePicker
        disableFuture={true}
        disableHighlightToday={true}
        shouldDisableDate={shouldDisableDate}
        shouldDisableYear={shouldDisableYear}
        shouldDisableTime={shouldDisableTime}
        onAccept={handleChange}
      />
      <Button disabled={!preview || disableRestore} sx={{ mt: 2 }} onClick={handleRestore}>
        Restore
      </Button>

      {preview && plugins ? (
        <>
          <Divider sx={{ mt: 2 }}>Preview</Divider>
          <EntityEditor key={preview.updatedAt} readOnly={true} element={preview} plugins={plugins} />
        </>
      ) : null}
      {isLoading ? <LinearProgress /> : null}
    </VBox>
  ) : (
    <Typography>No data</Typography>
  )
}

export default TimeTravelSelect
