import { Popper, Typography } from '@mui/material'
import { makeStyles, useDragMove } from '@tunasong/ui-lib'
import { formatNumber } from '@tunasong/models'
import cn from 'classnames'
import React, { type FC, useCallback, useRef, useState } from 'react'

const getLeftWidth = (pan = 0) => `${pan < 0 ? Math.abs(pan) * 100 : 0}%`
const getRightWidth = (pan = 0) => `${pan > 0 ? Math.abs(pan) * 100 : 0}%`
const getLabel = (pan = 0) => `${formatNumber(Math.abs(pan) * 100, { digits: 1 })} ${pan < 0 ? 'L' : 'R'}`

const useStyles = makeStyles<ChannelPanProps>()((theme, { pan }) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    backgroundColor: theme.vars.palette.action.hover,
    cursor: 'pointer',
    marginRight: theme.spacing(0.5),
    height: theme.spacing(0.5),
  },
  centerContainer: {
    width: theme.spacing(0.5),
    backgroundColor: theme.vars.palette.secondary.light,
    height: theme.spacing(0.5),
  },
  leftContainer: {
    flex: 1,
    transform: 'rotateY(180deg)',
  },
  leftPan: {
    width: getLeftWidth(pan),
    backgroundColor: theme.vars.palette.primary.light,
    height: theme.spacing(0.5),
  },
  rightContainer: {
    flex: 1,
  },
  rightPan: {
    width: getRightWidth(pan),
    backgroundColor: theme.vars.palette.primary.light,
    height: theme.spacing(0.5),
  },
}))

export interface ChannelPanProps {
  className?: string
  /** From -1 to 1, 0 is center */
  pan?: number
  onPan(pan: number): void
}

export const ChannelPan: FC<ChannelPanProps> = props => {
  const { className, pan = 0, onPan } = props
  const { classes } = useStyles(props)

  /** Dragging the volume bar? */
  const anchorEl = useRef<HTMLDivElement | null>(null)

  const handlePan = useCallback(
    (ev: MouseEvent) => {
      if (!anchorEl.current) {
        return
      }
      ev.preventDefault()
      const box = anchorEl.current.getBoundingClientRect()
      const pan = Math.max(0, Math.min(((ev.clientX - box.left) / box.width) * 2, 2)) - 1
      onPan(pan)
    },
    [onPan]
  )

  const handleStart = useCallback(
    (ev: MouseEvent) => {
      handlePan(ev)
      setShowPan(true)
    },
    [handlePan]
  )

  const handleStop = useCallback(
    (ev: MouseEvent) => {
      handlePan(ev)
      setShowPan(false)
    },
    [handlePan]
  )

  const [showPan, setShowPan] = useState(false)

  useDragMove({
    el: anchorEl.current,
    onMove: handlePan,
    onStart: handleStart,
    onStop: handleStop,
  })

  return (
    <div className={cn(className, classes.root)} title={getLabel(pan)} ref={anchorEl}>
      <div className={cn(classes.container, classes.leftContainer)}>
        <div className={classes.leftPan} />
      </div>
      <div className={cn(classes.container, classes.centerContainer)} />
      <div className={cn(classes.container, classes.rightContainer)}>
        <div className={classes.rightPan} />
      </div>
      <Popper open={showPan} anchorEl={anchorEl.current} placement="top">
        <Typography variant="caption">{getLabel(pan)}</Typography>
      </Popper>
    </div>
  )
}

export default ChannelPan
