import { FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from '@mui/material'
import { NOTE_NAMES, SCALE_NAMES } from '@tunasong/music-lib'
import type { NoteName } from '@tunasong/schemas'
import { useCallback, type FC } from 'react'

export interface NoteSelectProps {
  className?: string
  title?: string
  type?: 'ALL' | 'SCALE'
  value?: NoteName
  fullWidth?: boolean
  noValueAllowed?: boolean
  onChange(note: NoteName | undefined): void
}

const NoteSelect: FC<NoteSelectProps> = props => {
  const {
    className,
    noValueAllowed = false,
    title = 'Root',
    value = '',
    type = 'ALL',
    onChange,
    fullWidth = false,
    ...restProps
  } = props

  const handleNote = useCallback(
    (ev: SelectChangeEvent<string>) => {
      onChange(ev.target.value as NoteName)
    },
    [onChange]
  )

  const notes = type === 'ALL' ? NOTE_NAMES : SCALE_NAMES

  return (
    <FormControl fullWidth={fullWidth} className={className} sx={{ minWidth: 120 }}>
      <InputLabel htmlFor="note">{title}</InputLabel>
      <Select
        {...restProps}
        value={value}
        variant="standard"
        onChange={handleNote}
        label={title}
        inputProps={{
          id: 'note',
          name: 'note',
        }}
      >
        {noValueAllowed ? (
          <MenuItem value={undefined}>
            <i>Not set</i>
          </MenuItem>
        ) : null}
        {notes.map((n, idx) => (
          <MenuItem key={idx} value={n}>
            {n}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default NoteSelect
