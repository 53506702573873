import { Weblature } from './weblature.js'
import { Instrument } from './instrument.js'

const mandolinTuning = [Weblature.note('G', 3), Weblature.note('D', 4), Weblature.note('A', 4), Weblature.note('E', 5)]

export class Mandolin extends Instrument {
  constructor() {
    super(mandolinTuning)
  }
}
