/** Hook to handle things element list */

import { type Invite } from '@tunasong/models'
import { entitiesApi, useSelector } from '@tunasong/redux'
import { type ACL, type Entity, type ItemOperation, type Persisted } from '@tunasong/schemas'
import { getEntityPath } from '@tunasong/ui-lib'
import { useCallback, useMemo, useState } from 'react'
import invariant from 'tiny-invariant'
import { ShareDialog } from './share-dialog.js'

export const useShare = (entity?: Persisted<Entity>, onClose?: () => void) => {
  const [shareEntity, shareEntityResult] = entitiesApi.useShareMutation()
  const { isLoading } = shareEntityResult
  const [invite] = entitiesApi.useInviteMutation()

  const userId = useSelector(state => state.user.userId)
  const [showShare, setShowShare] = useState(false)
  const show = useCallback(() => setShowShare(true), [])
  const hide = useCallback(() => {
    setShowShare(false)
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const handleShare = useCallback(
    (acls: ACL[]) => {
      invariant(entity, 'Cannot share an undefined entity')
      /** Share will recursively share all children as well */
      shareEntity({ item: entity, acls })
    },
    [entity, shareEntity]
  )

  const handleInvite = useCallback(
    (email: string, allow: ItemOperation) => {
      if (!(userId && entity?.id)) {
        return
      }

      /** The /invite endpoint will handle invites */

      const targetPath = `${getEntityPath({ entity })}?mode=signup`

      const params: Invite = {
        inviteeEmail: email,
        inviterId: userId,
        entityIds: [entity.id],
        allow,
        targetPath,
      }

      invite({ invite: params })
    },
    [entity, invite, userId]
  )

  const dialog = useMemo(
    () =>
      entity ? (
        <ShareDialog
          key={`${entity.id}-share-dialog`}
          open={showShare}
          entity={entity}
          onShare={handleShare}
          onInvite={handleInvite}
          onClose={hide}
          isLoading={isLoading}
        />
      ) : null,
    [entity, showShare, handleShare, handleInvite, hide, isLoading]
  )

  return { share: handleShare, invite: handleInvite, show, hide, dialog, isLoading }
}
