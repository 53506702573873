import { Button, Grid, IconButton, Typography } from '@mui/material'
import { Delete } from '@tunasong/icons'
import { type SearchSummary } from '@tunasong/models'
import { useEdgeEntities, useEntity } from '@tunasong/redux'
import { type Entity, type EdgeType, type Persisted, type SearchFilter } from '@tunasong/schemas'
import { SearchResults, useRelatedContent } from '@tunasong/search'
import { EntityCard, Pulse } from '@tunasong/ui-lib'
import { type FC, useCallback, useMemo, useState } from 'react'

/** View to manage related content */
/** Filter must be stable */
const filter = {
  types: ['audio'],
} satisfies SearchFilter

interface RelatedContentProps {
  entity: Persisted<Entity>
  relation?: EdgeType
  relationTitle?: string
}

export const RelatedContent: FC<RelatedContentProps> = props => {
  const { entity: source, relation = 'hasRecording', relationTitle = 'Recordings' } = props

  const [targetSelected, setTargetSelected] = useState<SearchSummary>()
  const { entity: target } = useEntity(targetSelected?.id)

  const { results } = useRelatedContent({
    entity: source,
    filter,
  })

  const {
    edgeEntities,
    createEdge,
    deleteEdge,
    hasLoaded: hasLoadedEdges,
    isLoading: isLoadingEdges,
    isCreating,
  } = useEdgeEntities({ sourceId: source?.id, relation })

  const linkedIds = useMemo(() => edgeEntities.map(({ target }) => target?.id), [edgeEntities])
  const availableEntities = useMemo(() => results.filter(r => !linkedIds.includes(r.id)), [results, linkedIds])
  const hasEdge = useMemo(() => linkedIds.some(id => target?.id === id), [linkedIds, target?.id])

  const selectedIndex = useMemo(
    () => availableEntities.findIndex(r => r.id === targetSelected?.id),
    [availableEntities, targetSelected?.id]
  )
  const handleCreate = useCallback(async () => {
    if (!targetSelected) {
      return
    }
    await createEdge(targetSelected?.id)
    setTimeout(() => {
      setTargetSelected(availableEntities[1])
    }, 0)
  }, [availableEntities, createEdge, targetSelected])

  const handleDelete = useCallback((target: string) => () => deleteEdge(target), [deleteEdge])

  return (
    <>
      <Button
        disabled={isCreating || isLoadingEdges || !targetSelected || !hasLoadedEdges || hasEdge}
        onClick={handleCreate}
      >
        Link
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">{relationTitle}</Typography>
          {isLoadingEdges && <Pulse />}
          {edgeEntities.map(({ target }) => (
            <EntityCard
              sx={{ my: 2 }}
              key={target.id}
              entity={target}
              showMenu={false}
              headerAction={
                <IconButton onClick={handleDelete(target.id)}>
                  <Delete />
                </IconButton>
              }
            />
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Available Content</Typography>
          <SearchResults entities={availableEntities} selectedIndex={selectedIndex} onChange={setTargetSelected} />
        </Grid>
      </Grid>
    </>
  )
}

export default RelatedContent
