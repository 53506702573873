import { type TunaEditor } from '@tunasong/plugin-lib'
import { Node } from 'slate'
import { ReactEditor } from 'slate-react'
import { isReactEditor } from '../editor/tuna-editor.js'

export const getCurrentHTMLElement = (editor: TunaEditor) => {
  let currentHTMLElement: HTMLElement | null = null
  try {
    const path = editor.selection?.focus.path
    currentHTMLElement =
      path && isReactEditor(editor)
        ? ReactEditor.toDOMNode(editor as unknown as ReactEditor, Node.get(editor, path))
        : null
  } catch {}
  return currentHTMLElement
}
