import { useMemo } from 'react'
import { useEmbedding } from './embedding.hook.js'
import embeddingSimilarity from './queries/embedding-similarity.js'
import { type SearchProps, useAdvancedSearch } from './search.hook.js'

/** Embeddings search for siminar content */
export const useSimilarSearch = (
  { entityId }: { entityId?: string },
  props: SearchProps = {
    size: 3,
    queryType: 'relatedContent',
  }
) => {
  /** First, get the embeddings */
  const embedding = useEmbedding({ entityId })
  const query = useMemo(
    () => (embedding ? embeddingSimilarity(embedding, props.size ?? 0 + 1) : undefined),
    [embedding, props.size]
  )
  const { results, ...restProps } = useAdvancedSearch(query, props)

  /** The most relevant result is the doc we're using, so filter that out */
  return useMemo(
    () => ({
      results: results?.filter(r => r.id !== entityId),
      ...restProps,
    }),
    [entityId, restProps, results]
  )
}
