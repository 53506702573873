import { type Cursor, type CursorEditor, YjsEditor } from '@tunasong/sync-lib'
import { getRemoteCaretsOnLeaf } from '@tunasong/sync-lib/react'

export interface YjsEditor extends YjsEditor, CursorEditor {
  cursors: Cursor[]
}

export interface YjsState {
  userId: string
  color: string // rgb
  alphaColor: string
  anchor: YjsCaretPoint
  focus: YjsCaretPoint
}

export interface YjsCaretPoint {
  type: {
    client: string
    clock: number
  }
}
export interface YjsCaret {
  data: YjsState
  isForward: boolean
  isCaret: boolean
}

export function isCaret(range?: unknown): range is YjsCaret {
  const carets = getRemoteCaretsOnLeaf(range)

  return carets.length > 0
}
