/** Hook to control the video and audio tracks of the local and shared streams */

import { type BusName } from '@tunasong/models'
import { useEffect, useRef } from 'react'
import { MediaStreamRecorder } from '../recorder/media-recorder.js'
import { type Recorder } from '../recorder/recorder.js'
import { WavRecorder } from '../recorder/wav-recorder.js'
import { useMixer } from './mixer.hook.js'

export const useRecorder = (type: 'wav' | 'stream' = 'stream', busName: BusName = 'record') => {
  const recorder = useRef<Recorder>()
  const mixer = useMixer()

  /** Create a recorder */
  useEffect(() => {
    if (recorder.current || !mixer) {
      return
    }
    const stream = mixer.getStream(busName)?.stream
    const r = type === 'stream' && stream ? new MediaStreamRecorder(stream, { type: 'audio' }) : new WavRecorder(mixer)
    recorder.current = r

    return () => {
      r.destroy()
      recorder.current = undefined
    }
  }, [busName, mixer, type])

  return recorder.current
}
