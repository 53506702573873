export interface FormatTimeOptions {
  /** millis include milliseconds */
  millis?: boolean
  /** Prefix for minutes. @default 00 */
  minutePrefix?: string
}

export const formatTime = (time: number, options?: FormatTimeOptions) => {
  const { millis = true, minutePrefix = '00' } = { ...options }
  time = isNaN(time) ? 0 : time
  return [
    (minutePrefix + Math.floor(time / 60)).slice(-2), // minutes
    ('00' + Math.floor(time % 60)).slice(-2), // seconds
    millis ? ('000' + Math.floor((time % 1) * 1000)).slice(-3) : null, // milliseconds
  ]
    .filter(Boolean)
    .join(':')
}
