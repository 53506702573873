import { Box } from '@mui/material'
import { makeStyles } from '@tunasong/ui-lib'
import {
  ResizableProvider,
  ResizeHandle,
  ResizeHandleProvider,
  useResizable,
  useResizableState,
} from '@udecode/plate-resizable'
import { type FC } from 'react'
import { useReadOnly, useSelected } from 'slate-react'
import { Block, type BlockProps } from './block.js'

export interface ResizableBlockProps extends BlockProps {
  element: BlockProps['element'] & { width?: string | number }
  align?: 'left' | 'center' | 'right'
  minWidth?: number | string
  maxWidth?: number | string
}

const useStyles = makeStyles<{ align?: string; focused: boolean }>()((theme, { focused }) => ({
  handle: {
    display: 'flex',
    userSelect: 'none',
    width: theme.spacing(1),
    backgroundColor: theme.vars.palette.divider,
    zIndex: 1,
    position: 'absolute',
    alignItems: 'flex-end',
    top: 0,
    height: '100%',
    ':hover': {
      cursor: 'col-resize',
      display: 'block !important',
      backgroundColor: theme.vars.palette.secondary.main,
    },
  },
  handleLeft: {
    left: theme.spacing(-1),
    backgroundColor: focused ? theme.vars.palette.divider : 'transparent',
    paddingLeft: theme.spacing(1),
  },
  handleRight: {
    right: theme.spacing(-1),
    backgroundColor: focused ? theme.vars.palette.divider : 'transparent',
    paddingRight: theme.spacing(-1),
  },
}))

const ResizableBlockInner: FC<ResizableBlockProps> = props => {
  const { children, align, minWidth = 100, maxWidth = '100%', ...restProps } = props
  const focused = useSelected()
  const readOnly = useReadOnly()

  const { classes } = useStyles({ align, focused })
  const state = useResizableState({
    readOnly,
    minWidth,
    maxWidth,
    align,
  })
  const { wrapperRef, wrapperProps, props: rProps, context } = useResizable(state)

  return (
    <Block {...restProps} border={false}>
      <Box ref={wrapperRef} {...wrapperProps} sx={{ display: 'flex', justifyContent: align }} contentEditable={false}>
        <Box {...rProps}>
          <ResizeHandleProvider onResize={context.onResize}>
            {!readOnly ? (
              <ResizeHandle className={`${classes.handle} ${classes.handleLeft}`} options={{ direction: 'left' }} />
            ) : null}
            {children}
            {!readOnly ? (
              <ResizeHandle className={`${classes.handle} ${classes.handleRight}`} options={{ direction: 'right' }} />
            ) : null}
          </ResizeHandleProvider>
        </Box>
      </Box>
    </Block>
  )
}

export const ResizableBlock: FC<ResizableBlockProps> = props => (
  <ResizableProvider>
    <ResizableBlockInner {...props} />
  </ResizableProvider>
)

export default ResizableBlock
