import { Typography } from '@mui/material'
import { capitalize } from '@tunasong/models'
import { type AudioEvent } from '@tunasong/schemas'
import { type FC } from 'react'
import { formatTime } from '../lib/format-time.js'

export interface EventProps {
  className?: string
  event?: AudioEvent
}

export const Event: FC<EventProps> = ({ className, event }) => {
  if (!event) {
    return null
  }
  const title = `${formatTime(event.start)} ${event.type}`
  return (
    <>
      <Typography className={className} variant="caption" title={title}>
        {capitalize(event.type)}
      </Typography>
      <Typography className={className} variant="subtitle1" title={title}>
        {event.name}
      </Typography>
    </>
  )
}

export default Event
