import { Box, Typography, type BoxProps } from '@mui/material'
import { type FilterFunc, type SortOptions } from '@tunasong/models'
import { useChildrenIncremental } from '@tunasong/redux'
import { isAudio, type AudioMedia, type Persisted } from '@tunasong/schemas'
import { useEntitySort } from '@tunasong/ui-lib'
import { type FC } from 'react'
import Waveform from './waveform.js'

export interface EntityAudioClipsProps extends BoxProps {
  parentId: string
  /** @default isAudio */
  filter?: FilterFunc
}

const sortOptions = { sortBy: 'updatedAt', order: 'desc' } satisfies SortOptions

export const EntityAudioClips: FC<EntityAudioClipsProps> = props => {
  const { parentId, filter = isAudio, ...restProps } = props
  const { entities: clips } = useChildrenIncremental<Persisted<AudioMedia>>({ parentId, filter })
  const audioClips = useEntitySort(clips, sortOptions)

  return (
    <Box {...restProps}>
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        {audioClips.length > 0 ? (
          audioClips.map(clip => (
            <Box sx={{ position: 'relative' }} key={clip.id}>
              <Waveform
                key={clip.id}
                media={clip}
                timeline={false}
                autoStop={true}
                sx={{ paddingTop: 2 }}
                renderPeaks={true}
              />
            </Box>
          ))
        ) : (
          <Typography variant="caption">No clips</Typography>
        )}
      </Box>
    </Box>
  )
}

export default EntityAudioClips
