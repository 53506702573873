import { useDeviceState } from '@tunasong/redux'
import manifest from '@tunasong/manifest'
import { useEffect, useState } from 'react'

/** Get the ID of the release notes to show. If `null` no (new) release notes are available. */
interface ReleaseNotes {
  version: string
  entityId: string
}
export const useReleaseNotes = () => {
  const [latestSeenRelease, setLatestSeenRelease, isReady] = useDeviceState<string>('notifications.last-seen-release')

  const [latestUnread, setLatestUnread] = useState<ReleaseNotes>()

  const setReadVersion = (version: string) => {
    setLatestSeenRelease(version)
    setLatestUnread(undefined)
  }

  useEffect(() => {
    if (!isReady) {
      return
    }
    // Sort on the version number string, making sure e.g., 0.10.0 comes before 0.9.0
    const latestRelease = manifest.releases.sort((a, b) => {
      const aParts = a.version.split('.').map(Number)
      const bParts = b.version.split('.').map(Number)
      for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
        const aPart = aParts[i] ?? 0
        const bPart = bParts[i] ?? 0
        if (aPart < bPart) {
          return 1
        }
        if (aPart > bPart) {
          return -1
        }
      }
      return 0
    })[0]

    if (!latestRelease) {
      return
    }
    if (latestRelease.version !== latestSeenRelease) {
      setLatestUnread(latestRelease)
    }
  }, [isReady, latestSeenRelease, setLatestSeenRelease])

  return { releaseNotes: manifest.releases, latestUnread, setReadVersion }
}
