import { logger } from '@tunasong/models'

/* eslint-disable no-bitwise */
export class BTDecode {
  public midiMessage: Uint8Array | undefined
  public delay: number = 0

  private parserMessage: number[] = []
  private runningStatus: number = 0
  private thirdByteFlag: boolean = false

  private prevReturnedTimestamp: number = 0
  private prevReceivedTimestamp: number = 0
  private connTimePrevious: number = 0
  private firstMessageReceived = true

  constructor(blepacket: Uint8Array) {
    let connTime = performance.now()
    let currentByte

    let nextIsNewTimestamp = false

    let timestampBLE = (blepacket[1] & 0x7f) + ((blepacket[0] & 0x3f) << 7)
    this.delay = this.convertTimestamp(timestampBLE, connTime)

    /** Check messages in package. */
    for (let pos = 2; pos < blepacket.length; pos++) {
      currentByte = blepacket[pos]

      /** Check MSB and expectations to ID current byte as timestamp,
       * statusbyte or databyte */
      if (currentByte >> 7 && nextIsNewTimestamp) {
        /** New Timestamp means last message is complete */
        nextIsNewTimestamp = false

        if ((currentByte & 0x7f) < (timestampBLE & 0x7f)) {
          /** Timestamp overflow, increment Timestamp High */
          timestampBLE += 1 << 7
        }

        /** Storing newest timestamp for later reference, and translating
         * timestamp to local time */
        timestampBLE = (currentByte & 0x7f) + (timestampBLE & 0x1f80)
        this.delay = this.convertTimestamp(timestampBLE, connTime)

        if (this.midiMessage === undefined) {
          /** Previous message was not complete */
          logger.warn('Incomplete message: pos ' + pos)
        }
      } else {
        /** Statusbytes and databytes */
        nextIsNewTimestamp = true

        const midiMessage = this.parseMidiByte(currentByte)
        if (midiMessage) {
          /** Message completed */
          this.midiMessage = midiMessage as never
        }
      }
    }
  }

  private convertTimestamp(timestampBLE: number, connTime: number) {
    if (this.firstMessageReceived) {
      this.firstMessageReceived = false

      this.connTimePrevious = connTime
      this.prevReceivedTimestamp = timestampBLE
      this.prevReturnedTimestamp = connTime

      return 0
    }

    let trueTSInterval =
      ((timestampBLE - this.prevReceivedTimestamp) & 8191) +
      Math.round((connTime - this.connTimePrevious - ((timestampBLE - this.prevReceivedTimestamp) & 8191)) / 8192) *
        8192

    let addedDelay = trueTSInterval - (connTime - this.prevReturnedTimestamp)
    if (addedDelay < 0) {
      addedDelay = 0
    }

    this.connTimePrevious = connTime
    this.prevReceivedTimestamp = timestampBLE
    this.prevReturnedTimestamp = performance.now() + addedDelay

    return addedDelay
  }

  private parseMidiByte(currentByte: number) {
    if (currentByte >> 7 === 1) {
      /** Current byte is statusbyte */
      this.runningStatus = currentByte
      this.thirdByteFlag = false

      if (currentByte >> 7 === 1 && currentByte >> 3 === 0b11111) {
        /** System Real-Time Messages */
        this.parserMessage[0] = currentByte
        return this.parserMessage
      }

      /** Message with only one byte */
      if (currentByte >> 2 === 0b111101) {
        if (currentByte === 0xf7) {
          /** End of exclusive, not supported. Discarded for now.  */
          return
        }
        this.parserMessage[0] = currentByte

        return this.parserMessage
      }
      return
    }

    if (this.thirdByteFlag === true) {
      /** Expected third, and last, byte of message */
      this.thirdByteFlag = false
      this.parserMessage[2] = currentByte
      return this.parserMessage
    }

    if (this.runningStatus === 0) {
      /** System Exclusive (SysEx) databytes, from 3rd byte until EoX, or
       * orphaned databytes. */
      return
    }

    /** Channel Voice Messages */
    switch (this.runningStatus >> 4) {
      case 0x8:
      case 0x9:
      case 0xa:
      case 0xb:
      case 0xe:
        this.thirdByteFlag = true
        this.parserMessage[0] = this.runningStatus
        this.parserMessage[1] = currentByte
        return
      case 0xc:
      case 0xd:
        this.parserMessage[0] = this.runningStatus
        this.parserMessage[1] = currentByte
        return this.parserMessage
    }

    /** System Common Message */
    switch (this.runningStatus) {
      case 0xf2:
        this.thirdByteFlag = true
        this.parserMessage[0] = this.runningStatus
        this.parserMessage[1] = currentByte
        this.runningStatus = 0
        return this.parserMessage
      case 0xf1:
      case 0xf3:
        this.thirdByteFlag = false
        this.parserMessage[0] = this.runningStatus
        this.parserMessage[1] = currentByte
        this.runningStatus = 0
        return this.parserMessage
      case 0xf0:
        break
    }

    this.runningStatus = 0
  }
}
