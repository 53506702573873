/** Get a label from a duration (in seconds) */


const pad = (v: number, width = 2, z = '0') => {
  if (isNaN(v)) {
    return '-'
  }
  z = z || '0'
  const n = v.toString() + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export const getDurationLabel = (duration: number) => {
  let val = duration
  const hours = Math.floor(val / 3600)
  val = val - hours * 3600

  const min = Math.floor(val / 60)
  val = val - min * 60

  const sec = Math.round(val)

  return hours > 0 ? `${pad(hours)}:${pad(min)}:${pad(sec)}` : `${pad(min)}:${pad(sec)}`
}
