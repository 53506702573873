// @refresh reset

import { Box, type BoxProps } from '@mui/material'
import { ensureAllNodesHaveChildren } from '@tunasong/models'
import { type MusicContextType } from '@tunasong/music-lib'
import { buildMusicContext } from '@tunasong/music-ui'
import {
  RenderPlugins,
  TunaEditorContext,
  getEditorContainerId,
  useEditorMessage,
  type EditorEvent,
  type TunaEditor,
  type TunaPlugin,
} from '@tunasong/plugin-lib'
import { useMusicContext } from '@tunasong/redux'
import { type CoreElement, type TunaDecendant } from '@tunasong/schemas'
import { BrowserCapabilities, useDelayedInvoke } from '@tunasong/ui-lib'
import { Plate, PlateContent } from '@udecode/plate-core'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Node } from 'slate'
import { RenderLeaf } from './render-leaf.js'

import { makeStyles } from '@tunasong/ui-lib'

const useStyles = makeStyles<{ gutter: boolean }>({ name: 'element-editor' })((theme, { gutter }) => ({
  root: {
    display: 'flex',
    flex: 1,
    position: 'relative',
    flexDirection: 'column',
  },
  editor: {
    display: 'flex',
    outline: 'none',
    /** @note this is required for hover-over menu icon */
    padding: gutter ? theme.spacing(0, 3, 0, 3) : 0,
    flexDirection: 'column',
    flex: '1 1 auto',
    cursor: 'auto',
    [theme.breakpoints.up('md')]: {},
  },
}))

export interface ElementEditorProps<T extends CoreElement = CoreElement> extends Omit<BoxProps, 'onChange'> {
  className?: string
  editor: TunaEditor
  element: T
  readOnly?: boolean
  autoFocus?: boolean

  /**
   * Placeholder text to show when the element is empty
   */
  placeholder?: string
  /** Use gutter. Required for hover-over icon to work */
  gutter?: boolean
  /** ready to render. Set to `false` if rendering should be postponed. @default true */
  ready?: boolean

  initialValue?: TunaDecendant[]

  plugins?: TunaPlugin[]

  /** onChange must ensure the updated element is set in element */
  onChange?(element: T): void
  /** Editor is considering the document complete - e.g., for a single-line editor */
  onComplete?(event: EditorEvent): void
  onContext?(context: MusicContextType): void
}

export const ElementEditor = <T extends CoreElement = CoreElement>(props: ElementEditorProps<T>) => {
  const {
    className,
    children,
    editor,
    element,
    initialValue,
    autoFocus,
    gutter = true,
    readOnly = false,
    ready = true,
    plugins,
    placeholder,
    onChange,
    onComplete,
    onContext,

    ...restProps
  } = props
  const { classes } = useStyles({ gutter })

  /** Messages that are handled by the plugin(s) */
  const showContextMenu = useEditorMessage({ editor, type: 'show-context-menu' })
  const onContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      const target = event.currentTarget instanceof HTMLElement ? event.currentTarget : null
      showContextMenu(element, target)
    },
    [element, showContextMenu]
  )

  /** Defer complete to allow onChange to finish */
  useEditorMessage({ editor, type: 'complete', handler: onComplete })

  /** Update context when editor and/element changes */
  const [, setContext] = useMusicContext({ editorId: editor.id, selector: state => state })
  const [defaultContext] = useMusicContext({ editorId: 'default', selector: state => state })

  /** Initial context update on load */
  const initialized = useRef(false)
  useEffect(() => {
    if (!(editor && element && !initialized.current)) {
      return
    }
    initialized.current = true

    setContext(buildMusicContext(editor, element, defaultContext))
    editor.rootElement = element
    editor.placeholder = placeholder
  }, [defaultContext, editor, element, placeholder, setContext])

  /** Update the context after 250ms of no changes since that is potentially expensive */
  const delayedContext = useDelayedInvoke(250)

  const handleChange = useCallback(
    (nodes: Node[]) => {
      // Context depends on the selection
      delayedContext(() => {
        const editContext = buildMusicContext(editor, element)
        setContext(editContext)
        if (onContext) {
          onContext(editContext)
        }
      })

      /** if only selection has changed, do not call onChange */
      if (editor.operations.length > 0 && editor.operations.every(op => op.type === 'set_selection')) {
        return
      }

      // logger.debug('ElementEditor.handleChange', { ops: editor.operations })

      if (!onChange) {
        return
      }
      const newElement: T = {
        ...element,
        children: nodes as TunaDecendant[],
      }

      onChange(newElement)
    },
    [delayedContext, editor, element, onChange, onContext, setContext]
  )

  // this seems unnecessary if we do initial normalization
  const sanitizedValue = useMemo(() => {
    const val = initialValue ?? element?.children ?? null
    return val ? val.map(ensureAllNodesHaveChildren) : null
  }, [element?.children, initialValue]) as CoreElement[]

  if (!ready || !sanitizedValue || (Array.isArray(sanitizedValue) && sanitizedValue.length === 0)) {
    return null
  }

  return (
    <Box id={getEditorContainerId(editor)} className={classNames(classes.root, className)} {...restProps}>
      <RenderPlugins editor={editor} type="root" />
      <TunaEditorContext.Provider value={editor}>
        <RenderPlugins editor={editor} type="top" />

        <Plate<CoreElement[]>
          id={editor.id}
          key={editor.id}
          editor={editor as never}
          // This disables HTML serialization etc. revisit when time allows. Also check where we create the editor
          disableCorePlugins={true}
          normalizeInitialValue={!readOnly}
          initialValue={sanitizedValue}
          onChange={handleChange}
          /** @todo without these casts we get type inference errors (too deep) */
          plugins={(plugins ?? editor.plugins) as never}
          renderLeaf={RenderLeaf}
          // data-autofocus is telling FocusLock to zero in on this
          data-autofocus
        >
          <PlateContent
            className={classes.editor}
            autoFocus={autoFocus}
            spellCheck={false}
            onContextMenu={onContextMenu}
            readOnly={readOnly || !BrowserCapabilities.supportsEdit}
          >
            {children}
          </PlateContent>
        </Plate>
      </TunaEditorContext.Provider>
    </Box>
  )
}
