import { Box, Divider, LinearProgress, Typography, type BoxProps } from '@mui/material'
import { matchAny, noteTemplate, shortUuid, type Note } from '@tunasong/models'
import { usePlugins } from '@tunasong/plugin-lib'
import { useChildren, useMimeTypes } from '@tunasong/redux'
import { isMusicXML, isPdf } from '@tunasong/schemas'
import { EntityContent, UploadStoredEntity, VBox, type SidebarComponent } from '@tunasong/ui-lib'
import { useRef, type FC } from 'react'
import { useEditorClass } from '../hooks/index.js'

const contentFilter = matchAny(isPdf, isMusicXML)

const NoteEditorBox = (props: BoxProps) => (
  <Box
    sx={{ borderRight: theme => `2px solid ${theme.vars.palette.primary.main}`, my: 1, p: 1, flex: 1, display: 'flex' }}
  >
    {props.children}
  </Box>
)

export const EntityNotes: FC<Omit<SidebarComponent, 'onChange'>> = ({ entity, ...restProps }) => {
  const { entities: notes, hasLoaded } = useChildren<Note>({ parentId: entity?.id, filter: 'note' })

  const draftTemplate = useRef(noteTemplate({ id: shortUuid(), parentId: entity.id })).current

  const { entities, isLoading } = useChildren({
    parentId: entity?.id,
    filter: contentFilter,
  })

  const NoteEditor = useEditorClass('NoteEditor')
  const { mimeTypeAccept } = useMimeTypes()

  const plugins = usePlugins('all') ?? []

  // In the case where we start using the draft, we need to filter out the draft from the notes
  const filteredNotes = notes.filter(n => n.id !== draftTemplate.id)
  const hasNote = hasLoaded ? filteredNotes.length > 0 : undefined

  return (
    <VBox sx={{ flex: 1, ...restProps?.sx }} {...restProps}>
      <Divider>
        <Typography variant="caption">Personal Note</Typography>
      </Divider>
      {filteredNotes.map(note => (
        <NoteEditorBox>
          <NoteEditor key={note.id} element={note} gutter={false} />
        </NoteEditorBox>
      ))}
      {/* If there are no other notes, provide an empty note */}
      {!hasNote ? (
        <NoteEditorBox>
          <NoteEditor sx={{ flex: 1 }} element={draftTemplate} gutter={false} />
        </NoteEditorBox>
      ) : null}

      <Divider>
        <Typography variant="caption">Documents</Typography>
      </Divider>
      {isLoading ? <LinearProgress /> : null}
      {entities.map(doc => (
        <EntityContent key={doc.id} entity={doc} plugins={plugins} />
      ))}
      <Box flex={1} />
      <UploadStoredEntity parentId={entity.id} contentHint="MusicXML or PDF" isPrivate={true} accept={mimeTypeAccept} />
    </VBox>
  )
}
