import { Box } from '@mui/material'
import { UserAvatar } from '@tunasong/ui-lib'
import React from 'react'
import { type YjsCaret } from './types.js'

interface Caret {
  timeout?: number
  carets: YjsCaret[]
  cursors: unknown[]
}

const Caret: React.FC<Caret> = props => {
  const { carets, ...restProps } = props

  /** For now we just show the first caret */
  // logger.debug('Cursors and carets', cursors, carets)
  const data = carets[0].data
  const isForward = true

  return (
    <Box
      component="span"
      contentEditable={false}
      sx={{
        position: 'absolute',
        pointerEvents: 'none',
        userSelect: 'none',
        height: '1.2em',
        width: 2,
        backgroundColor: data.color,
        // @todo fix the isForward stuff with the new carets
        left: isForward ? '100%' : '0%',
        [isForward ? 'bottom' : 'top']: 0,
      }}
      {...restProps}
    >
      <Box
        component="span"
        contentEditable={false}
        sx={{
          /**
           * The block has a transform and acts as a coordinate reference
           * https://drafts.csswg.org/css-transforms/#transform-rendering
           */
          position: 'fixed',
          pointerEvents: 'none',
          userSelect: 'none',
          whiteSpace: 'nowrap',
          color: theme => theme.palette.getContrastText(data.color),
          left: theme => theme.spacing(-3),
        }}
      >
        <UserAvatar size="micro" userId={data.userId} />
      </Box>
    </Box>
  )
}

export default Caret
