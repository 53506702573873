import { Box, Divider, Typography } from '@mui/material'
import { type SidebarComponent } from '@tunasong/ui-lib'
import { type FC } from 'react'
import { Edges, Backlinks } from '@tunasong/ui-lib'

export const SidebarGraph: FC<SidebarComponent> = ({ entity }) => (
  <Box sx={{ overflow: 'auto' }}>
    <Typography variant="caption">
      <Divider textAlign="center" sx={{ my: 1 }}>
        Links
      </Divider>
      <Edges sourceId={entity.id} />
      <Divider textAlign="center" sx={{ my: 1 }}>
        Backlinks
      </Divider>
      <Backlinks id={entity.id} />
    </Typography>
  </Box>
)
