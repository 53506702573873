import { ElementEditor, useCreateEditor, type EditorContextProps } from '@tunasong/editor'
import { usePlugins, type TunaEditor } from '@tunasong/plugin-lib'
import { isCoreElement } from '@tunasong/schemas'
import { useEffect } from 'react'

export interface ChatEditorProps extends EditorContextProps {
  onEditor?(editor: TunaEditor): void
}

export const ChatEditor = (props: ChatEditorProps) => {
  const { element, onEditor, ...restProps } = props
  const chatPlugins = usePlugins('chat')

  const editor = useCreateEditor(element, chatPlugins)
  useEffect(() => {
    if (!editor) {
      return
    }
    onEditor?.(editor)
  }, [editor, onEditor])

  return chatPlugins && editor && isCoreElement(element) ? (
    <ElementEditor {...restProps} editor={editor} gutter={false} element={element} plugins={chatPlugins} />
  ) : null
}
