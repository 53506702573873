/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from '@tunasong/ui-lib'
import type { CSSProperties } from 'react'

export const useBlockStyles = makeStyles()(theme => ({
  root: {
    position: 'relative',
    alignItems: 'center',
    '& ::selection': {
      backgroundColor: theme.vars.palette.action.selected,
      // color: theme.vars.palette.action.contrastText,
    },
    /**
     * Set new coordinate system to allow children to use position fixed relative
     * @see https://drafts.csswg.org/css-transforms/#transform-rendering
     */
    transform: 'rotate(0deg)',
  },

  inline: {
    display: 'inline-flex',
  },
  selected: {
    backgroundColor: theme.vars.palette.action.selected,
  },
  border: {
    borderRightStyle: 'solid',
    borderRightWidth: theme.spacing(0.25),
    borderRightColor: theme.vars.palette.grey[200],
    ...(theme.applyStyles('dark', {
      borderRightColor: theme.vars.palette.background.paper,
    }) as CSSProperties),
    '@media print': {
      border: 'none',
    },
  },
  focus: {
    borderRightColor: `${theme.vars.palette.primary.main} !important`,
  },
  /** Dragable */
  icon: {
    fontSize: 24,
    color: theme.vars.palette.text.secondary,
  },
  blockAndGutter: {
    borderRadius: 0,
  },
  gutterLeft: {
    position: 'absolute',
    top: 0,
    left: theme.spacing(-2),
    transform: 'translateX(-100%)',
    display: 'flex',
    height: '100%',
    width: theme.spacing(2),
    opacity: 0,
    transition: 'all 0.3s',
    ':hover': {
      opacity: 1,
    },
  },

  blockToolbar: {
    width: 18,
    height: 18,
    marginRight: 4,
    pointerEvents: 'auto',
  },
  dragButton: {
    minWidth: 18,
    height: 18,
    padding: 0,
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    outline: 'none',
  },
  dropLine: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 2,
    opacity: 1,
    background: theme.vars.palette.secondary.main,
  },
  dropLineTop: {
    top: -1,
  },
  dropLineBottom: {
    bottom: -1,
  },
}))
