/** Hook to handle the signaling for a room */
import { useMixer } from '@tunasong/audio-ui'
import { logger, type WSMessage } from '@tunasong/models'
import {
  features,
  useSelector,
  useThunkDispatch,
  type PresenceLeaveEntityMessage,
  type WebRTCMessage,
} from '@tunasong/redux'
import { type Entity, type Persisted } from '@tunasong/schemas'
import { useRedraw } from '@tunasong/ui-lib'
import { WebSocketContext } from '@tunasong/ws'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import invariant from 'tiny-invariant'
import { VideoConnection } from '../video/lib/connection.js'
import { useConnectionPool } from '../video/pool.js'

export interface RoomSignalingProps {
  entity?: Persisted<Entity>
  name?: string
  stream: MediaStream | null
}

/**
 * Use ONLY once in an application. Otherwise multiple Connection Pools will be set up. Dependents should
 * use the SignalingContext instead.
 */
export const useVideoSignaling = ({ entity, stream }: RoomSignalingProps) => {
  const clientId = useSelector(state => state.presence.clientId)
  const iceServers = useSelector(state => state.webrtc.iceServers)
  const iceServersExpires = useSelector(state => state.webrtc.iceServersExpires)
  const iceServersRequested = useSelector(state => state.webrtc.iceServersRequested)
  const mixer = useMixer()
  const socket = useContext(WebSocketContext)
  const dispatch = useThunkDispatch()

  /** We force a re-draw when we receive new video tracks */
  const redraw = useRedraw()

  const { addConnection, removeConnection, getConnection, getConnections, hasConnection } = useConnectionPool({
    stream,
  })

  /** Don't depend on entity when we only care about the ID */
  const entityId = entity?.id

  const sendWSMessage = useCallback(
    (msg: WSMessage) => {
      if (!socket) {
        throw new Error(`useVideoSignaling: sendMessage called without a socket: ${JSON.stringify(msg)}`)
      }
      socket.send(JSON.stringify(msg))
    },
    [socket]
  )

  const sendMessage = useCallback(
    (msg: WebRTCMessage) => {
      /** Package the message in a WSMessage envelope */
      const wsMsg: WSMessage = {
        action: 'webrtc',
        data: msg,
      }
      return sendWSMessage(wsMsg)
    },
    [sendWSMessage]
  )

  /** Renew ICE if they expire within an hour. */
  useEffect(() => {
    if (!socket) {
      return
    }
    const inAnHour = Date.now() + 1000 * 60 * 60
    const mustRenew = iceServersExpires < inAnHour
    if (iceServersRequested || !mustRenew) {
      return
    }
    const request = features.webrtc.actions.requestIceServers()
    dispatch(request)
    sendWSMessage({
      action: 'webrtc',
      data: request,
    })
  }, [dispatch, iceServersExpires, iceServersRequested, sendWSMessage, socket])

  const ready = useCallback(() => Boolean(stream && entityId), [entityId, stream])

  const createConnection = useCallback(
    (peerClientId: string) => {
      if (!(stream && entityId)) {
        throw new Error('Not ready to create connection...')
      }
      const connection = new VideoConnection({
        stream,
        entityId,
        clientId,
        peerClientId,
        iceServers,
        mixer,
        sendMessage,
        onTrack: redraw,
        onDisconnect: conn => removeConnection(conn.peerClientId),
      })
      addConnection(connection)
      return connection
    },
    [stream, mixer, entityId, clientId, iceServers, sendMessage, redraw, addConnection, removeConnection]
  )

  const handleLeave = useCallback(
    (msg: PresenceLeaveEntityMessage) => {
      removeConnection(msg.payload.senderClientId)
    },
    [removeConnection]
  )

  const handleMessage = useCallback(
    (ev: MessageEvent) => {
      const { data } = ev
      logger.debug('Video signaling received message', data)
      const wsMsg = JSON.parse(data) as WSMessage
      if (wsMsg.action !== 'webrtc') {
        return
      }
      const msg = wsMsg.data

      /** Some sanity checks */

      if (!entityId) {
        throw new Error(`handleMessage: received message but entityId is null: ${JSON.stringify(msg)}`)
      }

      // if (msg.senderClientId === clientId) {
      //   /** This will happen during testing so we allow the tester to trigger this */
      //   if (typeof process !== 'undefined' && process.env.NODE_ENV === 'test') {
      //     return
      //   }
      //   throw new Error(`Received message from ourselves ${clientId}.`)
      // }

      if (features.presence.actions.setActiveClientsForEntity.match(msg)) {
        /** Just dispatch it to the store */
        /** @todo this should be a redux message */
        dispatch(msg)
      } else if (features.presence.actions.enterEntity.match(msg)) {
        const { senderClientId } = msg.payload
        if (!ready()) {
          logger.info(
            `Peer ${senderClientId} wants to chat, but we're not ready. When we are, the peer will respond to our call instead.`
          )
          return
        }
        if (!hasConnection(senderClientId)) {
          createConnection(senderClientId)
        }
        sendMessage(
          features.webrtc.actions.videoRequest({
            entityId,

            senderClientId: clientId,
            receiverClientId: senderClientId,
          })
        )
      } else if (features.webrtc.actions.videoOffer.match(msg)) {
        const connection = getConnection(msg.payload.senderClientId)

        if (!connection) {
          throw new Error(`Cannot answer video-offer without a connection`)
        }
        connection.handleVideoOffer(msg)
      } else if (features.webrtc.actions.videoAnswer.match(msg)) {
        const connection = getConnection(msg.payload.senderClientId)
        if (!connection) {
          throw new Error(`Cannot answer video-answer without a connection`)
        }
        connection.handleVideoAnswer(msg)
      } else if (features.presence.actions.leaveEntity.match(msg)) {
        /** @todo  we should differentiate between enter entity and enter video? */
        const connection = getConnection(msg.payload.senderClientId)
        if (connection) {
          connection.closeVideoCall()
        }
        handleLeave(msg)
      } else if (features.webrtc.actions.setIceServers.match(msg)) {
        dispatch(msg)
      } else if (features.webrtc.actions.videoRequest.match(msg)) {
        const { senderClientId } = msg.payload
        if (!ready()) {
          throw new Error(
            `Peer ${senderClientId} wants to chat and we should be ready, but we are not. This can happen if you run multiple signaling hooks at the same time - don't.`
          )
        }
        createConnection(senderClientId)
      } else if (features.webrtc.actions.newIceCandidate.match(msg)) {
        const connection = getConnection(msg.payload.senderClientId)
        if (!connection) {
          logger.warn(`Cannot answer new-ice-candidate without a connection. This may be OK if it's during setup.`)
          return
        }
        connection.handleNewICECandidate(msg)
      } else if (features.webrtc.actions.stopTranceiver.match(msg)) {
        const connection = getConnection(msg.payload.senderClientId)
        invariant(connection, `Cannot stop tranceiver without a connection`)
        connection.handleStopTransceiver(msg)
      }
    },
    [entityId, ready, hasConnection, sendMessage, clientId, createConnection, getConnection, handleLeave, dispatch]
  )

  /** Session management */
  const startSession = useCallback(() => {
    invariant(entityId, 'Cannot start session without an entityId')
    invariant(socket, 'Cannot start session without a socket')
    sendMessage(
      features.webrtc.actions.enterRoom({ roomId: entityId, senderClientId: clientId, timestamp: Date.now() })
    )
  }, [clientId, entityId, sendMessage, socket])

  const stopSession = useCallback(() => {
    if (!entityId) {
      throw new Error(`stopSession called without an entity`)
    }
    /** Stop all the streams */
    if (stream) {
      stream.getTracks().forEach(t => t.stop())
    }

    sendMessage(
      features.webrtc.actions.leaveRoom({
        roomId: entityId,
        senderClientId: clientId,
        timestamp: Date.now(),
      })
    )
    /** Close all the connections */
    getConnections().forEach(conn => {
      conn.closeVideoCall()
      removeConnection(conn.peerClientId)
    })
  }, [clientId, entityId, getConnections, removeConnection, sendMessage, stream])

  // Listen to events on the Websocket
  useEffect(() => {
    if (!socket) {
      logger.warn(`Cannot start video signaling without a socket`)
      return
    }

    logger.debug(`*** Video signaling init`, socket)
    socket.addEventListener('message', handleMessage)

    return () => {
      logger.debug(`*** Video signaling shutdown`)
      socket.removeEventListener('message', handleMessage)
    }
  }, [handleMessage, socket])

  return useMemo(
    () => ({ ready: Boolean(socket), sendMessage, startSession, stopSession }),
    [sendMessage, socket, startSession, stopSession]
  )
}
