import { type NoteStr } from '@tunasong/music-lib'
import { Note } from 'webmidi'

export const noteOnMessage = (note: NoteStr | number, velocity = 0.8) =>
  ({
    type: 'noteon',
    note: new Note(note),
    value: velocity,
    time: 0,
  } as const)

export const noteOffMessage = (note: NoteStr | number) =>
  ({
    type: 'noteoff',
    note: new Note(note),
    time: 0,
  } as const)
