/** Manage active notes */

import { type NoteOctave } from '@tunasong/music-lib'

import { type NoteMessageEvent } from 'webmidi'

interface ActiveNotes {
  currentNotes: NoteOctave[]
  event: NoteMessageEvent
}
export const getActiveNotes = ({ currentNotes, event }: ActiveNotes) => {
  const { note } = event
  const noteName = `${note.name}${note.accidental ? note.accidental : ''}${note.octave}` as NoteOctave

  if (event.type === 'noteon') {
    return currentNotes.includes(noteName) ? currentNotes : [...currentNotes, noteName]
  }
  if (event.type === 'noteoff') {
    return !currentNotes.includes(noteName) ? currentNotes : currentNotes.filter(n => n !== noteName)
  }
  return currentNotes
}
