/** Last changed query */
import { type QueryProps } from './query-props.js'
import manifest from '@tunasong/manifest'
import type { SearchRequest } from '@tunasong/schemas'

const query = ({ query: parentId, size = 100 }: QueryProps): SearchRequest =>
  ({
    index: 'entities',
    size,
    from: 0,
    /** embeddings are large - don't return them by default */
    _source_excludes: ['embedding', 'acls'],
    body: {
      sort: [{ updatedAt: { order: 'desc' } }, '_score'],
      query: {
        /** Match all documents */
        bool: {
          must: {
            match_all: {},
          },
          /** Filter out types we don't want in the recent list, as well as trash */

          must_not: {
            terms: { type: manifest.search.excludeFromRecent },
          },
          filter: {
            bool: {
              must: parentId ? { term: { 'parentChain.id.keyword': parentId } } : undefined,
              must_not: {
                term: { trash: true },
              },
            },
          },
        },
      },
    },
  }) satisfies SearchRequest

export default query
