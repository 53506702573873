import { Box } from '@mui/material'
import { type SidebarComponent } from '@tunasong/ui-lib'
import { useActivities } from '@tunasong/redux'
import { type FC } from 'react'
import ActivityStream from '../activities/activity-stream.js'

export const SidebarActivities: FC<SidebarComponent> = ({ entity }) => {
  const activities = useActivities(entity.id)

  return (
    <Box sx={{ overflow: 'auto' }}>
      <ActivityStream activities={activities} />
    </Box>
  )
}
