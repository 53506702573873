import { ButtonBase, type ButtonProps } from '@mui/material'
import { makeStyles } from '@tunasong/ui-lib'
import cn from 'classnames'
import { type FC } from 'react'

const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.vars.palette.action.hover,
    // borderColor: ,
    padding: 2,
    margin: 2,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    '& >svg': {
      width: 16,
      height: 16,
    },
  },
  selected: {
    backgroundColor: theme.vars.palette.action.selected,
    color: theme.vars.palette.secondary.light,
  },
}))

export interface ChannelButtonProps extends ButtonProps {
  className?: string
  selected?: boolean
}

export const ChannelButton: FC<ChannelButtonProps> = props => {
  const { className, selected = false, ...restProps } = props
  const { classes } = useStyles()
  return <ButtonBase className={cn(className, classes.root, { [classes.selected]: selected })} {...restProps} />
}

export default ChannelButton
