import { Awareness, Y } from '@tunasong/sync-lib'
import { useEffect, useMemo, useState } from 'react'
import { YDocWebsocketProvider } from './y-websocket.js'
import { logger } from '@tunasong/models'

export interface YDocWebSocket {
  webSocket: WebSocket | null
  entityId: string
  doc: Y.Doc
  /** Run sync-step 0 on start. @default true */
  initialSync: boolean
  /** Sync the diff og local YDoc changes to the server. */
  localDiffSync: boolean
  resyncInterval: number
  /** receiveOnly - i.e., don't send messages - (typically when read only) */
  receiveOnly: boolean
}

/** Websocket provider for YDoc sync */
export const useYDocWebSocket = (props: YDocWebSocket) => {
  const {
    doc,
    webSocket,
    localDiffSync = false,
    initialSync = true,
    resyncInterval,
    entityId,
    receiveOnly = false,
  } = props

  const [provider, setProvider] = useState<YDocWebsocketProvider>()
  const [connected, setConnected] = useState(false)
  const awareness = useMemo(() => new Awareness(doc), [doc])

  useEffect(() => {
    if (!(webSocket && entityId)) {
      return
    }
    const provider = new YDocWebsocketProvider(webSocket, entityId, doc, awareness, {
      resyncInterval,
      initialSync,
      receiveOnly,
    })
    setProvider(provider)

    const handleStatus = ({ status }: { status: 'connecting' | 'connected' }) => {
      setConnected(status === 'connected')
    }

    provider.on('status', handleStatus)

    provider.connect()

    return () => {
      provider.off('status', handleStatus)
      provider.destroy()
    }
  }, [awareness, doc, entityId, initialSync, receiveOnly, resyncInterval, webSocket])

  /** Sync when connected AND localSync has completed */
  useEffect(() => {
    if (!(connected && localDiffSync && provider)) {
      return
    }
    logger.debug('Syncing local YDoc changes to server...')
    provider.persistLocalChanges()
  }, [connected, localDiffSync, provider])

  return { provider, awareness, connected }
}

export default useYDocWebSocket
