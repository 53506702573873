import { withSuspense } from '@tunasong/ui-lib'
import { lazy } from 'react'

export * from './lib/index.js'
export * from './room-controls.js'
export * from './room-menu.js'
export * from './room-overlay.js'
export * from './stream-record.js'

export const Stream = withSuspense(lazy(async () => import('./stream.js')))
export const VideoGrid = withSuspense(lazy(async () => import('./video-grid.js')))
