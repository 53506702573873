import { Box } from '@mui/material'
import type { AudioEvent, Track } from '@tunasong/schemas'
import { makeStyles, useOnResize, VerticalSplitter } from '@tunasong/ui-lib'
import { type FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTransport } from '../hooks/transport.js'
import { Timeline } from '../timeline/timeline.js'
import ChannelHeader from './channel/channel-header.js'
import Channel from './channel/channel.js'
import Details from './details.js'
import Marker from './marker.js'
import { TrackLane } from './track-lane.js'

const useStyles = makeStyles<{ width: number }>()((theme, { width }) => ({
  root: {
    overflow: 'hidden',
  },

  channel: {
    paddingRight: theme.spacing(0.5),
  },
  trackContainer: {
    /**  @note must set width explicitly to ensure that pixel/second is correct */
    position: 'relative',
    width,
  },
  track: {
    display: 'flex',
    width: `${width ?? 0}px`,
  },
}))

export interface TrackViewProps {
  className?: string
  tracks: Track[]

  events: AudioEvent[]

  headerHeight: number

  /** pixelsPerSecond for the view, synced with the timeline */
  duration: number

  onUpdateTrack?(track: Track): void
  onCreateTrack?(): void
  onDeleteTrack?(track: Track): void

  onTogglePool?(): void
}

export const TrackView: FC<TrackViewProps> = props => {
  const {
    duration,
    tracks = [],
    events = [],
    onUpdateTrack,

    onDeleteTrack,
    onCreateTrack,
    onTogglePool,
    headerHeight,
  } = props

  const [pixelsPerSecond, setPixelsPerSecond] = useState(10)
  const containerWidth = pixelsPerSecond * duration
  const styleProps = useMemo(() => ({ width: containerWidth }), [containerWidth])
  const { classes } = useStyles(styleProps)

  const { transport } = useTransport()

  /** Scale up/down the pixelsPerSecond */
  const handleScale = useCallback(
    (ev: globalThis.MouseEvent) => {
      if (ev.movementY === 0) {
        return
      }
      const moveY = ev.movementY
      /** If negative, we divide by a factor, if positive we multiply */
      const factor = Math.abs(moveY / 20)
      const absFactor = moveY > 0 ? 1 + factor : 1 - factor
      const pps = pixelsPerSecond * absFactor
      /** Minimum 5 pps, maximum 100 */
      const minPps = 2.5
      const maxPps = 100
      setPixelsPerSecond(Math.min(Math.max(pps, minPps), maxPps))
    },
    [pixelsPerSecond]
  )

  const markerRef = useRef<HTMLDivElement | null>(null)
  const clipsContainerRef = useRef<HTMLElement | null>(null)
  const updateMarker = useCallback(
    (positionInSeconds: number) => {
      const el = markerRef.current
      if (!el) {
        return
      }
      if (!clipsContainerRef.current) {
        return
      }

      const markerPosition = positionInSeconds * pixelsPerSecond

      el.style.left = `${markerPosition}px`
      el.style.display = markerPosition === 0 ? 'none' : 'block'
    },
    [pixelsPerSecond]
  )
  const rootRef = useRef<HTMLDivElement | null>(null)
  useOnResize(rootRef.current, () => {
    updateMarker(transport.seconds)
  })
  /** Update marker on start, stop, loop */
  useEffect(() => {
    transport.on('start', updateMarker)
    transport.on('stop', updateMarker)
    transport.on('loop', updateMarker)
    return () => {
      transport.off('start', updateMarker)
      transport.off('stop', updateMarker)
      transport.off('loop', updateMarker)
    }
  }, [transport, updateMarker])

  /** Update the marker position when transport is running. Manipulate the DOM directly. */
  useEffect(() => {
    const id = setInterval(() => {
      if (transport.state !== 'started' || !markerRef.current) {
        return
      }
      updateMarker(transport.seconds)
    }, 50)
    return () => clearInterval(id)
  }, [transport, transport.state, updateMarker])

  const onTimelineClick = useCallback(
    (seconds: number) => {
      transport.seconds = seconds
      updateMarker(seconds)
    },
    [transport, updateMarker]
  )

  const [showDetails, setShowDetails] = useState(false)

  const handleTimelineClick = useCallback(
    (ev: React.MouseEvent) => {
      // Find the relative x-position of the click in the timeline
      const { x } = ev.currentTarget.getBoundingClientRect()
      const seconds = (ev.clientX - x) / pixelsPerSecond
      onTimelineClick?.(seconds)
    },
    [onTimelineClick, pixelsPerSecond]
  )

  const [selectedTrack, setSelectedTrack] = useState<Track>()
  const handleSetTrack = (t?: Track) => () => setSelectedTrack(t)

  return (
    <Box className={classes.root} ref={rootRef}>
      <VerticalSplitter
        sx={{ position: 'relative', display: 'flex', flex: 1, overflow: 'auto' }}
        defaultLeftPercentage={20}
        // onResize={setSidebarWidth}
        thickness={1}
        left={
          <>
            <ChannelHeader
              selected={selectedTrack}
              onToggleDetails={() => setShowDetails(d => !d)}
              onNewTrack={onCreateTrack}
              onDeleteTrack={onDeleteTrack}
              onTogglePool={onTogglePool}
            />
            {tracks.map(track => (
              <Channel
                key={track.id}
                className={classes.channel}
                selected={selectedTrack === track}
                track={track}
                onClick={handleSetTrack(track)}
                onChange={onUpdateTrack}
              />
            ))}
            {showDetails ? <Details track={selectedTrack} /> : null}
          </>
        }
        right={
          <Box ref={clipsContainerRef} className={classes.trackContainer}>
            <Marker ref={markerRef} />

            <Timeline
              onDragMove={handleScale}
              height={headerHeight}
              duration={duration}
              pixelsPerSecond={pixelsPerSecond}
              events={events}
              onClick={handleTimelineClick}
            />
            {tracks.map(track => (
              <TrackLane
                key={track.id}
                className={classes.track}
                pixelsPerSecond={pixelsPerSecond}
                track={track}
                onUpdate={onUpdateTrack}
              />
            ))}
          </Box>
        }
      />
    </Box>
  )
}

export default TrackView
