import { isBlock } from '@tunasong/models'
import { type TunaEditor } from '@tunasong/plugin-lib'
import { type CoreElement } from '@tunasong/schemas'
import { Node, type NodeEntry, Path } from 'slate'

type MatchType<T extends CoreElement> = (node: T) => boolean

export const ancestorElement = <T extends CoreElement, S extends TunaEditor>(
  editor: S,
  at?: Path,
  match: MatchType<T> = isBlock
): NodeEntry<T> | null => {
  if (!at) {
    return null
  }

  const ancestors = Node.ancestors(editor, at, { reverse: true })
  for (const nodeEntry of ancestors) {
    const m = nodeEntry[0] as T
    if (!match(m)) {
      continue
    }
    return nodeEntry as NodeEntry<T>
  }

  return null
}
