/** Handle mixer and AudioContext */

import { useContext } from 'react'
import invariant from 'tiny-invariant'
import { AudioEngineContext } from '../engine/audio-engine-context.js'

/** Mixer. Will resume the audio context once on any click. */
export const useMixer = () => {
  const engine = useContext(AudioEngineContext)
  invariant(engine?.mixer, 'Mixer not found')
  return engine.mixer
}
