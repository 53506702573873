/** ToneJS based Wav player */

import { Player } from 'tone'
import { sampleBucket } from '../lib/wave-drawer.js'
import { type MidiNoteEvent } from '../events/index.js'
import { type AudioInstrument } from './instrument.js'
import { logger } from '@tunasong/models'

/** @see https://tonejs.github.io/docs/14.7.77/Player.html */
export class AudioPlayer extends Player implements AudioInstrument {
  constructor(private url: string, onLoad?: () => void) {
    super(url, onLoad)
  }

  togglePlay = () => {
    if (this.state === 'started') {
      this.stop()
    } else {
      this.start()
    }
  }

  renderPeaks = (numPeaks = 100, normalize = true) => {
    if (!this.loaded) {
      throw new Error(`Cannot render peaks before the audio has loaded`)
    }
    const peaks: number[] = []
    const data = this.getChannelData(0)
    const binSize = data.length / numPeaks
    for (let i = 0; i < numPeaks; i++) {
      peaks.push(sampleBucket(data, i, binSize))
    }
    const maxVal = Math.max(...peaks)
    return normalize ? peaks.map(p => p / maxVal) : peaks
  }

  getChannelData = (channel = 0) => this.buffer.getChannelData(channel)

  onEvent = (ev: MidiNoteEvent) => {
    logger.debug(`Wav play`, this.url, ev)
  }
}
