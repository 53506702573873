import cn from 'classnames'
import React, { type FC, useCallback, useRef } from 'react'
import { useAnimationFrameLoop, makeStyles } from '@tunasong/ui-lib'
import { AudioController } from './engine/audio-controller.js'
import { getDBPercentage } from './lib/db.js'

const useStyles = makeStyles<{ orientation: 'horizontal' | 'vertical' }>()((theme, { orientation }) => ({
  dbMeter: {
    // display: 'flex',
    flexDirection: orientation === 'horizontal' ? 'row' : 'column',
    height: orientation === 'horizontal' ? theme.spacing(0.75) : '100%',
    width: orientation === 'vertical' ? theme.spacing(0.75) : '100%',
    position: 'relative',
  },
  primary: { backgroundColor: theme.vars.palette.primary.light },
  secondary: { backgroundColor: theme.vars.palette.secondary.light },
}))

export interface MeterProps {
  className?: string
  controller?: AudioController
  at?: 'pre' | 'post'
  color?: 'primary' | 'secondary'
  orientation?: 'horizontal' | 'vertical'
}

export const Meter: FC<MeterProps> = props => {
  const { controller, at = 'pre', color = 'secondary', className, orientation = 'horizontal' } = props
  const { classes } = useStyles({ orientation })

  const ref = useRef<HTMLDivElement | null>(null)
  const updateMeter = useCallback(() => {
    if (!(controller && ref.current)) {
      return
    }
    const db = controller.getDB(at)
    const size = `${getDBPercentage({ value: db.peak, reference: 0 })}%`
    if (orientation === 'horizontal') {
      ref.current.style.width = size
    } else {
      ref.current.style.height = size
    }
  }, [at, controller, orientation])

  useAnimationFrameLoop(updateMeter)

  if (!controller) {
    return null
  }

  /** We need this to be performant, so we don't keep state in React here */
  return (
    <div
      className={cn(className, classes.dbMeter, {
        [classes.primary]: color === 'primary',
        [classes.secondary]: color === 'secondary',
      })}
      ref={ref}
    ></div>
  )
}

export default Meter
