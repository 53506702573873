import { useEffect } from 'react'
import { useAudioEngine } from '../hooks/audio-engine.js'
import { type AudioInstrument } from '../instruments/index.js'

/** Instrument hook - will wire up the instrument */
export const useInstrument = (name: string, instrument?: AudioInstrument) => {
  const audioEngine = useAudioEngine()

  useEffect(() => {
    if (!(name && audioEngine && instrument)) {
      return
    }
    const id = audioEngine.addInstrument(name, instrument)
    return () => {
      audioEngine.removeInstrument(id)
    }
  }, [audioEngine, instrument, name])
}
