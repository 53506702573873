/** Get suggestions from all plugins */

import type { Suggestion, SuggestionContext } from '@tunasong/models'
import { type MusicContextType } from '@tunasong/music-lib'
import type { TunaEditor, TunaPlugin } from '@tunasong/plugin-lib'

interface GetSuggestions {
  editor: TunaEditor
  plugins: TunaPlugin[]
  context: MusicContextType | null
  showContexts: SuggestionContext[]
}
export const getSuggestions = async ({ plugins, context, editor, showContexts }: GetSuggestions) => {
  if (!context) {
    return []
  }
  const p = plugins.map(async (p: TunaPlugin) => (p.getSuggestions ? p.getSuggestions(editor, context as never) : []))
  const suggestions = await Promise.all(p)
  const filt = suggestions.flat().filter(s => showContexts.includes(s.context))

  /** Sort them on score */
  const sorted = filt.sort((a: Suggestion, b: Suggestion) => (a.score === b.score ? 0 : a.score > b.score ? -1 : 1))
  return sorted
}
