import { InputBase } from '@mui/material'
import { disableAutocomplete, makeStyles, useBorderColors, useCachedValue } from '@tunasong/ui-lib'
import { Record } from '@tunasong/icons'
import { type Track } from '@tunasong/schemas'
import cn from 'classnames'
import React, { type ChangeEvent, type FC, type MouseEvent, useCallback } from 'react'
import { useMixer } from '../../hooks/index.js'
import { Meter } from '../../meter.js'
import { useTrackControl } from '../hooks/track-control.js'
import { ChannelButton } from './channel-button.js'
import { ChannelPan } from './channel-pan.js'
import { ChannelVolume } from './channel-volume.js'

export interface ChannelProps {
  className?: string
  track: Track
  selected?: boolean
  onClick?(ev: MouseEvent): void
  onChange?(track: Track): void
}

const useStyles = makeStyles()(theme => ({
  root: {
    fontSize: 12,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderLeftStyle: 'solid',
    borderLeftWidth: theme.spacing(0.5),
    justifyContent: 'space-evenly',
    userSelect: 'none',
  },
  input: {
    fontSize: 12,
    padding: 0,
    textAlign: 'right',
  },
  row: {
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  selected: {
    backgroundColor: theme.vars.palette.action.hover,
  },
  buttonStrip: {},
  meter: {
    height: theme.spacing(),
  },
}))

export const Channel: FC<ChannelProps> = props => {
  const { className, track, selected, onChange, onClick } = props
  const { classes } = useStyles()
  const { classes: colors } = useBorderColors()
  const color = track.color ?? 'grey'
  const mixer = useMixer()

  const onUpdateTrack = (props: Partial<Track>) => onChange && onChange({ ...track, ...props })
  const { toggleMute, toggleSolo, toggleArm, onChangeVolume, onChangePan, pan, volume, channel } = useTrackControl({
    track,
    onUpdate: onUpdateTrack,
  })

  const [name, setName] = useCachedValue(track.name)
  const handleChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
      () =>
        setName(value),
    [setName]
  )
  const handleName = useCallback(
    (ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      onChange && onChange({ ...track, name: ev.target.value ?? 'Untitled' }),
    [onChange, track]
  )

  /** @todo we need a better way to route input */
  const recordInput = mixer?.getBus('mic')

  return (
    <div className={cn(className, classes.root, colors[color], { [classes.selected]: selected })} onClick={onClick}>
      <div className={classes.row}>
        <div className={classes.buttonStrip}>
          <ChannelButton selected={channel?.muted} onClick={toggleMute}>
            M
          </ChannelButton>
          <ChannelButton selected={channel?.solo} onClick={toggleSolo}>
            S
          </ChannelButton>
          <ChannelButton selected={channel?.armed} onClick={toggleArm}>
            <Record />
          </ChannelButton>
        </div>
        <InputBase
          classes={{ input: classes.input }}
          value={name}
          onChange={handleChange}
          onBlur={handleName}
          inputProps={disableAutocomplete}
        />
      </div>
      <div className={classes.row}>
        <ChannelVolume volume={volume} onChangeVolume={onChangeVolume} />
      </div>
      <div className={classes.row}>
        <ChannelPan pan={pan} onPan={onChangePan} />
      </div>
      {channel?.armed && recordInput && (
        <div className={classes.row}>
          <Meter className={classes.meter} controller={recordInput} />
        </div>
      )}
    </div>
  )
}

export default Channel
