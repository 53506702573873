import { Box, IconButton, Typography } from '@mui/material'
import { AudioRecorder, Waveform } from '@tunasong/audio-ui'
import { Delete } from '@tunasong/icons'
import { type SortOptions, dayjs } from '@tunasong/models'
import { useChildren, useDeleteEntity } from '@tunasong/redux'
import { isAudio, type AudioMedia } from '@tunasong/schemas'
import { type SidebarComponent, VBox, useConfirmDeleteDialog, useEntitySort, useUploadStoredEntity } from '@tunasong/ui-lib'
import { type FC, useCallback } from 'react'

const sortOptions = { sortBy: 'updatedAt', order: 'desc' } satisfies SortOptions

export const SidebarRecord: FC<SidebarComponent> = ({ entity }) => {
  const { entities: clips } = useChildren<AudioMedia>({ parentId: entity.id, filter: isAudio })
  const audioClips = useEntitySort(clips, sortOptions)
  const deleteEntity = useDeleteEntity()
  const entityId = entity.id

  const { ConfirmDeleteDialog, setConfirmItemHandler } = useConfirmDeleteDialog()

  const { uploadNewEntity } = useUploadStoredEntity()
  const handleComplete = useCallback(
    (blob: Blob) => {
      const name = `${dayjs().format('YYYY-MM-DD-HH-mm')}`
      uploadNewEntity(name, entityId, blob)
    },
    [entityId, uploadNewEntity]
  )

  return (
    <VBox sx={{ overflow: 'hidden' }}>
      <Box>
        <AudioRecorder onComplete={handleComplete} />
      </Box>
      <Typography variant="h6" gutterBottom>
        Clips
      </Typography>
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        {audioClips.length > 0 ? (
          audioClips.map(clip => (
            <Box sx={{ position: 'relative' }} key={clip.id}>
              <IconButton
                sx={{ position: 'absolute', right: 0, top: 4, zIndex: 1, opacity: 0.5, ':hover': { opacity: 1 } }}
                color="error"
                onClick={setConfirmItemHandler(clip)}
              >
                <Delete />
              </IconButton>
              <Waveform key={clip.id} media={clip} autoStop={true} sx={{ paddingTop: 2 }} renderPeaks={true} />
            </Box>
          ))
        ) : (
          <Typography variant="caption">No clips</Typography>
        )}
      </Box>
      <ConfirmDeleteDialog onConfirm={deleteEntity} />
    </VBox>
  )
}
