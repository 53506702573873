import { Grid2, type Grid2Props } from '@mui/material'
import { capitalize } from '@tunasong/models'
import type { Template, TunaPlugin } from '@tunasong/plugin-lib'
import { type Entity, type EntityType } from '@tunasong/schemas'
import { Fragment, useCallback, type FC } from 'react'
import TemplateCard from './template-card.js'

interface TemplatePluginsProps extends Omit<Grid2Props, 'onSelect'> {
  selected?: Template
  plugins: TunaPlugin[]
  onSelect: (template: Template) => void
}

/** Default template for plugins without templates */
const getDefaultTemplates = (plugin: TunaPlugin): Record<string, Template> =>
  typeof plugin.type === 'string'
    ? {
        default: {
          name: plugin.key,
          description: plugin.description,
          template: {
            type: plugin.type as EntityType,
          } satisfies Entity,
        },
      }
    : {}

/** Templates from plugin */
export const TemplatePlugins: FC<TemplatePluginsProps> = props => {
  const { selected, onSelect, plugins, ...restProps } = props

  const handleSelect = useCallback(
    (template: Template) => () => {
      onSelect(template)
    },
    [onSelect]
  )

  return (
    <Grid2 container spacing={2} {...restProps}>
      {plugins
        .filter(p => !Boolean(p.disallowUserCreate))
        .map(p => (
          <Fragment key={p.key}>
            {Object.values(p.templates ?? getDefaultTemplates(p)).map((template, idx) => (
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={idx}>
                <TemplateCard
                  key={idx}
                  plugin={p}
                  title={template.name ?? capitalize(p.type ?? '')}
                  description={template.description ?? p.description ?? 'Template for ' + p.type}
                  template={template}
                  onSelect={handleSelect(template)}
                  selected={selected?.name === template.name}
                />
              </Grid2>
            ))}
          </Fragment>
        ))}
    </Grid2>
  )
}

export default TemplatePlugins
