import { Box, Divider, MenuItem, Select } from '@mui/material'
import { entityTypeNames, type EntityType, type SearchFilter as SearchFilterType } from '@tunasong/schemas'
import { TagList } from '@tunasong/ui-lib'
import { type FC } from 'react'

export interface SearchFilterProps {
  filter: SearchFilterType
  onChange(filter: SearchFilterType): void
}

export const SearchFilter: FC<SearchFilterProps> = props => {
  const { filter, onChange } = props
  const handleTags = (tags: string[]) => onChange({ ...filter, tags })
  const handleType = (types: EntityType[]) => onChange({ ...filter, types })
  return (
    <Box>
      <Divider>Type</Divider>
      <Select
        sx={{ my: 2 }}
        fullWidth
        value={filter.types ?? []}
        multiple
        onChange={ev => handleType(ev.target.value as EntityType[])}
      >
        {entityTypeNames.toSorted().map(type => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
      <Divider>Tags</Divider>
      <TagList tags={filter.tags} onChange={handleTags} />
    </Box>
  )
}

export default SearchFilter
