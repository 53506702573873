import cn from 'classnames'
import React from 'react'
import { makeStyles } from '@tunasong/ui-lib'

const useStyles = makeStyles()(theme => ({
  root: {
    position: 'absolute',
    width: 2,
    backgroundColor: theme.vars.palette.primary.light,
    opacity: 0.3,
    zIndex: 1,
    height: '100%',
  },
}))

export interface MarkerProps {
  className?: string
}

export const Marker = React.forwardRef<HTMLDivElement, MarkerProps>((props, ref) => {
  const { className } = props
  const { classes } = useStyles()

  /** Update the marker */
  return <div className={cn(className, classes.root)} ref={ref} />
})

export default Marker
